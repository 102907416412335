import React, { useEffect } from "react";
import { Layout, Header, Footer, Tab, MinFooter } from "@kidslivesafe/sc-react";
import { useAppSelector } from "../../hooks/hooks";
import { selectBrandInfo } from "../../features/brand/brandSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import {
  selectIsOpen,
  toggleOpen,
  setTab,
  closeMultiSearch,
  selectTab,
} from "../../features/multisearch/multisearchSlice";

import {
  logoutAsync,
  selectAccountType,
  selectIdProtectData,
} from "../../features/authentication/authenticationSlice";
import gateKeeper from "../../components/HigherOrderComponents/gatekeeper";

import Logo from "@assets/images/brand-logo.svg";
import HeaderLogo from "@assets/images/brand-logo--header.svg";

import facebook from "../../assets/global/images/social_facebook.svg";
import twitter from "../../assets/global/images/social_twitter.svg";
import instagram from "../../assets/global/images/social_instagram.svg";
import youtube from "../../assets/global/images/social_youtube.svg";
import linkedin from "../../assets/global/images/social_linkedin.svg";
import tiktok from "../../assets/global/images/social_tiktok.svg";
import { useMultiSearchProps } from "../../hooks/multiSearchPropsHook";
import flexAccessLayout from "../../components/HigherOrderComponents/flexAccessLayout";
import brandedLoginLayout from "../../components/HigherOrderComponents/brandedLoginLayout";
import deepLinkRedirect from "../../components/HigherOrderComponents/deepLinkRedirect";
import { sendMemberEventAsync } from "../../features/admin/adminSlice";
import { useFooterProps } from "../../hooks/footerPropsHook";

const socialAssetMap = {
  facebook: facebook,
  twitter: twitter,
  instagram: instagram,
  youtube: youtube,
  linkedin: linkedin,
  tiktok: tiktok,
};

interface PageLayoutProps {
  children: React.ReactNode;
  pageName: string;
  documentTitle?: string;
  header?: boolean;
  footerType?: "default" | "no-footer" | "min-footer";
  minFooterOptions?: {
    color?: "base" | "white";
  };
  noFooter?: boolean;
  displayButtons?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  pageName,
  children,
  documentTitle,
  header = false,
  displayButtons = false,
  noFooter = false,
  minFooterOptions = { color: "base" },
  footerType = "default",
}) => {
  const [tabs, setTabs] = React.useState<Tab[]>([
    "people",
    "property",
    "phone",
    "social",
  ]);
  const [multiSearchFooter, setMultiSearchFooter] =
    React.useState<boolean>(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accountType = useAppSelector(selectAccountType);
  const idProtectData = useAppSelector(selectIdProtectData);
  const brandInfo = useAppSelector(selectBrandInfo);

  const handleLogout = () => {
    dispatch(closeMultiSearch());
    dispatch(logoutAsync());
    navigate("/logout");
  };

  const handleIdProtectLink = () => {
    dispatch(
      sendMemberEventAsync({
        EventCategory: "Menu",
        EventAction: "ID Protect Express",
      }),
    );
    navigate("/id-protect");
    // Scroll to the top of the page after navigation
    window.scrollTo(0, 0);
  };

  const linkBank = [
    {
      text: "Dashboard",
      link: "/",
      target: "_self",
    },
    {
      text: "Activity History",
      link: "/activity-history",
      target: "_self",
    },
    {
      text: "ID Protect",
      onClick: handleIdProtectLink,
    },
    {
      text: "Neighborhood Watch",
      link: "/neighborhood-home",
      target: "_self",
    },
    {
      text: "People",
      link: "/people-home",
      target: "_self",
    },
    {
      text: "Phone",
      link: "/phone-home",
      target: "_self",
    },
    {
      text: "Property",
      link: "/property-home",
      target: "_self",
    },
    {
      text: "Social",
      link: "/social-home",
      target: "_self",
    },
    {
      text: "My Account",
      link: "/my-account",
      target: "_self",
    },
    {
      text: "Contact Us",
      link: `${brandInfo.homeUrl}/help-center/contact`,
      target: "_blank",
    },
    {
      text: "Logout",
      onClick: handleLogout,
    },
  ];

  const renderLinks = (type: Tab | null) => {
    let allowedLinks = [] as string[];
    switch (type) {
      case "auto":
        allowedLinks = ["Dashboard", "Activity History", "Logout"];
        break;
      case "neighborhood":
        allowedLinks = [
          "Dashboard",
          "Activity History",
          "Neighborhood Watch",
          "People",
          "Phone",
          "Property",
          "Social",
          "ID Protect",
          "My Account",
          "Contact Us",
          "Logout",
        ];
        break;
      default:
        allowedLinks = [
          "Dashboard",
          "Activity History",
          "ID Protect",
          "My Account",
          "Contact Us",
          "Logout",
        ];
    }
    return (
      linkBank
        .filter(link => {
          if (link.text === "ID Protect") {
            return (
              idProtectData.isIdProtectAvailable ||
              idProtectData.idProtectMember
            );
          }
          return allowedLinks.includes(link.text);
        })
        // sorts by order of appearance in allowedLinks to be able to dynamically control
        .sort((a, b) => {
          return allowedLinks.indexOf(a.text) - allowedLinks.indexOf(b.text);
        })
    );
  };

  useEffect(() => {
    const whichTabs = (type: Tab | null) => {
      switch (type) {
        case "auto":
          setTabs(["auto"]);
          setMultiSearchFooter(false);
          dispatch(setTab("auto"));
          break;
        case "neighborhood":
          setTabs(["neighborhood", "people", "property", "phone"]);
          setMultiSearchFooter(true);
          dispatch(setTab("neighborhood"));
          break;
        case "people":
          setTabs(["people", "property", "phone", "social"]);
          setMultiSearchFooter(true);
          dispatch(setTab("people"));
          break;
        case "phone":
          setTabs(["phone", "people", "property", "social"]);
          setMultiSearchFooter(true);
          dispatch(setTab("phone"));
          break;
        case "property":
          brandInfo.acronym === "pf"
            ? setTabs(["property"])
            : setTabs(["property", "people", "phone", "social"]);
          setMultiSearchFooter(true);
          dispatch(setTab("property"));
          break;
        // no social funnel so no social accountType
        default: // defaults to "people" accountType
          setTabs(["people", "property", "phone", "social"]);
          setMultiSearchFooter(true);
          dispatch(setTab("people"));
          break;
      }
    };

    whichTabs(accountType);
  }, [accountType, dispatch, brandInfo]);

  const multiSearchProps = useMultiSearchProps(
    {
      handleCancelSearchMiddleware: () => {
        dispatch(toggleOpen());
      },
      handlePeopleSearchMiddleware: () => dispatch(toggleOpen()),
      handleSocialSearchMiddleware: () => dispatch(toggleOpen()),
      handlePhoneSearchMiddleware: () => dispatch(toggleOpen()),
      handlePropertySearchMiddleware: () => dispatch(toggleOpen()),
      handleAutoSearchMiddleware: () => dispatch(toggleOpen()),
      handleNeighborhoodSearchMiddleware: () => dispatch(toggleOpen()),
      handleNeighborhoodNameSearchMiddleware: () => dispatch(toggleOpen()),
      openTab: useAppSelector(selectTab),
      handleSetTab: (tab: Tab | undefined) => {
        dispatch(setTab(tab));
      },
      resetInput: true,
      tabs: tabs,
      footer: multiSearchFooter,
      backgroundStyle: "alt",
    },
    "",
  );

  const multiSearchOpen = useAppSelector(selectIsOpen);

  // updates the document title
  useEffect(() => {
    document.title = documentTitle
      ? `${brandInfo.name} | ${documentTitle}`
      : `${brandInfo.name} | ${pageName}`;
  }, [documentTitle, brandInfo.name, pageName]);

  const menuButtonLinks = renderLinks(accountType);

  const footerProps = useFooterProps();

  brandInfo.footerSocials.forEach(social => {
    const socialObj = {
      name: social,
      assetUrl: socialAssetMap[social],
      link: brandInfo.socialUrls[social] || "",
      target: "_blank",
    };
    footerProps.socialMediaLinks.push(socialObj);
  });

  return (
    <>
      <div className={`page page-${pageName}`}>
        {header ? (
          <>
            <Header
              menuButtonLinks={menuButtonLinks}
              displayButtons={displayButtons}
              logoUrl={HeaderLogo}
              menuLogoUrl={Logo}
              homeUrl="/"
              expand={multiSearchOpen}
              setExpand={multiSearchProps.handleCancelSearch}
              changeBackgroundOnExpand={
                brandInfo.acronym === "pf" ? true : false
              }
              multiSearchOptions={multiSearchProps}
            />
            {children}
          </>
        ) : (
          <Layout
            type="flex"
            flexProps={{ direction: "column", justifyContent: "center" }}
          >
            {children}
          </Layout>
        )}
      </div>
      {footerType === "default" && (
        <Footer
          {...footerProps}
          logoUrl={Logo}
          disclaimerBrand={brandInfo.acronym === "pf" ? "pf" : "default"}
        />
      )}
      {footerType === "min-footer" && (
        <MinFooter
          brand={brandInfo.name}
          color={minFooterOptions.color}
          llcName={`${brandInfo.llcName}, LLC.`}
          links={[
            {
              text: "Privacy Policy",
              link: `${brandInfo.homeUrl}/help-center/privacy`,
            },
            {
              text: "Fair Credit Reporting Act",
              link: `${brandInfo.homeUrl}/help-center/fcra`,
            },
            {
              text: "Terms of Use",
              link: `${brandInfo.homeUrl}/help-center/terms`,
            },
          ]}
        />
      )}
      {footerType === "no-footer" && null}
    </>
  );
};

export default PageLayout;

export const GateKeeperPageLayout = gateKeeper(PageLayout);

export const FlexAccessLayout = flexAccessLayout(PageLayout);

export const BrandedLoginLayout = brandedLoginLayout(PageLayout);

export const DeepLinkingRedirectLayout = deepLinkRedirect(PageLayout);
