import { useAppDispatch, useAppSelector, useHandlePageNavigate } from "./hooks";
import {
  getNeighborhoodPOIsAsync,
  propertyReportDifferenceAsync,
  pullPropertyReportAsync,
  selectPropertyReportStatus,
} from "../features/reports/reportsSlice";
import { useLocation } from "react-router-dom";
import { setPropertySearch } from "../features/multisearch/multisearchSlice";
import { selectPropertyMonitoring } from "../features/monitoring/monitoringSlice";
import { PropertyReport } from "../features/reports/reportTypes";
import { selectBrandInfo } from "../features/brand/brandSlice";
import {
  MemberExperience,
  selectMemberExperienceData,
} from "../features/authentication/authenticationSlice";
import React from "react";

export type PullPropertyReportParams = any;

type UsePropertyReportHook = () => (report: PullPropertyReportParams) => void;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

/* Property Search */
const usePropertySearchDispatch: UsePropertyReportHook = () => {
  const dispatch = useAppDispatch();
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const monitoredPropertyReports = useAppSelector(selectPropertyMonitoring);
  const memberExperienceData = useAppSelector(selectMemberExperienceData);
  const pageNavigate = useHandlePageNavigate();
  const location = useLocation();
  const brandInfo = useAppSelector(selectBrandInfo);

  const memberExperienceRef =
    React.useRef<MemberExperience>(memberExperienceData);

  React.useEffect(() => {
    memberExperienceRef.current = memberExperienceData;
  }, [memberExperienceData]);

  const query = useQuery();
  const version = query.get("version");

  const pullPropertyReport = React.useCallback(
    (report: PullPropertyReportParams) => {
      if (propertyReportStatus === "loading") return;
      if (location.pathname !== "/property-report") {
        pageNavigate("/property-report");
      } else {
        window.scrollTo(0, 0);
      }

      const dto = {
        addressLine1: report.streetNumber
          ? report.streetNumber + " " + report.streetName
          : report.fullStreetAddress
            ? report.fullStreetAddress
            : report.addressLine1
              ? report.addressLine1
              : report.streetName,
        addressLine2: report.subPremise
          ? report.subPremise
          : report.addressLine2,
        state: report.state,
        city: report.city,
        zip: report.zip,
        latitude: report.lat
          ? report.lat
          : report.latitude
            ? report.latitude
            : undefined,
        longitude: report.long
          ? report.long
          : report.longitude
            ? report.longitude
            : undefined,
        nearbySchoolRadius: report.nearbySchoolRadius
          ? report.nearbySchoolRadius
          : 5,
      };

      dispatch(pullPropertyReportAsync(dto))
        .unwrap()
        .then(result => {
          if (result.success) {
            if (!result.noResults) {
              const report = result.report.results
                .scalableReport as PropertyReport;
              const monitoredReport = monitoredPropertyReports.find(
                monitoredProperty =>
                  monitoredProperty.addressLine1 === dto.addressLine1 &&
                  (!monitoredProperty.addressLine2 ||
                    monitoredProperty.addressLine2 === dto.addressLine2),
              );

              if (version && report.propertyReportSummary) {
                dispatch(
                  propertyReportDifferenceAsync({
                    ...dto,
                    versionA: Number(version),
                    versionB: Number(report.propertyReportSummary.version!),
                  }),
                );
              }

              if (
                !version &&
                monitoredReport &&
                report.propertyReportSummary &&
                monitoredReport.versionZ &&
                report.propertyReportSummary.version &&
                monitoredReport.versionZ !==
                  report.propertyReportSummary.version
              ) {
                dispatch(
                  propertyReportDifferenceAsync({
                    ...dto,
                    versionA: Number(monitoredReport.versionZ),
                    versionB: Number(report.propertyReportSummary.version!),
                  }),
                );
              }

              if (
                report &&
                report.propertyDetails &&
                ((["prr"].includes(brandInfo.acronym) &&
                  memberExperienceRef &&
                  memberExperienceRef.current.variant &&
                  [
                    "m31_prr_/property-report_v1",
                    "m31_prr_/property-report_v2",
                  ].includes(memberExperienceRef.current.variant)) ||
                  ["pf"].includes(brandInfo.acronym))
              ) {
                if (report.propertyDetails?.postalCode) {
                  dispatch(
                    getNeighborhoodPOIsAsync({
                      zip: report.propertyDetails.postalCode,
                      zip4: report.propertyDetails.zip4,
                      variant: memberExperienceRef.current.variant,
                    }),
                  );
                }
              }
            }
          }

          dispatch(setPropertySearch(dto));
        })
        .catch(error => {
          console.log(error.message);
          pageNavigate("/");
        });
    },
    [
      dispatch,
      propertyReportStatus,
      monitoredPropertyReports,
      version,
      brandInfo,
      pageNavigate,
      location,
    ],
  );

  return pullPropertyReport;
};

export { usePropertySearchDispatch };
