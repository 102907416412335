import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintableComponent from "../../../components/UIComponents/PrintableComponent/PrintableComponent";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import useSiteJabberDispatch from "../../../hooks/siteJabberHook";

import {
  Layout,
  Sticky,
  NavigateToSection,
  NavigateToSectionItemProps,
  Padding,
  ReportTitle,
  Sheet,
  ReportSummary,
  LoadingPlaceholder,
  Text,
} from "@kidslivesafe/sc-react";

import "../PeopleReport/print.css"; // used for print styling .page-break could be added
import "./assets/phone-report.css";

import {
  selectPhoneReport,
  selectPhoneReportStatus,
} from "../../../features/reports/reportsSlice";

import { selectBrand } from "../../../features/brand/brandSlice";

import {
  parseDate,
  formatPhoneNumber,
  generatePhoneReportHighlights,
} from "../../../utils";

import PossibleLocations from "./PossibleLocations";
import PossiblePeople from "./PossiblePeople";
import PossibleBusiness from "./PossibleBusiness";
import Complaints from "./Complaints";

import { PhoneReport as PhoneReportType } from "../../../features/reports/reportTypes";
import { GridProps } from "@kidslivesafe/sc-react/lib/atoms/Layout/LayoutTypes";

import { selectPhoneSearchParams } from "../../../features/multisearch/multisearchSlice";

import NoResults from "../../../components/UIComponents/NoResults/NoResults";
import { sendMemberEventAsync } from "../../../features/admin/adminSlice";

const PhoneReport: React.FC = () => {
  const phoneSearch = useAppSelector(selectPhoneSearchParams);
  // local state
  const [navigateProps, setNavigateProps] = useState<
    NavigateToSectionItemProps[]
  >([]);
  const [displayNavigateProps, setDisplayNavigateProps] = useState(false);

  const [printReady, setPrintReady] = useState<boolean>(false);
  const contentToPrint = useRef<HTMLDivElement>(null);
  const promiseResolveRef = useRef<((value?: any) => void) | null>(null);
  const dispatch = useAppDispatch();

  let componentFormat: GridProps["templateColumns"] = printReady
    ? "1-column"
    : "1fr-2fr";

  useEffect(() => {
    if (printReady && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [printReady]);

  const runSiteJabber = useSiteJabberDispatch();

  //store state
  const pulledReport = useAppSelector(selectPhoneReport);
  const phoneReportStatus = useAppSelector(selectPhoneReportStatus);
  const brandInfo = useAppSelector(selectBrand);

  const loading = phoneReportStatus === "loading";

  // Constants - to be set when built - changed
  const locationId = "location";
  const possibleBusinessId = "business";
  const possiblePeopleId = "people";
  const complaintsId = "complaints";

  useEffect(() => {
    setNavigateProps([
      {
        navigateToSectionId: locationId,
        navigateToSectionText: "Locations",
        itemCount: pulledReport ? allPhoneAddresses(pulledReport).length : 0,
        loading: loading,
      },
      {
        navigateToSectionId: possibleBusinessId,
        navigateToSectionText: "Business Results",
        itemCount: pulledReport
          ? pulledReport.phoneReportSummary.numberOfBusinessResults
          : 0,
        loading: loading,
        displayIfZero: false,
      },
      {
        navigateToSectionId: possiblePeopleId,
        navigateToSectionText: "People Results",
        itemCount: pulledReport
          ? pulledReport.phoneReportSummary.numberOfPersonResults
          : 0,
        loading: loading,
        displayIfZero: false,
      },
      {
        navigateToSectionId: complaintsId,
        navigateToSectionText: "Complaints",
        itemCount: pulledReport
          ? pulledReport.phoneComplaints.result.length
          : 0,
        loading: loading,
      },
    ]);
  }, [pulledReport, loading]);

  useEffect(() => {
    if (
      pulledReport &&
      (allPhoneAddresses(pulledReport).length > 0 ||
        pulledReport.phoneReportSummary.numberOfBusinessResults > 0 ||
        pulledReport.phoneReportSummary.numberOfPersonResults > 0)
    ) {
      setDisplayNavigateProps(true);
    } else {
      setDisplayNavigateProps(false);
    }

    // Show SiteJabber prompt only for select brands
    if (!loading) {
      runSiteJabber();
    }
  }, [pulledReport, loading]);

  const generateReportSummaryAddress = (report: PhoneReportType) => {
    let chosenAddress;

    // check business address first
    if (report.results.businesses[0]) {
      if (report.results.businesses[0].address) {
        chosenAddress = [
          `${report.results.businesses[0].address.fullAddress}`,
          `${
            report.results.businesses[0].address.city
              ? report.results.businesses[0].address.city
              : null
          }, ${
            report.results.businesses[0].address.state
              ? report.results.businesses[0].address.state
              : null
          } ${
            report.results.businesses[0].address.postalCode
              ? report.results.businesses[0].address.postalCode
              : null
          }`,
        ];
      }
      //then check people
    } else if (report.results.people[0]) {
      if (
        report.results.people[0].address[0] &&
        report.results.people[0].address[0].fullAddress
      ) {
        chosenAddress = [
          report.results.people[0].address[0].fullAddress,
          `${report.results.people[0].address[0].city}, ${report.results.people[0].address[0].state} ${report.results.people[0].address[0].postalCode}`,
        ];
      }
      //lastly go with report Summary
    } else if (
      report.phoneReportSummary.phoneCity &&
      report.phoneReportSummary.phoneState
    ) {
      chosenAddress = [
        `${report.phoneReportSummary.phoneCity}, ${report.phoneReportSummary.phoneState}`,
      ];
    }

    return (
      <>
        <Text size="sm">
          {chosenAddress && chosenAddress[0] ? chosenAddress[0] : null}
        </Text>
        <Text size="sm">
          {chosenAddress && chosenAddress[1] ? chosenAddress[1] : null}
        </Text>
      </>
    );
  };

  const sendMemberEvent = () => {
    dispatch(
      sendMemberEventAsync({
        EventCategory: "Phone Report",
        EventAction: "Download Express",
        MetaData: JSON.stringify({
          key: "pdfDownload",
          value: `Report Name: ${
            pulledReport && pulledReport.phoneReportSummary
              ? pulledReport.phoneReportSummary.phone
              : ""
          }`,
        }),
      }),
    );
  };

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current as HTMLElement,
    onBeforeGetContent: () => {
      return new Promise<void>(resolve => {
        const originalTitle = document.title;
        document.title =
          pulledReport && pulledReport.phoneReportSummary
            ? `${originalTitle} - ${pulledReport.phoneReportSummary.phone}`
            : originalTitle;
        setPrintReady(true);

        // Function to check if elements with the specific class still exist
        const checkIfElementsClosed = () => {
          const elements = document.querySelectorAll(
            ".sc-show-more__icon--closed",
          );
          if (elements.length === 0) {
            // No elements found, resolve the promise to proceed with printing
            resolve();
          } else {
            // Elements still found, check again after a short delay
            setTimeout(checkIfElementsClosed, 50);
          }
        };

        // Start the checking process
        checkIfElementsClosed();

        // Ensure the title is reset after printing is triggered
        setTimeout(() => {
          document.title = originalTitle; // Restore the original document title
        }, 5000);
      });
    },
    onAfterPrint: () => {
      setPrintReady(false);
    },
    copyStyles: true,
  });

  // this need to be added to state to be used by possible location component
  // will currently pass as a prop
  const allPhoneAddresses = (report: PhoneReportType) => {
    let allAddressArray: {
      name?: any;
      fullAddress: any;
      city: any;
      state: any;
      postalCode: any;
      longitude: any;
      latitude: any;
    }[] = [];
    if (report.results.businesses) {
      report.results.businesses.forEach(item => {
        if (item.address) {
          let addressObj = {
            name: item.name,
            fullAddress: item.address.fullAddress,
            city: item.address.city,
            state: item.address.state,
            postalCode: item.address.postalCode,
            longitude: item.address.longitude,
            latitude: item.address.latitude,
          };
          allAddressArray.push(addressObj);
        }
      });
    }
    if (report.results.people) {
      report.results.people.forEach(item => {
        if (item.address) {
          let addressObj = {
            fullAddress: item.address[0].fullAddress,
            city: item.address[0].city,
            state: item.address[0].state,
            postalCode: item.address[0].postalCode,
            name: "",
            longitude: item.address[0].longitude,
            latitude: item.address[0].latitude,
          };
          if (item.name[0]) {
            addressObj.name =
              item.name[0].firstName + " " + item.name[0].lastName;
          }
          allAddressArray.push(addressObj);
        }
      });
    }
    return allAddressArray ? allAddressArray : [];
  };

  return (
    <>
      {pulledReport || loading ? (
        <PrintableComponent
          ref={contentToPrint}
          printReady={printReady}
          reportType="phone"
          header={
            pulledReport
              ? formatPhoneNumber(pulledReport.searchCriteria.phone)
              : undefined
          }
          subtitle={
            pulledReport
              ? generatePhoneReportHighlights(pulledReport, false)
              : undefined
          }
          date={new Date().toLocaleDateString()}
        >
          <ReportTitle
            reportTitle="Phone Report"
            reportType="phone"
            handleDownload={
              loading
                ? () => {
                    console.log("Wait for loading");
                  }
                : () => {
                    sendMemberEvent();
                    handlePrint(null, () => null);
                  }
            }
          />
          <Sheet>
            <Padding
              space="md"
              top="none"
              breakpointStyles={{
                mobile: {
                  space: "none",
                },
                tablet: {
                  space: "none",
                },
              }}
            >
              <Layout
                type="grid"
                gridProps={{
                  templateColumns: "1-column",
                  gap: displayNavigateProps || loading ? "md" : undefined,
                }}
                breakpointStyles={{
                  desktop: {
                    type: "grid",
                    gridProps: { gap: "md", templateColumns: componentFormat },
                  },
                }}
                shrinkChildren
              >
                <Layout
                  type="flex"
                  flexProps={{ gap: "md", direction: "column" }}
                  passThroughClassess="page-phone-report__left-column"
                >
                  <Sticky
                    space="sm"
                    turnOffAtBreakPoint={["mobile"]}
                    direction="header-offset"
                  >
                    <Layout
                      type="flex"
                      flexProps={{ direction: "column", gap: "md" }}
                    >
                      <ReportSummary
                        reportDate={
                          loading ? (
                            <LoadingPlaceholder
                              height={"16px"}
                              width={"10rem"}
                            />
                          ) : (
                            `Report Date ${parseDate(
                              new Date().toLocaleDateString(), // change to pulled report date
                              "MM/DD/YYYY",
                            )}`
                          )
                        }
                        reportTitle={
                          loading ? (
                            <LoadingPlaceholder
                              height={"25px"}
                              width={"15rem"}
                            />
                          ) : pulledReport ? (
                            formatPhoneNumber(pulledReport.searchCriteria.phone)
                          ) : null
                        }
                        reportHighlights={
                          loading ? (
                            <LoadingPlaceholder
                              height={"18px"}
                              width={"12rem"}
                            />
                          ) : pulledReport ? (
                            generatePhoneReportHighlights(pulledReport, true)
                          ) : null
                        }
                        reportDetails={
                          loading ? (
                            <LoadingPlaceholder
                              height={"24px"}
                              width={"10rem"}
                            />
                          ) : pulledReport ? (
                            generateReportSummaryAddress(pulledReport)
                          ) : null
                        }
                      />
                      {displayNavigateProps || loading ? (
                        <Padding
                          space="none"
                          breakpointStyles={{
                            mobile: {
                              left: "md",
                              right: "md",
                            },
                            tablet: {
                              left: "md",
                              right: "md",
                            },
                          }}
                        >
                          <NavigateToSection
                            items={navigateProps}
                            tooltipContent={`The ${brandInfo.name} Phone Report provides you with many different types of data and information. The Navigate to Section menu provides access and counts of records contained in the report for each category of data.`}
                          />
                        </Padding>
                      ) : null}
                    </Layout>
                  </Sticky>
                </Layout>

                <Layout
                  type="flex"
                  flexProps={{ direction: "column", gap: "md" }}
                >
                  {displayNavigateProps || loading ? (
                    <Padding
                      space="none"
                      top="md"
                      breakpointStyles={{
                        mobile: {
                          left: "md",
                          right: "md",
                        },
                        tablet: {
                          left: "md",
                          right: "md",
                        },
                      }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        <PossibleLocations
                          sectionId={locationId}
                          addresses={
                            pulledReport ? allPhoneAddresses(pulledReport) : []
                          }
                          printReady={printReady}
                        />
                        <PossibleBusiness
                          sectionId={possibleBusinessId}
                          printReady={printReady}
                        />
                        <PossiblePeople
                          sectionId={possiblePeopleId}
                          printReady={printReady}
                        />
                      </Layout>
                    </Padding>
                  ) : null}
                  <Complaints
                    sectionId={complaintsId}
                    printReady={printReady}
                  />
                </Layout>
              </Layout>
            </Padding>
          </Sheet>
        </PrintableComponent>
      ) : (
        <>
          <ReportTitle reportTitle="Phone Report" reportType="phone" />
          <NoResults
            searchType="phone"
            header={
              <Text textAlign="center" fontWeight="bold" tag="h2">
                No results for
              </Text>
            }
            noResultText={
              <Text tag="h2" fontWeight="bold" textAlign="center">
                {phoneSearch ? formatPhoneNumber(phoneSearch) : null}
              </Text>
            }
            subHeader={
              <Text textAlign="center">
                We’ve run searches and found no data for this input. In some
                cases this may be due to privacy preferences or regulations.
              </Text>
            }
          />
        </>
      )}
      <div className="stjr-instant-feedback"></div>
    </>
  );
};

export default PhoneReport;
