import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";

// components
import { Layout, Text, Map, LoadingPlaceholder } from "@kidslivesafe/sc-react";

// redux getters
import {
  selectPeopleReportHistory,
  selectReportHistoryStatus,
} from "../../../features/reports/reportsSlice";
import { selectMapboxKey } from "../../../features/brand/brandSlice";

// types
import { PeopleReportHistory } from "../../../features/reports/reportTypes";
import { getCoordinates } from "../../../features/reports/reportsAPI";
import { generateList } from "../../../utils";

interface PinData {
  latitude: number;
  longitude: number;
  popup: React.ReactNode;
  element?: React.ReactNode;
}

interface PeopleReportHistoryWithCoordinates extends PeopleReportHistory {
  latitude: number;
  longitude: number;
}

const PeopleHistoryMap: React.FC<{}> = () => {
  const reportHistory = useAppSelector(selectPeopleReportHistory);
  const reportHistoryStatus = useAppSelector(selectReportHistoryStatus);
  const [mapPins, setMapPins] = React.useState<PinData[]>([]);

  const dispatch = useAppDispatch();

  const loading = reportHistoryStatus === "loading";

  const mapboxToken = useAppSelector(selectMapboxKey);

  useEffect(() => {
    const enrichReportsWithCoordinates = async (
      reports: PeopleReportHistory[],
    ) => {
      const addressMap = reports.slice(0, 5).map((report, index) => {
        if (report.city && report.fullAddress && report.state && report.zip) {
          return {
            index,
            address: {
              fullAddress: report.fullAddress || "",
              city: report.city || "",
              state: report.state,
              postalCode: report.zip,
              latitude: 0,
              longitude: 0,
            },
          };
        } else {
          return null; // Skip reports with no valid address
        }
      });

      const validAddressMap = addressMap.filter(entry => entry !== null) as {
        index: number;
        address: {
          fullAddress: string;
          city: string;
          state: string;
          postalCode: string;
          latitude: number;
          longitude: number;
        };
      }[];

      const addresses = validAddressMap.map(entry => entry.address);

      const coordinateData: any = await getCoordinates(addresses);

      const enrichedReports = [
        ...reports,
      ] as PeopleReportHistoryWithCoordinates[];
      validAddressMap.forEach(({ index }, i) => {
        const coordinate = coordinateData[i];
        if (!coordinate?.error) {
          const { lat: latitude, lon: longitude } = coordinate;
          enrichedReports[index] = {
            ...enrichedReports[index],
            latitude,
            longitude,
          };
        } else {
          enrichedReports[index] = {
            ...enrichedReports[index],
            latitude: 0,
            longitude: 0,
          };
        }
      });

      return enrichedReports;
    };

    const preparePeoplePinData = (
      history: PeopleReportHistoryWithCoordinates[],
    ) => {
      const pinData = history
        .map(entry => {
          const latitude = Number(entry.latitude);
          const longitude = Number(entry.longitude);

          if (
            !isNaN(latitude) &&
            !isNaN(longitude) &&
            latitude !== 0 &&
            longitude !== 0 &&
            latitude >= 24.396308 &&
            latitude <= 49.384358 &&
            longitude >= -125.0 &&
            longitude <= -66.93457
          ) {
            return {
              latitude,
              longitude,
              popup: (
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Text fontWeight="bold" size="sm">
                    {entry.firstName} {entry.middleName} {entry.lastName}
                  </Text>
                  <Text size="xxs">
                    {entry.fullAddress},
                    {generateList(
                      [
                        {
                          value: entry.city ? entry.city : null,
                          label: " ",
                        },
                        {
                          value: generateList(
                            [
                              {
                                value: entry.state ? entry.state : null,
                                label: " ",
                              },
                              {
                                value: entry.zip
                                  ? entry.zip.split("-")[0]
                                  : null,
                                label: " ",
                              },
                            ],
                            " ",
                          ),
                          label: "",
                        },
                      ],
                      ", ",
                    )}
                  </Text>
                </Layout>
              ),
            };
          } else {
            // Skip this entry if the coordinates are invalid
            return null;
          }
        })
        // Remove null entries
        .filter((pin): pin is Exclude<typeof pin, null> => pin !== null);
      return pinData;
    };

    const fetchAndPreparePins = async () => {
      if (reportHistory && reportHistory.length > 0) {
        // Limit to the first 5 items
        const limitedReportHistory = reportHistory.slice(0, 5);
        const enrichedReports =
          await enrichReportsWithCoordinates(limitedReportHistory);
        const pins = preparePeoplePinData(enrichedReports);
        setMapPins(pins);
      }
    };

    fetchAndPreparePins();
  }, [reportHistory, dispatch]);

  return (
    <>
      <>
        {loading ? (
          <LoadingPlaceholder width="100%" height="500px" />
        ) : (
          reportHistory &&
          reportHistory.length > 0 && (
            <Map
              mapboxToken={mapboxToken}
              markerData={reportHistory ? mapPins : undefined}
              setBoundaryMarkerData={reportHistory ? mapPins : undefined}
            />
          )
        )}
      </>
    </>
  );
};

export default PeopleHistoryMap;
