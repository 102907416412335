import React, { useEffect, useRef, useState } from "react";
import PrintableComponent from "../../../components/UIComponents/PrintableComponent/PrintableComponent";

import {
  Layout,
  Sticky,
  NavigateToSection,
  NavigateToSectionItemProps,
  Padding,
  Text,
  ReportTitle,
  Sheet,
  ReportSummary,
  LoadingPlaceholder,
  Margin,
  Modal,
  Icon,
  IconList,
  Input,
  Button,
  LineItemSummary,
  Table,
  AnchorLink,
} from "@kidslivesafe/sc-react";
import {
  fullCapsText,
  generateList,
  generateNeighborhoodReportHighlights,
  parseDate,
  scoreGenerator,
} from "../../../utils";
import { useReactToPrint } from "react-to-print";
import { GridProps } from "@kidslivesafe/sc-react/lib/atoms/Layout/LayoutTypes";

import { selectBrandInfo } from "../../../features/brand/brandSlice";

import "./neighborhood-report.css";
import "./print.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import useSiteJabberDispatch from "../../../hooks/siteJabberHook";
import {
  selectNeighborhoodMapModal,
  selectNeighborhoodMapModalContent,
  selectNeighborhoodReport,
  selectNeighborhoodReportStatus,
  setNeighborhoodMapModal,
} from "../../../features/reports/reportsSlice";
import NoResults from "../../../components/UIComponents/NoResults/NoResults";
import { selectNeighborhoodReportSearchParams } from "../../../features/multisearch/multisearchSlice";
import { sendMemberEventAsync } from "../../../features/admin/adminSlice";
import OffenderList from "./OffenderList";
import MapOfNeighborhood from "./MapOfNeighborhood";
import {
  checkCurrentlyMonitoringNeighborhood,
  deleteNeighborhoodWatchMonitoringAreaAsync,
  saveNeighborhoodWatchMonitoringAreaAsync,
  selectNeighborhoodMonitoringAreas,
  updateNeighborhoodWatchMonitoringAreaAsync,
  selectMaxMonitoringReached,
  selectNeighborhoodMonitoringStatus,
} from "../../../features/monitoring/monitoringSlice";

import { Offender } from "../../../features/reports/reportTypes";
import { renderArrayOfObjectsPrep } from "../../../utils";
import { formatAddress } from "@kidslivesafe/sc-foundation";
import TimedMonitoringModal from "../../../components/UIComponents/TimedMonitoringModal/TimedMonitoringModal";
import { NeighborhoodSaveMonitoringInterface } from "../../../features/monitoring/monitoringAPI";

const NeighborhoodReport: React.FC = () => {
  // local state
  const [navigateProps, setNavigateProps] = useState<
    NavigateToSectionItemProps[]
  >([]);
  const [openMonitoringModal, setOpenMonitoringModal] =
    useState<boolean>(false);
  const [nickname, setNickname] = useState<string>("");
  const [nicknameError, setNickNameError] = useState<boolean>(false);

  const [printReady, setPrintReady] = useState<boolean>(false);
  const contentToPrint = useRef<HTMLDivElement>(null);
  const promiseResolveRef = useRef<((value?: any) => void) | null>(null);
  const openOffenderModal = useAppSelector(selectNeighborhoodMapModal);
  const neighborhoodMapModalContent = useAppSelector(
    selectNeighborhoodMapModalContent,
  );
  const dispatch = useAppDispatch();

  const neighborhoodSearch = useAppSelector(
    selectNeighborhoodReportSearchParams,
  );

  const neighborhoodMonitoringAreas = useAppSelector(
    selectNeighborhoodMonitoringAreas,
  );

  const monitoringLimitReached = useAppSelector(selectMaxMonitoringReached);
  const neighborhoodMonitoringStatus = useAppSelector(
    selectNeighborhoodMonitoringStatus,
  );

  useEffect(() => {
    // closes monitoring modal when max number of monitoring areas is hit
    if (monitoringLimitReached === true) {
      setOpenMonitoringModal(false);
    }
  }, [monitoringLimitReached]);

  useEffect(() => {
    if (printReady && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [printReady]);

  let componentFormat: GridProps["templateColumns"] = printReady
    ? "1-column"
    : "1fr-2fr";

  const loading = useAppSelector(selectNeighborhoodReportStatus) === "loading";
  const monitoringLoading = neighborhoodMonitoringStatus === "loading";
  const pulledReport = useAppSelector(selectNeighborhoodReport);
  const brandInfo = useAppSelector(selectBrandInfo);
  const runSiteJabber = useSiteJabberDispatch();
  const currentlyMonitoring = useAppSelector(state =>
    checkCurrentlyMonitoringNeighborhood(state, pulledReport?.addressObject),
  );
  const currentlyMonitoringArea = useAppSelector(state =>
    state.monitoring.neighborhoodMonitoringAreas.find(
      area =>
        area.address === pulledReport?.addressObject.address &&
        area.city === pulledReport?.addressObject.city &&
        area.state === pulledReport?.addressObject.state &&
        area.zipCode === pulledReport?.addressObject.zipCode,
    ),
  );

  // constants
  const mapOfNeighborhoodId = "map-of-neighborhood";
  const offenderListId = "offender-list";

  // effects
  /* generate Navigation Props */
  useEffect(() => {
    setNavigateProps([
      {
        navigateToSectionId: mapOfNeighborhoodId,
        navigateToSectionText: "Map of Neighborhood",
        itemCount:
          pulledReport && pulledReport.offenders
            ? pulledReport.offenders.length
            : 0,
        newInfo: false,
        loading: loading,
      },
      {
        navigateToSectionId: offenderListId,
        navigateToSectionText: "Offender List",
        itemCount:
          pulledReport && pulledReport.offenders
            ? pulledReport.offenders.length
            : 0,
        newInfo: false,
        loading: loading,
      },
    ]);
  }, [pulledReport, loading]);

  useEffect(() => {
    // Show SiteJabber prompt only for select brands
    if (!loading) {
      runSiteJabber();
    }
  }, [loading]);

  const sendMemberEvent = () => {
    dispatch(
      sendMemberEventAsync({
        EventCategory: "Neighborhood Report",
        EventAction: "Download Express",
        MetaData: JSON.stringify({
          key: "pdfDownload",
          value: `Report Name: ${
            pulledReport && pulledReport.addressSearch
              ? fullCapsText(
                  generateList([
                    {
                      value: pulledReport.addressSearch,
                      label: "",
                    },
                  ]),
                )
              : ""
          }`,
        }),
      }),
    );
  };

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current as HTMLElement,
    onBeforeGetContent: () => {
      return new Promise<void>(resolve => {
        const originalTitle = document.title;
        document.title =
          pulledReport && pulledReport.addressSearch
            ? `${originalTitle} - ${fullCapsText(
                generateList([
                  {
                    value: pulledReport.addressSearch,
                    label: "",
                  },
                ]),
              )}`
            : originalTitle;
        setPrintReady(true);

        // Function to check if elements with the specific class still exist
        const checkIfElementsClosed = () => {
          const elements = document.querySelectorAll(
            ".sc-container-line-item__content--closed",
          );
          const showMoreElements = document.querySelectorAll(
            ".sc-show-more__icon--closed",
          );
          if (elements.length === 0 && showMoreElements.length === 0) {
            // No elements found, resolve the promise to proceed with printing
            resolve();
          } else {
            // Elements still found, check again after a short delay
            setTimeout(checkIfElementsClosed, 50);
          }
        };

        // Start the checking process
        checkIfElementsClosed();

        // Ensure the title is reset after printing is triggered
        setTimeout(() => {
          document.title = originalTitle; // Restore the original document title
        }, 5000);
      });
    },
    onAfterPrint: () => {
      setPrintReady(false);
    },
    copyStyles: true,
  });

  const offenderSummary = renderArrayOfObjectsPrep(
    pulledReport?.offenders || [],
    [
      "totalOffenders",
      "distance",
      "latitude",
      "longitude",
      "createDate",
      "offenderID",
      "name",
      "zipCode",
      "state",
      "city",
    ],
    [
      {
        key: ["offense"],
        keyFormat: () => "Offense(s)",
      },
      {
        key: ["address"],
        valueFormat: (value, currentObject: Offender) => {
          let formattedAddress = {
            addressLine1: value,
            city: currentObject.city,
            state: currentObject.state,
            zip: currentObject.zipCode,
          };
          return formatAddress({ address: formattedAddress });
        },
      },
    ],
  );

  const renderOffenderDetails = (offender: Offender, index: number) => {
    return (
      <Layout
        type="flex"
        flexProps={{ direction: "column", alignItems: "center" }}
      >
        <img
          src={`https://photo.familywatchdog.us/OffenderPhoto/OffenderPhoto.aspx?id=${offender.offenderID}`}
          alt={`picture_${offender.name}`}
          height={173.25}
          width={130.5}
        />
        <Padding top="xs">
          <Text fontWeight="bold" tag="h2" textAlign="center">
            {offender.name.split(", ").reverse().join(" ")}
          </Text>
        </Padding>
        <LineItemSummary backgroundColor={false} />
        <Padding left="md" right="md">
          <Table
            tableDataObject={offenderSummary[index]}
            orderOfProperties={["Offense(s)"]}
          />
        </Padding>
      </Layout>
    );
  };

  const handleSubmitNickName = (nickname: string) => {
    if (nickname.length > 15 || nickname.length === 0) {
      setNickNameError(true);
    } else {
      setNickNameError(false);
    }
  };

  return (
    <>
      {pulledReport || loading ? (
        <PrintableComponent
          ref={contentToPrint}
          printReady={printReady}
          reportType="neighborhood"
          header={
            pulledReport && pulledReport.addressSearch
              ? fullCapsText(
                  generateList([
                    {
                      value: pulledReport.addressSearch,
                      label: "",
                    },
                  ]),
                )
              : undefined
          }
          subtitle={
            pulledReport
              ? generateNeighborhoodReportHighlights(pulledReport)
              : null
          }
          date={scoreGenerator()}
        >
          <ReportTitle
            reportTitle="Neighborhood Report"
            reportType="neighborhood"
            handleDownload={
              loading
                ? () => {
                    console.log("Wait for loading");
                  }
                : () => {
                    sendMemberEvent();
                    handlePrint(null, () => null);
                  }
            }
          />
          <Sheet>
            <Padding
              space="md"
              top="none"
              breakpointStyles={{
                mobile: {
                  space: "none",
                  bottom: "md",
                },
              }}
            >
              <Layout
                type="grid"
                gridProps={{ gap: "md", templateColumns: "1-column" }}
                breakpointStyles={{
                  desktop: {
                    type: "grid",
                    gridProps: { templateColumns: componentFormat, gap: "md" },
                  },
                }}
                shrinkChildren
              >
                <>
                  <Layout
                    type="flex"
                    flexProps={{ gap: "md", direction: "column" }}
                    passThroughClassess="page-neighborhood-report__left-column"
                  >
                    <Sticky
                      space="sm"
                      turnOffAtBreakPoint={["mobile"]}
                      direction="header-offset"
                    >
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        <ReportSummary
                          reportDate={
                            loading ? (
                              <LoadingPlaceholder
                                height={"16px"}
                                width={"10rem"}
                              />
                            ) : (
                              `Report Date ${parseDate(
                                scoreGenerator(),
                                "MM/DD/YYYY",
                              )}`
                            )
                          }
                          reportTitleWordBreak="break-word"
                          reportTitle={
                            loading ? (
                              <LoadingPlaceholder
                                height={"25px"}
                                width={"15rem"}
                              />
                            ) : pulledReport && pulledReport.addressSearch ? (
                              fullCapsText(
                                generateList([
                                  {
                                    value: pulledReport.addressSearch,
                                    label: "",
                                  },
                                ]),
                              )
                            ) : (
                              ""
                            )
                          }
                          reportHighlights={
                            loading ? (
                              <LoadingPlaceholder
                                height={"18px"}
                                width={"12rem"}
                              />
                            ) : pulledReport ? (
                              generateNeighborhoodReportHighlights(pulledReport)
                            ) : null
                          }
                          newInfo={false}
                          monitoringInitialState={
                            loading ? false : !!currentlyMonitoring
                          }
                          handleMonitoring={() => {
                            if (pulledReport && currentlyMonitoring) {
                              dispatch(
                                deleteNeighborhoodWatchMonitoringAreaAsync({
                                  id: currentlyMonitoring,
                                }),
                              );
                            } else {
                              dispatch(
                                saveNeighborhoodWatchMonitoringAreaAsync({
                                  address: pulledReport?.addressObject.address,
                                  addressLine2:
                                    pulledReport?.addressObject.addressLine2,
                                  addressSearch: pulledReport?.addressSearch,
                                  city: pulledReport?.addressObject.city,
                                  state: pulledReport?.addressObject.state,
                                  zipCode: pulledReport?.addressObject.zipCode,
                                  latitude: pulledReport?.searchLatitude,
                                  longitude: pulledReport?.searchLongitude,
                                  radius: 5,
                                  nickname: `Neighborhood ${neighborhoodMonitoringAreas.length + 1}`,
                                  latestOffendersCount:
                                    pulledReport?.offendersCount,
                                }),
                              );
                              setOpenMonitoringModal(true);
                            }
                          }}
                          monitoringInput={"test"}
                          monitoringSuccess={
                            monitoringLimitReached ? "failed" : undefined
                          }
                        />
                        <Padding
                          space="none"
                          breakpointStyles={{
                            mobile: {
                              left: "md",
                              right: "md",
                            },
                          }}
                        >
                          <NavigateToSection
                            items={navigateProps}
                            tooltipContent={`The ${brandInfo.name} Neighborhood Report provides you with many different types of data and information. The Navigate to Section menu provides access and counts of records contained in the report for each category of data.`}
                          />
                        </Padding>
                      </Layout>
                    </Sticky>
                  </Layout>
                  <Margin space="none">
                    <Padding
                      space="none"
                      top="md"
                      breakpointStyles={{
                        mobile: {
                          left: "md",
                          right: "md",
                          top: "none",
                        },
                        tablet: {
                          top: "none",
                        },
                      }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        <MapOfNeighborhood
                          containerId={mapOfNeighborhoodId}
                          printReady={printReady}
                        />
                        <OffenderList
                          containerId={offenderListId}
                          printReady={printReady}
                        />
                      </Layout>
                    </Padding>
                  </Margin>
                </>
              </Layout>
            </Padding>
          </Sheet>
        </PrintableComponent>
      ) : (
        <>
          <ReportTitle reportTitle="Neighborhood Report" reportType="social" />

          <NoResults
            searchType="neighborhood"
            soloTab
            header={
              <Text textAlign="center" fontWeight="bold" tag="h2">
                No results for
              </Text>
            }
            subHeader={
              <Text textAlign="center">
                We’ve run searches and found no data for this input. In some
                cases this may be due to privacy preferences or regulations.
              </Text>
            }
            noResultText={
              <Text tag="h2" fontWeight="bold" textAlign="center">
                {neighborhoodSearch &&
                  generateList(
                    [
                      {
                        value: neighborhoodSearch.addressSearch.address
                          ? neighborhoodSearch.addressSearch.address
                          : null,
                        label: "",
                      },
                      {
                        value: neighborhoodSearch.addressSearch.addressLine2
                          ? neighborhoodSearch.addressSearch.addressLine2
                          : null,
                        label: "",
                      },
                      {
                        value: generateList(
                          [
                            {
                              value: neighborhoodSearch.addressSearch.city
                                ? neighborhoodSearch.addressSearch.city
                                : null,
                              label: "",
                            },
                            {
                              value: neighborhoodSearch.addressSearch.state
                                ? neighborhoodSearch.addressSearch.state
                                : null,
                              label: "",
                            },
                          ],
                          ", ",
                        ),
                        label: "",
                      },
                      {
                        value: neighborhoodSearch.addressSearch.zipCode
                          ? neighborhoodSearch.addressSearch.zipCode
                          : null,
                        label: "",
                      },
                    ],
                    " ",
                  )}
              </Text>
            }
          />
        </>
      )}
      <Modal
        open={openMonitoringModal}
        setOpen={() => {
          setOpenMonitoringModal(!openMonitoringModal);
          setNickname("");
        }}
        size="fit-content"
        xClose
        outSideClose
      >
        <Padding top="md" bottom="md">
          <Layout
            type="flex"
            flexProps={{ direction: "column", gap: "lg", alignItems: "center" }}
          >
            <Text tag="h2">Monitoring Zone Set</Text>
            <Text textAlign="center">
              If we detect changes, we'll notify you with a{" "}
              <Text tag="span" color="monitoring">
                <Icon name={IconList.IconMonitoring} size={20} block="inline" />
              </Text>{" "}
              and send you an alert.
            </Text>
            <Layout
              type="flex"
              fillHorizontal
              flexProps={{
                gap: "sm",
                direction: "column",
                alignItems: "center",
              }}
            >
              <Layout
                type="flex"
                fillHorizontal
                flexProps={{
                  gap: "xxs",
                  direction: "column",
                  alignItems: "center",
                }}
              >
                <Text size="sm">Set a name for your report (optional)</Text>
                <Text size="xxs" tag="i">
                  Please use 15 characters maximum
                </Text>
              </Layout>
              <Layout
                type="flex"
                fillHorizontal
                flexProps={{
                  gap: "xs",
                  direction: "column",
                  alignItems: "center",
                }}
              >
                <Input
                  id="neighborhood-report__monitoring-modal"
                  value={nickname}
                  handleChange={input => {
                    setNickname(input);
                    handleSubmitNickName(input);
                  }}
                  type="text"
                  placeholder="Neighborhood 1 (optional)"
                  fill="fill"
                  error={nicknameError}
                />
                <Button
                  buttonText="Set Name"
                  fill="fill"
                  disabled={monitoringLoading || nicknameError}
                  handleClick={() => {
                    if (currentlyMonitoringArea) {
                      if (nickname.length > 0) {
                        dispatch(
                          updateNeighborhoodWatchMonitoringAreaAsync({
                            address: currentlyMonitoringArea.address,
                            addressLine2: currentlyMonitoringArea.addressLine2,
                            addressSearch: pulledReport?.addressSearch,
                            city: currentlyMonitoringArea.city,
                            state: currentlyMonitoringArea.state,
                            zipCode: currentlyMonitoringArea.zipCode,
                            latitude: pulledReport?.searchLatitude,
                            longitude: pulledReport?.searchLongitude,
                            nickname: nickname,
                            radius: 5,
                            id: currentlyMonitoringArea.id,
                            latestOffendersCount: pulledReport?.offendersCount,
                          }),
                        );
                      }
                      setOpenMonitoringModal(false);
                      setNickname("");
                    } else {
                      setNickNameError(true);
                    }
                  }}
                />
                <AnchorLink
                  role="button"
                  size="xs"
                  handleClick={() => {
                    setOpenMonitoringModal(false);
                    setNickname("");
                  }}
                >
                  No Thanks, Exit
                </AnchorLink>
              </Layout>
            </Layout>
          </Layout>
        </Padding>
      </Modal>
      <Modal
        open={openOffenderModal}
        setOpen={() => dispatch(setNeighborhoodMapModal(!openOffenderModal))}
        size="fit-content"
        xClose
        outSideClose
      >
        <Padding>
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
              alignItems: "center",
            }}
          >
            {neighborhoodMapModalContent &&
              renderOffenderDetails(
                neighborhoodMapModalContent.offender,
                neighborhoodMapModalContent.index,
              )}
          </Layout>
        </Padding>
      </Modal>
      {pulledReport && pulledReport.addressObject && (
        <TimedMonitoringModal<NeighborhoodSaveMonitoringInterface>
          reportTitle={
            pulledReport && pulledReport.addressSearch
              ? fullCapsText(
                  generateList([
                    {
                      value: pulledReport.addressSearch,
                      label: "",
                    },
                  ]),
                )
              : ""
          }
          toggleMonitoring={address => {
            if (pulledReport && currentlyMonitoring) {
              dispatch(
                deleteNeighborhoodWatchMonitoringAreaAsync({
                  id: currentlyMonitoring,
                }),
              );
            } else {
              dispatch(saveNeighborhoodWatchMonitoringAreaAsync(address));
            }
          }}
          monitoringInput={{
            address: pulledReport.addressObject.address,
            addressLine2: pulledReport.addressObject.addressLine2
              ? pulledReport.addressObject.addressLine2
              : "",
            addressSearch: pulledReport.addressSearch,
            city: pulledReport.addressObject.city,
            state: pulledReport.addressObject.state,
            zipCode: pulledReport.addressObject.zipCode,
            latitude: pulledReport.searchLatitude,
            longitude: pulledReport.searchLongitude,
            radius: 5,
            nickname: `Neighborhood ${neighborhoodMonitoringAreas.length + 1}`,
            latestOffendersCount: pulledReport?.offendersCount,
          }}
          currentlyMonitoring={!!currentlyMonitoring}
          monitoringSuccess={
            monitoringLimitReached
              ? "failed"
              : !!currentlyMonitoring
                ? "success"
                : ""
          }
          loading={loading}
        />
      )}
      <div className="stjr-instant-feedback"></div>
    </>
  );
};

export default NeighborhoodReport;
