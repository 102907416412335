import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

// redux getters
import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import {} from "../../../utils";

// types
import { PropertyBusiness } from "../../../features/reports/reportTypes";

// components
import {
  Container,
  CollapsableItemList,
  ContainerLineItem,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

const BusinessDetails: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );

  const loading = propertyReportStatus === "loading";

  function getBusinessParameter(business: PropertyBusiness, parameter: string) {
    if (business.businessNames && business.businessNames[0]) {
      if (parameter === "name") {
        return business.businessNames[0]?.name?.toUpperCase();
      } else if (parameter === "type") {
        return business.businessNames[0].type;
      }
    }
    return null;
  }

  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Businesses at Address ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.businesses
                  ? pulledReport.businesses.length
                  : 0
              })`
        }`}
        containerId={containerId}
        tooltipContent="This section lists businesses which have been registered with this property as their primary address. It does not necessarily indicate the address is a commercial property such as an office building, retail space or warehouse."
        newInfo={reportDifferences?.businesses}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
            children
          />
        ) : pulledReport?.businesses && pulledReport.businesses[0] ? (
          <CollapsableItemList isOpen={printReady}>
            {pulledReport.businesses.map((business, index) => (
              <ContainerLineItem
                lineItemHeader={getBusinessParameter(business, "name")}
                lineItemSubHeader={getBusinessParameter(business, "type")}
                key={index}
                isOpen={printReady ? true : false}
              />
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No Businesses found at this address
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default BusinessDetails;
