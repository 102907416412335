import React from "react";
import { Padding } from "@kidslivesafe/sc-react";

export interface ReportPaddingWrapperProps {
  children: React.ReactNode;
}

const ReportPaddingWrapper: React.FC<ReportPaddingWrapperProps> = ({
  children,
}) => {
  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      {children}
    </Padding>
  );
};

export default ReportPaddingWrapper;
