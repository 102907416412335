import React, { useEffect } from "react";

import {
  Button,
  Modal,
  Select,
  Input,
  Layout,
  Text,
  Padding,
} from "@kidslivesafe/sc-react";

import { selectMemberExperienceData } from "../../features/authentication/authenticationSlice";

import { convertStateToAbbreviation } from "../../utils";

import { usePeopleSearchDispatch } from "../../hooks/peopleReportHook";
import { selectAllReportHistory } from "../../features/reports/reportsSlice";
import { useAppSelector } from "../../hooks/hooks";
import { selectAdminStatus } from "../../features/admin/adminSlice";

const OnboardingModal: React.FC = () => {
  const [peopleState, setPeopleState] = React.useState<string>("");
  const [peopleStateError, setPeopleStateError] =
    React.useState<boolean>(false);
  const [peopleFirstName, setPeopleFirstName] = React.useState<string>("");
  const [peopleFirstNameError, setPeopleFirstNameError] =
    React.useState<boolean>(false);
  const [peopleLastName, setPeopleLastName] = React.useState<string>("");
  const [peopleLastNameError, setPeopleLastNameError] =
    React.useState<boolean>(false);

  const [peopleError, setPeopleError] = React.useState<string | undefined>(
    undefined,
  );

  const adminStatus = useAppSelector(selectAdminStatus);
  const loading = adminStatus === "loading";

  const reportHistory = useAppSelector(selectAllReportHistory);
  const reportHistoryIsEmpty = !reportHistory || reportHistory.length === 0;

  const memberExperienceData = useAppSelector(selectMemberExperienceData);
  let showPIS63Modal =
    reportHistoryIsEmpty &&
    memberExperienceData &&
    memberExperienceData.hasOwnProperty("pis63SearchParams");

  const [firstName, lastName, state] =
    (memberExperienceData &&
      memberExperienceData.pis63SearchParams &&
      memberExperienceData?.pis63SearchParams.split(",")) ||
    [];

  const searchPeople = usePeopleSearchDispatch();

  useEffect(() => {
    if (
      peopleFirstName === "" &&
      peopleLastName === "" &&
      memberExperienceData.pis63SearchParams
    ) {
      setPeopleFirstName(firstName);
      setPeopleLastName(lastName);
    }
    if (memberExperienceData.pis63SearchParams) {
      if (state === "All States" && peopleState === "") {
        setPeopleState("All States");
      } else if (state !== "All States" && peopleState === "") {
        setPeopleState(convertStateToAbbreviation(state));
      }
    }
  }, [
    peopleFirstName,
    peopleLastName,
    peopleState,
    firstName,
    lastName,
    state,
    memberExperienceData.pis63SearchParams,
  ]);

  const handleSearchPeople = async () => {
    let error: boolean = false;

    if (peopleFirstName.length < 2) {
      setPeopleFirstNameError(true);
      setPeopleError("First name must be at least 2 characters");
      error = true;
    }

    if (peopleLastName.length < 2) {
      setPeopleLastNameError(true);
      setPeopleError("Last name must be at least 2 characters");
      error = true;
    }

    if (error) {
      return;
    }

    setPeopleError(undefined);

    try {
      searchPeople({
        firstName: peopleFirstName,
        lastName: peopleLastName,
        state: peopleState,
        city: "",
        minAge: "All Ages",
        maxAge: "All Ages",
      });
    } catch (error) {
      console.log("searchPeople error", error);
    }
  };

  const stateOptions = [
    { label: "All States", value: "All States" },
    { label: "AK", value: "AK" },
    { label: "AL", value: "AL" },
    { label: "AR", value: "AR" },
    { label: "AZ", value: "AZ" },
    { label: "CA", value: "CA" },
    { label: "CO", value: "CO" },
    { label: "CT", value: "CT" },
    { label: "DE", value: "DE" },
    { label: "FL", value: "FL" },
    { label: "GA", value: "GA" },
    { label: "HI", value: "HI" },
    { label: "IA", value: "IA" },
    { label: "ID", value: "ID" },
    { label: "IL", value: "IL" },
    { label: "IN", value: "IN" },
    { label: "KS", value: "KS" },
    { label: "KY", value: "KY" },
    { label: "LA", value: "LA" },
    { label: "MA", value: "MA" },
    { label: "MD", value: "MD" },
    { label: "ME", value: "ME" },
    { label: "MI", value: "MI" },
    { label: "MN", value: "MN" },
    { label: "MO", value: "MO" },
    { label: "MS", value: "MS" },
    { label: "MT", value: "MT" },
    { label: "NC", value: "NC" },
    { label: "ND", value: "ND" },
    { label: "NE", value: "NE" },
    { label: "NH", value: "NH" },
    { label: "NJ", value: "NJ" },
    { label: "NM", value: "NM" },
    { label: "NV", value: "NV" },
    { label: "NY", value: "NY" },
    { label: "OH", value: "OH" },
    { label: "OK", value: "OK" },
    { label: "OR", value: "OR" },
    { label: "PA", value: "PA" },
    { label: "RI", value: "RI" },
    { label: "SC", value: "SC" },
    { label: "SD", value: "SD" },
    { label: "TN", value: "TN" },
    { label: "TX", value: "TX" },
    { label: "UT", value: "UT" },
    { label: "VA", value: "VA" },
    { label: "VT", value: "VT" },
    { label: "WA", value: "WA" },
    { label: "WI", value: "WI" },
    { label: "WV", value: "WV" },
    { label: "WY", value: "WY" },
  ];

  return (
    <>
      {memberExperienceData && memberExperienceData.pis63SearchParams ? (
        <Modal
          open={showPIS63Modal}
          setOpen={() => {
            showPIS63Modal = false;
          }}
          size="expanded-width"
          scrollable={false}
          useShowModal={false}
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "sm",
              alignItems: "center",
            }}
          >
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "sm",
              }}
            >
              <div>
                <Text textAlign="center" tag="h2" fontWeight="semibold">
                  Welcome! <br /> We have compiled results for {firstName}{" "}
                  {lastName}
                </Text>
                <Padding top="md">
                  <Text textAlign="center" size="sm">
                    Don’t see the record you’re looking for?
                    <br /> Take advantage of unlimited searches.
                  </Text>
                </Padding>

                <Padding top="md">
                  <Text textAlign="center" size="sm">
                    Your membership includes access to people, property, phone
                    and social reports with up to 100 individual report pulls
                    per month
                  </Text>
                </Padding>
              </div>
              <Text textAlign="center" size="sm">
                Try searching by address or phone number.
              </Text>
            </Layout>
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "sm",
                alignItems: "center",
              }}
            >
              <Padding space="sm">
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    gap: "sm",
                    alignItems: "center",
                  }}
                >
                  <Input
                    id="pis63Modal_firstName"
                    value={peopleFirstName}
                    handleChange={input => {
                      setPeopleFirstName(input);
                    }}
                    type="text"
                    placeholder={""}
                    fill="fill"
                    error={peopleFirstNameError}
                  />
                  <Input
                    id="pis63Modal_lastName"
                    value={peopleLastName}
                    handleChange={input => {
                      setPeopleLastName(input);
                    }}
                    type="text"
                    placeholder={""}
                    fill="fill"
                    error={peopleLastNameError}
                  />
                  <Select
                    label="State"
                    error={peopleStateError}
                    handleError={setPeopleStateError}
                    onOptionSelected={setPeopleState}
                    options={stateOptions}
                    value={peopleState}
                  />
                  {peopleError && (
                    <Text color="error" size="sm">
                      {peopleError}
                    </Text>
                  )}
                  <Button
                    buttonText="Continue"
                    handleClick={handleSearchPeople}
                    clicked={loading}
                    loading={loading}
                  />
                </Layout>
              </Padding>
            </Layout>
          </Layout>
        </Modal>
      ) : null}
    </>
  );
};

export default OnboardingModal;
