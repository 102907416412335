import React from "react";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  selectMonitoring,
  selectMonitoringStatus,
  selectPropertyMonitoring,
  setRenameReport,
  setToggleMonitoringModal,
  togglePropertyMonitoringAsync,
} from "../../../features/monitoring/monitoringSlice";

import {
  Container,
  Text,
  Layout,
  Monitoring,
  ContainerLineItem,
  ReportLink,
  LoadingPlaceholder,
  Padding,
  MonitoringContainer,
  Icon,
  IconList,
  AnchorLink,
} from "@kidslivesafe/sc-react";

import { PropertyMonitoringInterface } from "../../../features/monitoring/monitoringAPI";
import {
  formatAddress,
  fullCapsText,
  generateList,
  generatePropertyReportHighlights,
  parseDate,
} from "../../../utils";

import {
  PullPropertyReportParams,
  usePropertySearchDispatch,
} from "../../../hooks/propertyReportHook";

const PropertyReportMonitoring: React.FC = () => {
  const dispatch = useAppDispatch();

  const monitoring = useAppSelector(selectMonitoring);

  const propertyMonitoring = useAppSelector(selectPropertyMonitoring);
  const monitoringStatus = useAppSelector(selectMonitoringStatus);

  const pullPropertyReport = usePropertySearchDispatch();

  type MonitoringType = PropertyMonitoringInterface;

  function sortMonitoringArray(
    monitoringArray: (MonitoringType | null)[],
  ): (MonitoringType | null)[] {
    return monitoringArray.sort((a, b) => {
      if (!a) return 1;
      if (!b) return -1;
      if (!a && !b) return 0;

      const getTimestamp = (item: MonitoringType): number => {
        if (item.createdDate) {
          return new Date(
            parseDate(item.createdDate.toString(), "down-to-the-second"),
          ).getTime();
        }

        return item.version
          ? new Date(parseDate(item.version, "down-to-the-second")).getTime()
          : 0;
      };

      const timeA = getTimestamp(a);
      const timeB = getTimestamp(b);

      return timeB - timeA;
    });
  }

  let monitoringArray: any = [];

  monitoringArray = monitoringArray.concat(monitoring);
  monitoringArray = monitoringArray.concat(propertyMonitoring);

  monitoringArray = sortMonitoringArray(monitoringArray);
  console.log("monitoringArray", monitoringArray);

  const renderMonitoring = (
    report: PropertyMonitoringInterface | undefined,
    index: number,
  ) => {
    if (report && report.type === "property") {
      return (
        <MonitoringContainer
          key={`${report.type}-${report.addressLine1}`}
          active
          fixedHeight={false}
          newInfo={report.hasChanges}
        >
          <Padding space="md">
            <Layout type="flex" flexProps={{ gap: "md", direction: "column" }}>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    alignItems: "center",
                  }}
                  passThroughClassess="dashboard__monitoring-container__title"
                >
                  <Icon name={IconList.IconProperty} size={24} />
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      alignItems: "center",
                      wrap: "wrap",
                    }}
                  >
                    <Text size="sm" fontWeight="semibold" textWrap="wrap">
                      {report.nickname}
                    </Text>
                    <AnchorLink
                      size="xs"
                      color="primary"
                      role="button"
                      hoverUnderline
                      handleClick={() => {
                        dispatch(setRenameReport(report));
                        dispatch(setToggleMonitoringModal(true));
                      }}
                    >
                      (rename)
                    </AnchorLink>
                  </Layout>
                </Layout>
                <Monitoring
                  handleMonitoring={dto =>
                    dispatch(togglePropertyMonitoringAsync(dto))
                  }
                  monitoringInput={{
                    addressLine1: report.addressLine1,
                    addressLine2: report.addressLine2,
                    city: report.city,
                    state: report.state,
                    zip: report.zip,
                    nickname: report.nickname,
                    version: report.version,
                    bedrooms: report.bedrooms,
                    totalBaths: report.totalBaths,
                    sqft: report.sqft,
                    lotAcres: report.lotAcres,
                  }}
                  initialState={true}
                />
              </Layout>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  direction: "column",
                }}
              >
                <ContainerLineItem
                  lineItemHeader={
                    <Layout type="flex" flexProps={{ alignItems: "center" }}>
                      <ReportLink<PullPropertyReportParams>
                        handleClick={pullPropertyReport}
                        inputValue={{
                          addressLine1: report.addressLine1,
                          addressLine2: report.addressLine2,
                          city: report.city,
                          state: report.state,
                          zip: report.zip,
                        }}
                        text={fullCapsText(
                          formatAddress(
                            {
                              addressLine1: report.addressLine1,
                              addressLine2: report.addressLine2,
                              city: report.city,
                              state: report.state,
                              postalCode: report.zip,
                            },
                            "with-comma",
                          ),
                        )}
                        noWrap
                      />
                    </Layout>
                  }
                  lineItemHeaderNoWrap
                  lineItemHeaderCenter
                  noPadding
                />
                <Text size="xs">
                  {generatePropertyReportHighlights({
                    propertyDetails: {
                      bedrooms: report.bedrooms,
                      totalBaths: report.totalBaths,
                      buildingSquareFeet: report.sqft,
                      acres: report.lotAcres,
                    },
                  })}
                </Text>
              </Layout>
              <Text size="xxs" tag="i" textAlign="center">
                {generateList(
                  [
                    {
                      value: report.createdDate
                        ? parseDate(report.createdDate, "MM/DD/YYYY")
                        : null,
                      label: "set on ",
                    },
                  ],
                  " | ",
                )}
              </Text>
            </Layout>
          </Padding>
        </MonitoringContainer>
      );
    }
  };

  return (
    <>
      <Padding left="md" right="md">
        <Container
          containerTitle="Property Report Monitoring"
          containerId="dashboard__monitoring"
        >
          {monitoringArray && monitoringArray.length > 1 ? (
            <Padding space="md">
              <Padding
                bottom="md"
                breakpointStyles={{ mobile: { bottom: "sm" } }}
              >
                <Text size="xs" textAlign="left">
                  This is an overview of your monitored Property Reports, to
                  manage all of your monitored reports{" "}
                  <AnchorLink
                    href="/#dashboard__monitoring__container"
                    target="_parent"
                    inline
                    hoverUnderline
                    color="primary"
                    size="xs"
                    role="link"
                  >
                    click here
                  </AnchorLink>
                  .
                </Text>
              </Padding>
              <Layout
                type="flex"
                flexProps={{ direction: "column", gap: "md" }}
                breakpointStyles={{
                  desktop: {
                    type: "grid",
                    gridProps: { templateColumns: "2-column", gap: "md" },
                  },
                }}
              >
                {monitoringStatus === "loading" ? (
                  Array(2)
                    .fill(null)
                    .map((_, index) => (
                      <MonitoringContainer key={index}>
                        <ContainerLineItem
                          key={index}
                          lineItemHeader={
                            <LoadingPlaceholder
                              width={"10rem"}
                              height={"20px"}
                            />
                          }
                          lineItemDetails={
                            <Layout
                              type="flex"
                              flexProps={{
                                gap: "xxs",
                                direction: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <LoadingPlaceholder
                                width={"55px"}
                                height={"13px"}
                              />
                              <LoadingPlaceholder
                                width={"50px"}
                                height={"13px"}
                              />
                            </Layout>
                          }
                          lineItemSubHeader={
                            <LoadingPlaceholder width={"12rem"} />
                          }
                          lineItemHeaderCenter
                        />
                      </MonitoringContainer>
                    ))
                ) : monitoringStatus === "failed" ? (
                  <Text>Failed to load monitoring</Text>
                ) : monitoringArray ? (
                  monitoringArray.map((_: any, index: number) => {
                    return renderMonitoring(monitoringArray[index], index + 1);
                  })
                ) : (
                  <Text>You are not currently monitoring anyone.</Text>
                )}
              </Layout>
            </Padding>
          ) : (
            <Padding space="md">
              <Text size="xs" textAlign="left">
                Get notified when Report information changes by setting up
                monitoring. Search above and start monitoring.
              </Text>
            </Padding>
          )}
        </Container>
      </Padding>
    </>
  );
};

export default PropertyReportMonitoring;
