import React from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectAutoReport,
  selectAutoReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  Container,
  ImageCarousel,
  LoadingPlaceholder,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";

import { AutoReportType } from "../../../features/reports/reportTypes";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";

const VehicleImages: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  let pulledReport = useAppSelector(selectAutoReport) as AutoReportType;
  const autoReportStatus = useAppSelector(selectAutoReportStatus);
  const currentBreakpoint = useCurrentBreakpoint();

  const loading = autoReportStatus === "loading";

  return (
    <>
      {pulledReport && pulledReport.images ? (
        <Padding
          space="none"
          breakpointStyles={{
            mobile: {
              left: "md",
              right: "md",
            },
          }}
        >
          <Container
            containerId={containerId}
            containerTitle={`Vehicle Images ${
              loading
                ? ""
                : `(${
                    pulledReport && pulledReport.images
                      ? pulledReport.images.length
                      : 0
                  })`
            }`}
          >
            {loading ? (
              <LoadingPlaceholder height={"248px"} width={"100%"} />
            ) : (
              <ImageCarousel
                height={currentBreakpoint === "desktop" ? 500 : 248}
                images={
                  pulledReport && pulledReport.images
                    ? pulledReport.images.map(image => {
                        return image.link;
                      })
                    : []
                }
              />
            )}
            <Padding space="md" top="xxs" bottom="xxs">
              <Text textAlign="center" size="xxs">
                <Text textAlign="center" size="xxs" tag="i">
                  These images can be examples only and not images of the exact
                  vehicle. Logos shown are registered trademarks of their
                  respective owners.
                </Text>
              </Text>
            </Padding>
          </Container>
        </Padding>
      ) : null}
    </>
  );
};

export default VehicleImages;
