import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import {
  generateList,
  parseDate,
  renderArrayOfObjectsPrep,
} from "../../../utils";

// types
import { PropertyEviction } from "../../../features/reports/reportTypes";

// components
import {
  Container,
  Padding,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  ReportLink,
  Text,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

// pull report hook
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

const Evictions: React.FC<{ containerId: string; printReady?: boolean }> = ({
  containerId,
  printReady,
}) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );

  const loading = propertyReportStatus === "loading";
  const pullPeopleReport = usePeopleReportDispatch();

  /* generate evictions Object */
  const evictionObjects = renderArrayOfObjectsPrep(
    pulledReport?.evictions || [],
    [],
    [
      {
        key: ["nameDetails"],
        keyFormat: () => {
          return "Name";
        },
        valueFormat: (value, currentObject: PropertyEviction) => {
          const jsxElements =
            currentObject.nameDetails?.reportToken &&
            currentObject.nameDetails.fullName ? (
              <ReportLink<PullPeopleReportParams>
                inputValue={currentObject.nameDetails.reportToken}
                handleClick={pullPeopleReport}
                text={currentObject.nameDetails.fullName}
                smallText
              />
            ) : (
              <Text passThroughClassess="sc-table-row__detail">
                {currentObject.nameDetails?.fullName}
              </Text>
            );

          return <>{jsxElements}</>;
        },
      },
    ],
  );
  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Evictions at Address ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.evictions
                  ? pulledReport.evictions.length
                  : 0
              })`
        }`}
        containerId={containerId}
        tooltipContent="Evictions are legal processes used by landlords to remove tenants from rental properties due to various reasons, such as unpaid rent or lease violations, often resulting in court proceedings and potential consequences for tenants' housing history."
        newInfo={reportDifferences?.evictions}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
            children
          />
        ) : pulledReport?.evictions && pulledReport.evictions[0] ? (
          <CollapsableItemList numberToShow={5} isOpen={printReady}>
            {pulledReport.evictions.map((eviction, index) => (
              <ContainerLineItem
                lineItemHeader={`Eviction - ${
                  eviction.filingType ? eviction.filingType : null
                }`}
                lineItemSubHeader={generateList(
                  [
                    {
                      value: eviction.filingDate
                        ? parseDate(eviction.filingDate, "year")
                        : null,
                      label: "",
                    },
                    {
                      value:
                        eviction.unlawfulDetainer === "Y"
                          ? "Unlawful Detainer"
                          : "Not an Unlawful Detainer",
                      label: "",
                    },
                  ],
                  " | ",
                )}
                isOpen={printReady ? true : false}
                key={index}
              >
                {evictionObjects[index] ? (
                  <Padding space="md">
                    <Table
                      orderOfProperties={[]}
                      tableDataObject={evictionObjects[index]}
                    />
                  </Padding>
                ) : null}
              </ContainerLineItem>
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No Evictions found at this address
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default Evictions;
