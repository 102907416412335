import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  getReportDifference,
  getReportHistory,
  pullPeopleReport,
  pullPropertyReport,
  searchPeople,
  socialSearch,
  pullPhoneReport,
  getRentData,
  submitComplaint,
  pullAutoReport,
  pullNeighborhoodReportByLocation,
  pullNeighborhoodSearch,
  propertyReportDifference,
  getNeighborhoodPOIs,
  getAllLists,
  getSpecificList,
  addReportToList,
  removeReportFromList,
  renameList,
  deleteList,
} from "../reports/reportsAPI";
import {
  PeopleReportHistory,
  PhoneReportHistory,
  PropertyReportHistory,
  SocialReportHistory,
  PullPeopleReportParams,
  PullPropertyReportParams,
  PeopleReport,
  PropertyReport,
  SearchPeopleReportParams,
  TeaserReport,
  SocialReportType,
  SocialSearch,
  GetReportDifferenceParams,
  MonitoringDifference,
  PhoneReport,
  PullPhoneReportParams,
  GetRentDataParams,
  RentData,
  SubmitComplaintParams,
  AutoReportType,
  AutoReportHistory,
  PullAutoReportParams,
  PropertyName,
  PropertyReportDifferenceParams,
  PropertyReportDifference,
  GetPropertyPOIsParams,
  ReportList,
  GetSpecificListParams,
  RemoveReportFromListParams,
  RenameListParams,
  DeleteListParams,
  CombinedAddReportToList,
} from "./reportTypes/";

import { logoutAsync } from "../authentication/authenticationSlice";
import {
  formatName,
  renderSocialReportType,
  scoreGenerator,
  setValuationSummary,
} from "../../utils";
import {
  NeighborhoodReport,
  NeighborhoodReportHistory,
  PullNeighborhoodReportParams,
  PullNeighborhoodSearchParams,
  NeighborhoodMapModalContent,
} from "./reportTypes/neighborhoodReportTypes";

export type CombinedReports =
  | PeopleReport
  | PhoneReport
  | PropertyReport
  | SocialReportType
  | NeighborhoodReport;

export interface ReportsState {
  status: "idle" | "loading" | "failed"; // default status should personalize each
  reportHistoryStatus: "idle" | "loading" | "failed";
  peopleReportStatus: "idle" | "loading" | "failed";
  peopleSearchStatus: "idle" | "loading" | "failed";
  propertyReportStatus: "idle" | "loading" | "failed";
  aiStatus: "idle" | "loading" | "failed";
  socialSearchStatus: "idle" | "loading" | "failed";
  phoneReportStatus: "idle" | "loading" | "failed";
  autoReportStatus: "idle" | "loading" | "failed";
  neighborhoodReportStatus: "idle" | "loading" | "failed";
  neighborhoodSearchStatus: "idle" | "loading" | "failed";
  rentDataStatus: "idle" | "loading" | "failed";
  submitComplaintStatus: "idle" | "loading" | "failed";
  listStatus: "idle" | "loading" | "failed";
  allReportHistory?: Array<
    | PeopleReportHistory
    | PhoneReportHistory
    | PropertyReportHistory
    | SocialReportHistory
    | AutoReportHistory
    | NeighborhoodReportHistory
  >;
  peopleReportHistory?: PeopleReportHistory[];
  phoneReportHistory?: PhoneReportHistory[];
  propertyReportHistory?: PropertyReportHistory[];
  socialReportHistory?: SocialReportHistory[];
  autoReportHistory?: AutoReportHistory[];
  neighborhoodReportHistory?: NeighborhoodReportHistory[];
  mostRecentReport?:
    | PeopleReportHistory
    | PhoneReportHistory
    | PropertyReportHistory
    | SocialReportHistory
    | AutoReportHistory
    | NeighborhoodReportHistory
    | null;
  peopleReport?: PeopleReport;
  peopleReportMonitoringDifferences?: MonitoringDifference;
  propertyReport?: PropertyReport;
  propertyReportTrackingFlags: {
    neighborhoodAiUsed: boolean;
    neighborhoodAiVoted: boolean;
  };
  propertyReportMonitoringDifferences?: PropertyReportDifference;
  socialReport?: SocialReportType;
  phoneReport?: PhoneReport;
  autoReport?: AutoReportType;
  neighborhoodReport?: NeighborhoodReport;
  neighborhoodSearch?: any;
  isReportHistoryEmpty: boolean;
  peopleTeaserReports?: TeaserReport[];
  rentData?: RentData;
  peopleReportMonitoringModal?: boolean;
  neighborhoodMapModal: boolean;
  siteJabberShown: boolean;
  neighborhoodMapModalContent?: NeighborhoodMapModalContent;
  reportLists?: ReportList[];
}

const initialState: ReportsState = {
  status: "idle",
  reportHistoryStatus: "idle",
  peopleReportStatus: "idle",
  peopleSearchStatus: "idle",
  propertyReportStatus: "idle",
  aiStatus: "idle",
  socialSearchStatus: "idle",
  phoneReportStatus: "idle",
  autoReportStatus: "idle",
  neighborhoodReportStatus: "idle",
  neighborhoodSearchStatus: "idle",
  rentDataStatus: "idle",
  listStatus: "idle",
  submitComplaintStatus: "idle",
  isReportHistoryEmpty: true,
  neighborhoodMapModal: false,
  siteJabberShown: false,
  propertyReportTrackingFlags: {
    neighborhoodAiUsed: false,
    neighborhoodAiVoted: false,
  },
};

export const getReportHistoryAsync = createAsyncThunk<any, void, {}>(
  "report/getReportHistory",
  async () => {
    return getReportHistory();
  },
);

/* People Report */
export const searchPeopleAsync = createAsyncThunk<
  any,
  SearchPeopleReportParams,
  {}
>("report/searchPeople", async params => {
  return searchPeople(params);
});

export const pullPeopleReportAsync = createAsyncThunk<
  any,
  PullPeopleReportParams,
  {}
>("report/pullPeopleReport", async params => {
  return pullPeopleReport(params);
});

export const getReportDifferenceAsync = createAsyncThunk<
  any,
  GetReportDifferenceParams,
  {}
>("report/getReportDifference", async params => {
  return getReportDifference(params);
});

/* Property Report */
export const pullPropertyReportAsync = createAsyncThunk<
  any,
  PullPropertyReportParams,
  {}
>("report/pullPropertyReport", async params => {
  return pullPropertyReport(params);
});

export const propertyReportDifferenceAsync = createAsyncThunk<
  any,
  PropertyReportDifferenceParams,
  {}
>("monitoring/propertyReportDifference", async address => {
  return propertyReportDifference(address);
});

export const getNeighborhoodPOIsAsync = createAsyncThunk<
  any,
  GetPropertyPOIsParams,
  {}
>("report/getNeighborhoodPOIs", async address => {
  return getNeighborhoodPOIs(address);
});

export const getRentDataAsync = createAsyncThunk<any, GetRentDataParams, {}>(
  "report/getRentData",
  async params => {
    return getRentData(params);
  },
);

/* Social Report */
export const searchSocialAsync = createAsyncThunk<any, SocialSearch, {}>(
  "report/searchSocial",
  async params => {
    return socialSearch(params);
  },
);

/* Phone Report */
export const pullPhoneReportAsync = createAsyncThunk<
  any,
  PullPhoneReportParams,
  {}
>("report/pullPhoneReport", async params => {
  return pullPhoneReport(params);
});

export const submitComplaintAsync = createAsyncThunk<
  any,
  SubmitComplaintParams,
  {}
>("report/submitComplaint", async params => {
  return submitComplaint(params);
});

/* Auto Report */
export const pullAutoReportAsync = createAsyncThunk<
  any,
  PullAutoReportParams,
  {}
>("report/pullAutoReport", async params => {
  return pullAutoReport(params);
});

/* Neighborhood Report */
export const pullNeighborhoodReportByLocationAsync = createAsyncThunk<
  any,
  PullNeighborhoodReportParams,
  {}
>("report/pullNeighborhoodReportByLocation", async params => {
  return pullNeighborhoodReportByLocation(params);
});

export const pullNeighborhoodSearchAsync = createAsyncThunk<
  any,
  PullNeighborhoodSearchParams,
  {}
>("report/pullNeighborhoodSearch", async params => {
  return pullNeighborhoodSearch(params);
});

/* List Actions */
export const getAllListsAsync = createAsyncThunk<any, void, {}>(
  "report/getAllLists",
  async () => {
    return getAllLists();
  },
);

export const getSpecificListAsync = createAsyncThunk<
  any,
  GetSpecificListParams,
  {}
>("report/getSpecificList", async params => {
  return getSpecificList(params);
});

export const addReportToListAsync = createAsyncThunk<
  any,
  CombinedAddReportToList,
  {}
>("report/addReportToList", async params => {
  return addReportToList(params);
});

export const removeReportFromListAsync = createAsyncThunk<
  any,
  RemoveReportFromListParams,
  {}
>("report/removeReportFromList", async params => {
  return removeReportFromList(params);
});

export const renameListAsync = createAsyncThunk<any, RenameListParams, {}>(
  "report/renameList",
  async params => {
    return renameList(params);
  },
);

export const deleteListAsync = createAsyncThunk<any, DeleteListParams, {}>(
  "report/deleteList",
  async params => {
    return deleteList(params);
  },
);

/* Slice */
export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setMostRecentReport: (state, action) => {
      if (state.isReportHistoryEmpty) {
        state.isReportHistoryEmpty = false;
      }
      state.mostRecentReport = action.payload;
    },
    setPeopleReportMonitoringModal: (state, action) => {
      state.peopleReportMonitoringModal = action.payload;
    },
    setNeighborhoodMapModal: (state, action) => {
      state.neighborhoodMapModal = action.payload;
    },
    setNeighborhoodMapModalContent: (state, action) => {
      let offender = action.payload.offender;
      let index = action.payload.index;
      state.neighborhoodMapModalContent = {
        offender,
        index,
      };
    },
    setSiteJabberShown: state => {
      state.siteJabberShown = true;
    },
    updateNeighborhoodAiUsed: state => {
      state.propertyReportTrackingFlags.neighborhoodAiUsed = true;
    },
    updateNeighborhoodAiVoted: state => {
      state.propertyReportTrackingFlags.neighborhoodAiVoted = true;
    },
  },
  extraReducers: builder => {
    builder
      /* getReportHistoryAsync */
      .addCase(getReportHistoryAsync.pending, state => {
        state.reportHistoryStatus = "loading";
      })
      .addCase(getReportHistoryAsync.fulfilled, (state, action) => {
        state.reportHistoryStatus = "idle";
        state.mostRecentReport = null;

        state.allReportHistory = action.payload.allResults;

        if (action.payload.allResults && action.payload.allResults.length > 0) {
          state.isReportHistoryEmpty = false;
          // set the most recent report to the first element in the array (sorted in decending order)
          state.mostRecentReport = action.payload.allResults[0];
        } else {
          state.isReportHistoryEmpty = true;
        }

        state.peopleReportHistory = action.payload.peopleReportHistory;
        state.phoneReportHistory = action.payload.phoneReportHistory;
        state.propertyReportHistory = action.payload.propertyReportHistory;
        state.socialReportHistory = action.payload.socialReportHistory;
        state.autoReportHistory = action.payload.autoReportHistory;
        state.neighborhoodReportHistory = action.payload.neighborhoodHistory;
      })
      .addCase(getReportHistoryAsync.rejected, state => {
        state.reportHistoryStatus = "failed";
      })
      /* handle logout */
      .addCase(logoutAsync.pending, () => {})
      .addCase(logoutAsync.fulfilled, state => {
        state.allReportHistory = undefined;
        state.peopleReportHistory = undefined;
        state.phoneReportHistory = undefined;
        state.propertyReportHistory = undefined;
        state.socialReportHistory = undefined;
        state.mostRecentReport = null;
        state.isReportHistoryEmpty = true;
        state.propertyReportStatus = "idle";
        state.aiStatus = "idle";
        state.peopleReportStatus = "idle";
        state.phoneReportStatus = "idle";
        state.socialSearchStatus = "idle";
        state.peopleSearchStatus = "idle";
        state.autoReportStatus = "idle";
        state.neighborhoodReportStatus = "idle";
        state.rentDataStatus = "idle";
        state.submitComplaintStatus = "idle";
        state.listStatus = "idle";
        state.peopleReport = undefined;
        state.propertyReport = undefined;
        state.phoneReport = undefined;
        state.socialReport = undefined;
        state.rentData = undefined;
        state.autoReport = undefined;
        state.neighborhoodReport = undefined;
        state.neighborhoodSearch = undefined;
        state.peopleReportMonitoringDifferences = undefined;
        state.peopleTeaserReports = undefined;
        state.siteJabberShown = false;
        state.propertyReportTrackingFlags = {
          neighborhoodAiUsed: false,
          neighborhoodAiVoted: false,
        };
      })
      .addCase(logoutAsync.rejected, state => {
        state.reportHistoryStatus = "failed";
      })
      /* searchPeopleAsync */
      .addCase(searchPeopleAsync.pending, state => {
        state.peopleSearchStatus = "loading";
      })
      .addCase(searchPeopleAsync.fulfilled, (state, action) => {
        state.peopleSearchStatus = "idle";
        state.peopleTeaserReports = action.payload.searchResponse;
      })
      .addCase(searchPeopleAsync.rejected, state => {
        state.peopleSearchStatus = "failed";
      })
      /* pullPeopleReportAsync */
      .addCase(pullPeopleReportAsync.pending, state => {
        state.peopleReportStatus = "loading";
      })
      .addCase(pullPeopleReportAsync.fulfilled, (state, action) => {
        state.peopleReport = undefined;
        state.peopleReportMonitoringDifferences = undefined;
        state.peopleReportStatus = "idle";
        if (action.payload.report.result) {
          let report = action.payload.report.result as PeopleReport;

          // set report to peopleReport
          state.peopleReport = report;

          // add report to the peopleReportHistory & allHistory
          let newHistoryReport: PeopleReportHistory = {
            age: report.age,
            city: "",
            dateOfBirth: report.dateOfBirth,
            dateOfDeath: report.dateOfDeath,
            deceased: report.dateOfDeath ? "Yes" : "No",
            firstName: report.firstName,
            fullAddress: "",
            gender: report.gender,
            lastName: report.lastName,
            middleName: report.middleName,
            monitoring: false,
            origin: "Membersite",
            reportToken: report.reportToken!,
            state: "",
            version: report.reportSummary.version!,
            zip: "",
            score: scoreGenerator(),
            type: "people",
            schema: "v2",
          };
          if (report.addresses.length > 0) {
            newHistoryReport.city = report.addresses[0].city;
            newHistoryReport.fullAddress = report.addresses[0].fullAddress;
            newHistoryReport.state = report.addresses[0].state;
            newHistoryReport.zip = report.addresses[0].postalCode;
          }

          // set most recent report to the report
          state.mostRecentReport = newHistoryReport;

          state.peopleReportHistory?.unshift(newHistoryReport);

          state.allReportHistory?.unshift(newHistoryReport);
        }
      })
      .addCase(pullPeopleReportAsync.rejected, state => {
        state.peopleReport = undefined;
        state.peopleReportStatus = "failed";
      })
      /* searchSocialAsync */
      .addCase(searchSocialAsync.pending, state => {
        state.socialSearchStatus = "loading";
      })
      .addCase(searchSocialAsync.fulfilled, (state, action) => {
        state.socialReport = undefined;
        state.socialSearchStatus = "idle";
        if (!action.payload.noResults) {
          state.socialReport = action.payload.socialProfileSearchResponse
            .hasError
            ? undefined
            : action.payload.socialProfileSearchResponse;

          if (
            action.payload.socialProfileSearchResponse &&
            action.payload.socialProfileSearchResponse.socialReportSummary &&
            action.payload.socialProfileSearchResponse.socialReportSummary
              .numberOfResults > 0
          ) {
            let newHistoryReport: SocialReportHistory = {
              score: scoreGenerator(),
              type: "social",
              twitter:
                action.payload.socialProfileSearchResponse.socialReportSummary
                  .twitter,
              email:
                action.payload.socialProfileSearchResponse.socialReportSummary
                  .email,
              fullName: action.payload.socialProfileSearchResponse.fullName,
              gender: action.payload.socialProfileSearchResponse.gender,
              phone:
                action.payload.socialProfileSearchResponse.socialReportSummary
                  .phone,
              location: action.payload.socialProfileSearchResponse.location,
              schema: "v2",
            };

            state.mostRecentReport = newHistoryReport;
            state.socialReportHistory?.unshift(newHistoryReport);
            state.allReportHistory?.unshift(newHistoryReport);
          }
        }
      })
      .addCase(searchSocialAsync.rejected, state => {
        state.socialReport = undefined;
        state.socialSearchStatus = "failed";
      })
      /* getReportDifferenceAsync */
      .addCase(getReportDifferenceAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getReportDifferenceAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.success && action.payload.reportDifference) {
          const reportDifference = action.payload
            .reportDifference as MonitoringDifference;
          state.peopleReportMonitoringDifferences = {
            ...reportDifference,
            contactTitle:
              reportDifference.addresses ||
              reportDifference.emailAddresses ||
              reportDifference.phoneNumbers
                ? true
                : false,
            peopleTitle:
              reportDifference.relatives ||
              reportDifference.associates ||
              reportDifference.neighbors
                ? true
                : false,
            criminalTitle: reportDifference.criminalRecords ? true : false,
            financialTitle:
              reportDifference.bankruptcies ||
              reportDifference.judgments ||
              reportDifference.liens ||
              reportDifference.propertyForeclosures ||
              reportDifference.evictions ||
              reportDifference.uccFilings
                ? true
                : false,
            careerTitle:
              reportDifference.employers ||
              reportDifference.corporateAffiliations ||
              reportDifference.businessAffiliations ||
              reportDifference.professionalLicenses
                ? true
                : false,
            lifestyleTitle:
              reportDifference.voterRegistrations ||
              reportDifference.huntingPermits ||
              reportDifference.driversLicenses ||
              reportDifference.pilotLicenses ||
              reportDifference.vehicles ||
              reportDifference.concealedWeaponPermits
                ? true
                : false,
            anyReportDifference: Object.values(reportDifference).some(
              value => !!value,
            ),
          };
        }
      })
      .addCase(getReportDifferenceAsync.rejected, state => {
        state.status = "failed";
      })
      /* pullPhoneReportAsync */
      .addCase(pullPhoneReportAsync.pending, (state, action) => {
        state.phoneReportStatus = "loading";
      })
      .addCase(pullPhoneReportAsync.rejected, state => {
        state.phoneReport = undefined;
        state.phoneReportStatus = "failed";
      })
      .addCase(pullPhoneReportAsync.fulfilled, (state, action) => {
        state.phoneReport = undefined;
        state.phoneReportStatus = "idle";
        let report = action.payload.report as PhoneReport;

        // set report to phoneReport state
        state.phoneReport = report;
        // need to add state setting for most recent report
        let newHistoryReport: PhoneReportHistory = {
          carrier: report.phoneReportSummary.carrier,
          phone: report.searchCriteria.phone,
          phoneCity: report.phoneReportSummary.phoneCity,
          phoneState: report.phoneReportSummary.phoneState,
          phoneType: report.phoneReportSummary.phoneType,
          score: scoreGenerator(),
          type: "phone",
          schema: "v2",
        };

        //if people report options
        newHistoryReport.personCity = report.results.people[0]?.address[0]?.city
          ? report.results.people[0]?.address[0]?.city
          : undefined;

        newHistoryReport.personFullAddress = report.phoneReportSummary
          .personAddress
          ? report.phoneReportSummary.personAddress
          : undefined;

        newHistoryReport.personState = report.results.people[0]?.address[0]
          .state
          ? report.results.people[0]?.address[0].state
          : undefined;
        newHistoryReport.personZip = report.results.people[0]?.address[0]
          ?.postalCode
          ? report.results.people[0]?.address[0]?.postalCode
          : undefined;

        // if business report options
        newHistoryReport.businessCity = report.results.businesses[0]?.address
          ?.city
          ? report.results.businesses[0]?.address?.city
          : undefined;

        newHistoryReport.businessState = report.results.businesses[0]?.address
          ?.state
          ? report.results.businesses[0]?.address?.state
          : undefined;

        newHistoryReport.businessFullAddress = report.results.businesses[0]
          ?.address?.fullAddress
          ? report.results.businesses[0]?.address?.fullAddress
          : undefined;

        newHistoryReport.businessZip = report.results.businesses[0]?.address
          ?.zip4
          ? report.results.businesses[0]?.address?.zip4
          : undefined;

        state.mostRecentReport = newHistoryReport;

        state.phoneReportHistory?.unshift(newHistoryReport);
        state.allReportHistory?.unshift(newHistoryReport);
      })
      /* pullPropertyReportAsync */
      .addCase(pullPropertyReportAsync.pending, (state, action) => {
        state.propertyReportStatus = "loading";
      })
      .addCase(pullPropertyReportAsync.rejected, state => {
        state.propertyReport = undefined;
        state.propertyReportTrackingFlags = {
          neighborhoodAiUsed: false,
          neighborhoodAiVoted: false,
        };
        state.propertyReportStatus = "failed";
      })
      .addCase(pullPropertyReportAsync.fulfilled, (state, action) => {
        state.propertyReport = undefined;
        state.propertyReportTrackingFlags = {
          neighborhoodAiUsed: false,
          neighborhoodAiVoted: false,
        };
        state.propertyReportMonitoringDifferences = undefined;
        state.propertyReportStatus = "idle";
        if (!action.payload.noResults) {
          let report = action.payload.report.results
            .scalableReport as PropertyReport;

          // set report to propertyReport state
          state.propertyReport = report;

          //checking for opt outs and null results
          if (report) {
            // need to add state setting for most recent report
            let newHistoryReport: PropertyReportHistory = {
              acres: report.propertyDetails?.acres,
              addressLine1: report.propertyDetails?.fullAddress,
              propertyDetails: report.propertyDetails,
              bedrooms: report.propertyDetails?.bedrooms,
              buildingSquareFeet: report.propertyDetails?.buildingSquareFeet,
              city: report.propertyDetails?.city,
              latitude: report.propertyDetails?.latitude,
              longitude: report.propertyDetails?.longitude,
              state: report.propertyDetails?.state,
              totalBaths: report.propertyDetails?.totalBaths,
              zip: report.propertyDetails?.postalCode,
              score: scoreGenerator(),
              type: "property",
              schema: "v2",
              currentOwners: {
                owners: report.propertyCurrentOwnership?.names?.map(
                  (name: PropertyName) => {
                    return {
                      fullName: name.firstName
                        ? formatName(name)
                        : name.businessName
                          ? name.businessName
                          : "",
                      reportToken: name.reportToken,
                    };
                  },
                ),
                saleDate: report.propertyCurrentOwnership?.saleDate,
              },
              valuationSummary: setValuationSummary(report),
            };

            // set most recent report to the report
            state.mostRecentReport = newHistoryReport;

            state.propertyReportHistory?.unshift(newHistoryReport);
            state.allReportHistory?.unshift(newHistoryReport);
          }
        }
      })
      /* propertyReportDifferenceAsync */
      .addCase(propertyReportDifferenceAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(propertyReportDifferenceAsync.rejected, state => {
        state.propertyReportMonitoringDifferences = undefined;
        state.status = "failed";
      })
      .addCase(propertyReportDifferenceAsync.fulfilled, (state, action) => {
        state.propertyReportMonitoringDifferences = action.payload;
        state.status = "idle";
      })
      /* getNeighborhoodPOIsAsync */
      .addCase(getNeighborhoodPOIsAsync.pending, state => {
        state.aiStatus = "loading";
      })
      .addCase(getNeighborhoodPOIsAsync.rejected, state => {
        state.aiStatus = "failed";
      })
      .addCase(getNeighborhoodPOIsAsync.fulfilled, (state, action) => {
        state.aiStatus = "idle";
        state.propertyReport = {
          ...state.propertyReport,
          neighborhoodAi: action.payload,
        };
      })
      /* getRentDataAsync */
      .addCase(getRentDataAsync.pending, (state, action) => {
        state.rentDataStatus = "loading";
      })
      .addCase(getRentDataAsync.rejected, state => {
        state.rentDataStatus = "failed";
      })
      .addCase(getRentDataAsync.fulfilled, (state, action) => {
        state.rentDataStatus = "idle";

        let report = action.payload.rentDataResponse.results
          .scalableReport as RentData;

        state.rentData = report;
      })
      /* pullAutoReportAsync */
      .addCase(pullAutoReportAsync.pending, state => {
        state.autoReportStatus = "loading";
      })
      .addCase(pullAutoReportAsync.fulfilled, (state, action) => {
        state.autoReport = undefined;
        state.autoReportStatus = "idle";

        let autoReport = action.payload.report as AutoReportType;

        state.autoReport = autoReport;

        if (autoReport) {
          let newHistoryReport: AutoReportHistory = {
            score: scoreGenerator(),
            type: "auto",
            schema: "v2",
            vin: autoReport.vehicleSummary.vin,
            vehicleDescription: autoReport.vehicleSummary.vehicleDescription,
            mspr: autoReport.vehicleSummary.msrp,
            titleCount: autoReport.vehicleSummary.titleCount,
          };

          state.mostRecentReport = newHistoryReport;
          state.autoReportHistory?.unshift(newHistoryReport);
          state.allReportHistory?.unshift(newHistoryReport);
        }
      })
      .addCase(pullAutoReportAsync.rejected, state => {
        state.autoReport = undefined;
        state.autoReportStatus = "failed";
      })
      /* pullNeighrborhoodReportByLocationAsync */
      .addCase(pullNeighborhoodReportByLocationAsync.pending, state => {
        state.neighborhoodReportStatus = "loading";
      })
      .addCase(
        pullNeighborhoodReportByLocationAsync.fulfilled,
        (state, action) => {
          state.neighborhoodReport = undefined;
          state.neighborhoodReportStatus = "idle";
          if (!action.payload.noResults) {
            let report = action.payload as NeighborhoodReport;
            state.neighborhoodReport = action.payload;

            // set report to propertyReport state

            //checking for opt outs and null results
            if (report) {
              let newHistoryReport: NeighborhoodReportHistory = {
                addressLine1: report.addressObject.address,
                city: report.addressObject.city,
                distance: 5,
                state: report.addressObject.state,
                zip: report.addressObject.zipCode,
                score: scoreGenerator(),
                type: "neighborhood",
                schema: "v2",
                offendersCount: report.offendersCount,
              };
              state.mostRecentReport = newHistoryReport;
              state.neighborhoodReportHistory?.unshift(newHistoryReport);
              state.allReportHistory?.unshift(newHistoryReport);
            }
          }
        },
      )
      .addCase(pullNeighborhoodReportByLocationAsync.rejected, state => {
        state.neighborhoodReport = undefined;
        state.neighborhoodReportStatus = "failed";
      })
      .addCase(pullNeighborhoodSearchAsync.pending, state => {
        state.neighborhoodSearchStatus = "loading";
      })
      .addCase(pullNeighborhoodSearchAsync.fulfilled, (state, action) => {
        state.neighborhoodSearch = undefined;
        state.neighborhoodSearchStatus = "idle";
        state.neighborhoodSearch = action.payload;
      })
      .addCase(pullNeighborhoodSearchAsync.rejected, state => {
        state.neighborhoodSearch = undefined;
        state.neighborhoodSearchStatus = "failed";
      })
      .addCase(submitComplaintAsync.pending, (state, action) => {
        state.submitComplaintStatus = "loading";
      })
      .addCase(submitComplaintAsync.rejected, state => {
        state.submitComplaintStatus = "failed";
      })
      .addCase(submitComplaintAsync.fulfilled, (state, action) => {
        state.submitComplaintStatus = "idle";
        if (state.phoneReport) {
          const updatedComplaints = [
            action.payload.submitPhoneComplaint.result[0],
            ...state.phoneReport.phoneComplaints.result,
          ];
          const updatedSummary = {
            ...state.phoneReport.phoneComplaints.summary,
            rplComplaintCounts:
              state.phoneReport.phoneComplaints.summary.rplComplaintCounts + 1,
          };
          state.phoneReport = {
            ...state.phoneReport,
            phoneComplaints: {
              ...state.phoneReport.phoneComplaints,
              result: updatedComplaints,
              summary: updatedSummary,
            },
          };
        }
      })
      /* getAllListsAsync */
      .addCase(getAllListsAsync.pending, state => {
        state.listStatus = "loading";
      })
      .addCase(getAllListsAsync.rejected, state => {
        state.listStatus = "failed";
      })
      .addCase(getAllListsAsync.fulfilled, (state, action) => {
        state.listStatus = "idle";
        state.reportLists = action.payload.result;
      })
      /* getSpecificListAsync */
      .addCase(getSpecificListAsync.pending, state => {
        state.listStatus = "loading";
      })
      .addCase(getSpecificListAsync.rejected, state => {
        state.listStatus = "failed";
      })
      .addCase(getSpecificListAsync.fulfilled, (state, action) => {
        state.listStatus = "idle";
        let foundFlag = false;
        let arrayOfLists = state.reportLists;

        const list = action.payload.result ? action.payload.result[0] : null;

        // if the list is found in currently saved list, update the list
        if (list) {
          arrayOfLists = state.reportLists?.map(reportList => {
            if (reportList.savedReportsListId === list.savedReportsListId) {
              foundFlag = true;
              return list;
            } else {
              return reportList;
            }
          });

          // if the list is not found in currently saved list, add it to the listArray
          if (!foundFlag) {
            arrayOfLists?.push(list);
          }

          state.reportLists = arrayOfLists;
        }
      })
      /* addReportToListAsync */
      .addCase(addReportToListAsync.pending, state => {
        state.listStatus = "loading";
      })
      .addCase(addReportToListAsync.rejected, state => {
        state.listStatus = "failed";
      })
      .addCase(addReportToListAsync.fulfilled, (state, action) => {
        // adding to state handled by getSpecificListAsync due to too many additional properties to manage / create
        state.listStatus = "idle";
      })
      /* removeReportFromListAsync */
      .addCase(removeReportFromListAsync.pending, state => {
        state.listStatus = "loading";
      })
      .addCase(removeReportFromListAsync.rejected, state => {
        state.listStatus = "failed";
      })
      .addCase(removeReportFromListAsync.fulfilled, (state, action) => {
        state.listStatus = "idle";
        state.reportLists = state.reportLists
          ?.map(reportList => {
            const filteredReports = reportList.savedReports.filter(
              report => report.id !== action.payload.savedReportId,
            );

            if (filteredReports.length === 0) {
              return null;
            } else {
              return {
                ...reportList,
                savedReports: filteredReports,
              };
            }
          })
          // need to filter out nulls to allow type to work
          ?.filter((reportList): reportList is ReportList => !!reportList);
      })
      /* renameListAsync */
      .addCase(renameListAsync.pending, state => {
        state.listStatus = "loading";
      })
      .addCase(renameListAsync.rejected, state => {
        state.listStatus = "failed";
      })
      .addCase(renameListAsync.fulfilled, (state, action) => {
        state.listStatus = "idle";
        // map over lists and upate only the list name of the list that was renamed
        state.reportLists = state.reportLists?.map(reportList => {
          if (
            reportList.savedReportsListId === action.payload.savedReportsListId
          ) {
            return { ...reportList, listName: action.payload.listName };
          } else {
            return reportList;
          }
        });
      })
      /* deleteListAsync */
      .addCase(deleteListAsync.pending, state => {
        state.listStatus = "loading";
      })
      .addCase(deleteListAsync.rejected, state => {
        state.listStatus = "failed";
      })
      .addCase(deleteListAsync.fulfilled, (state, action) => {
        state.listStatus = "idle";
        // filter out the list that was deleted
        state.reportLists = state.reportLists?.filter(
          reportList =>
            reportList.savedReportsListId !== action.payload.savedReportsListId,
        );
      });
  },
});

/* actions */
export const {
  setPeopleReportMonitoringModal,
  setNeighborhoodMapModal,
  setNeighborhoodMapModalContent,
  setSiteJabberShown,
  updateNeighborhoodAiUsed,
  updateNeighborhoodAiVoted,
} = reportsSlice.actions;

/* Getters */
export const selectReportStatus = (state: RootState) => state.reports.status;
export const selectReportHistoryStatus = (state: RootState) =>
  state.reports.reportHistoryStatus;
export const selectPeopleReportStatus = (state: RootState) =>
  state.reports.peopleReportStatus;
export const selectPhoneReportStatus = (state: RootState) =>
  state.reports.phoneReportStatus;
export const selectPeopleSearchStatus = (state: RootState) =>
  state.reports.peopleSearchStatus;
export const selectPropertyReportStatus = (state: RootState) =>
  state.reports.propertyReportStatus;
export const selectSocialSearchStatus = (state: RootState) =>
  state.reports.socialSearchStatus;
export const selectAutoReportStatus = (state: RootState) =>
  state.reports.autoReportStatus;
export const selectNeighborhoodReportStatus = (state: RootState) =>
  state.reports.neighborhoodReportStatus;
export const selectNeighborhoodSearchStatus = (state: RootState) =>
  state.reports.neighborhoodSearchStatus;
export const selectRentDataStatus = (state: RootState) =>
  state.reports.rentDataStatus;
export const selectAiStatus = (state: RootState) => state.reports.aiStatus;
export const selectListStatus = (state: RootState) => state.reports.listStatus;
export const selectRentData = (state: RootState) => state.reports.rentData;
export const selectAllReportHistory = (state: RootState) =>
  state.reports.allReportHistory;
export const selectPeopleReportHistory = (state: RootState) =>
  state.reports.peopleReportHistory;
export const selectPhoneReportHistory = (state: RootState) =>
  state.reports.phoneReportHistory;
export const selectPropertyReportHistory = (state: RootState) =>
  state.reports.propertyReportHistory;
export const selectSocialReportHistory = (state: RootState) =>
  state.reports.socialReportHistory;
export const selectNeighborhoodReportHistory = (state: RootState) =>
  state.reports.neighborhoodReportHistory;
export const selectMostRecentReport = (state: RootState) =>
  state.reports.mostRecentReport;
export const selectIsReportHistoryEmpty = (state: RootState) =>
  state.reports.isReportHistoryEmpty;
export const selectTeaserReports = (state: RootState) =>
  state.reports.peopleTeaserReports;
export const selectPeopleReport = (state: RootState) =>
  state.reports.peopleReport;
export const selectPeopleReportMonitoringDifferences = (state: RootState) =>
  state.reports.peopleReportMonitoringDifferences;
export const selectPropertyReport = (state: RootState) =>
  state.reports.propertyReport;
export const selectPropertyReportMonitoringDifferences = (state: RootState) =>
  state.reports.propertyReportMonitoringDifferences;
export const selectSocialReport = (state: RootState) =>
  state.reports.socialReport;
export const selectPhoneReport = (state: RootState) =>
  state.reports.phoneReport;
export const selectAutoReport = (state: RootState) => state.reports.autoReport;
export const selectNeighborhoodReport = (state: RootState) =>
  state.reports.neighborhoodReport;
export const selectNeighborhoodSearch = (state: RootState) =>
  state.reports.neighborhoodSearch;
export const selectPeopleReportMonitoringModal = (state: RootState) =>
  state.reports.peopleReportMonitoringModal;
export const selectSiteJabberShown = (state: RootState) =>
  state.reports.siteJabberShown;
export const selectNeighborhoodMapModal = (state: RootState) =>
  state.reports.neighborhoodMapModal;
export const selectNeighborhoodMapModalContent = (state: RootState) =>
  state.reports.neighborhoodMapModalContent;
export const selectPropertyReportTrackingFlags = (state: RootState) =>
  state.reports.propertyReportTrackingFlags;

export const checkIfReportIsOnList = (
  state: RootState,
  report: CombinedReports,
) => {
  let foundFlag = false;
  state.reports.reportLists?.forEach(reportList => {
    if (
      reportList.savedReports.some(
        savedReport => savedReport.reportType === report.reportType,
      )
    ) {
      switch (report.reportType) {
        case "people":
          if (
            reportList.savedReports.some(
              savedReport =>
                savedReport.reportType === report.reportType &&
                savedReport.reportToken === report.reportToken,
            )
          ) {
            foundFlag = true;
          }
          break;
        case "phone":
          if (
            reportList.savedReports.some(
              savedReport =>
                savedReport.reportType === report.reportType &&
                savedReport.phone === report.phoneReportSummary.phone,
            )
          ) {
            foundFlag = true;
          }
          break;
        case "property":
          if (
            reportList.savedReports.some(
              savedReport =>
                savedReport.reportType === report.reportType &&
                savedReport.addressLine1 ===
                  report.propertyReportSummary?.addressLine1 &&
                savedReport.zip === report.propertyReportSummary?.zip &&
                savedReport.city === report.propertyReportSummary?.city &&
                savedReport.state === report.propertyReportSummary?.state,
            )
          ) {
            foundFlag = true;
          }
          break;
        case "social":
          if (
            reportList.savedReports.some(
              savedReport =>
                savedReport.reportType === report.reportType &&
                savedReport.socialToken ===
                  (renderSocialReportType(report.socialReportSummary)
                    ? renderSocialReportType(report.socialReportSummary)
                    : ""),
            )
          ) {
            foundFlag = true;
          }
          break;
        case "neighborhood":
          if (
            reportList.savedReports.some(
              savedReport =>
                savedReport.reportType === report.reportType &&
                savedReport.addressLine1 === report.addressObject.address &&
                savedReport.zip === report.addressObject?.zipCode &&
                savedReport.city === report.addressObject?.city &&
                savedReport.state === report.addressObject?.state,
            )
          ) {
            foundFlag = true;
          }
          break;
      }
    }
  });
  return foundFlag;
};

export default reportsSlice.reducer;
