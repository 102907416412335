import React, { useEffect, useRef, useState } from "react";
import PrintableComponent from "../../../components/UIComponents/PrintableComponent/PrintableComponent";

import {
  Layout,
  Sticky,
  NavigateToSection,
  NavigateToSectionItemProps,
  Container,
  Padding,
  Text,
  ReportTitle,
  Sheet,
  ReportSummary,
  LoadingPlaceholder,
  CollapsableItemList,
  ContainerLineItem,
  AnchorLink,
  Margin,
} from "@kidslivesafe/sc-react";
import {
  capFirstLetter,
  fullCapsText,
  generateList,
  generateListNotFound,
  generateSocialReportHighlights,
  parseDate,
  renderSocialReportType,
  scoreGenerator,
  sumArraysWithinObjectsInArray,
} from "../../../utils";
import { useReactToPrint } from "react-to-print";
import { GridProps } from "@kidslivesafe/sc-react/lib/atoms/Layout/LayoutTypes";
import { Profiles } from "../../../features/reports/reportTypes";

import { selectBrandInfo } from "../../../features/brand/brandSlice";

import "./social-report.css";
import "./print.css";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import useSiteJabberDispatch from "../../../hooks/siteJabberHook";
import {
  selectSocialReport,
  selectSocialSearchStatus,
} from "../../../features/reports/reportsSlice";
import NoResults from "../../../components/UIComponents/NoResults/NoResults";
import { selectSocialSearchParams } from "../../../features/multisearch/multisearchSlice";
import { sendMemberEventAsync } from "../../../features/admin/adminSlice";

const SocialReport: React.FC = () => {
  // local state
  const [navigateProps, setNavigateProps] = useState<
    NavigateToSectionItemProps[]
  >([]);
  const [noResultsDisclaimer, setNoResultsDisclaimer] =
    useState<JSX.Element | null>(null);
  const [printReady, setPrintReady] = useState<boolean>(false);
  const contentToPrint = useRef<HTMLDivElement>(null);
  const promiseResolveRef = useRef<((value?: any) => void) | null>(null);
  const dispatch = useAppDispatch();

  const socialSearch = useAppSelector(selectSocialSearchParams);

  useEffect(() => {
    if (printReady && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [printReady]);

  let componentFormat: GridProps["templateColumns"] = printReady
    ? "1-column"
    : "1fr-2fr";

  const loading = useAppSelector(selectSocialSearchStatus) === "loading";
  const pulledReport = useAppSelector(selectSocialReport);
  const brandInfo = useAppSelector(selectBrandInfo);
  const runSiteJabber = useSiteJabberDispatch();

  // constants
  const profilePicturesId = "profile-pictures";
  const socialProfilesId = "social-profiles";
  const locationsId = "locations";
  const employmentId = "employment";
  const educationId = "education";
  const affiliatedWebsitesId = "affiliated-websites";

  // effects
  /* generate Navigation Props */
  useEffect(() => {
    setNavigateProps([
      {
        navigateToSectionId: profilePicturesId,
        navigateToSectionText: "Profile Pictures",
        itemCount: pulledReport
          ? sumArraysWithinObjectsInArray([pulledReport.details], ["photos"])
          : 0,
        newInfo: false,
        loading: loading,
      },
      {
        navigateToSectionId: socialProfilesId,
        navigateToSectionText: "Social Profiles",
        itemCount: pulledReport
          ? Object.keys(pulledReport.details.profiles).length
          : 0,
        newInfo: false,
        loading: loading,
      },
      {
        navigateToSectionId: locationsId,
        navigateToSectionText: "Locations",
        itemCount: pulledReport
          ? sumArraysWithinObjectsInArray([pulledReport.details], ["locations"])
          : 0,
        newInfo: false,
        loading: loading,
        displayIfZero: false,
      },
      {
        navigateToSectionId: employmentId,
        navigateToSectionText: "Employment",
        itemCount: pulledReport
          ? sumArraysWithinObjectsInArray(
              [pulledReport.details],
              ["employment"],
            )
          : 0,
        newInfo: false,
        loading: loading,
        displayIfZero: false,
      },
      {
        navigateToSectionId: educationId,
        navigateToSectionText: "Education",
        itemCount: pulledReport
          ? sumArraysWithinObjectsInArray([pulledReport.details], ["education"])
          : 0,
        newInfo: false,
        loading: loading,
        displayIfZero: false,
      },
      {
        navigateToSectionId: affiliatedWebsitesId,
        navigateToSectionText: "Affiliated Websites",
        itemCount: pulledReport
          ? sumArraysWithinObjectsInArray([pulledReport.details], ["urls"])
          : 0,
        newInfo: false,
        loading: loading,
        displayIfZero: false,
      },
    ]);
  }, [pulledReport, loading]);

  useEffect(() => {
    const noResultsCategories: string[] = [
      "Locations",
      "Employment",
      "Education",
      "Affiliated Websites",
    ];
    const noResultsList: string[] = [];
    if (pulledReport) {
      navigateProps.forEach(item => {
        if (
          item.itemCount === 0 &&
          typeof item.navigateToSectionText === "string" &&
          noResultsCategories.includes(item.navigateToSectionText)
        ) {
          noResultsList.push(item.navigateToSectionText);
        }
      });
      if (noResultsList.length > 0) {
        setNoResultsDisclaimer(
          <Margin top="md">
            <Container customClassName="social-report__no-results-disclaimer">
              <Padding space="md">
                <Container bodyBorderRadius altColor="alt">
                  <Padding top="lg" bottom="lg" left="xs" right="xs">
                    <Layout
                      type="flex"
                      flexProps={{
                        direction: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "xs",
                      }}
                    >
                      <Text tag="h1">Other Searches Conducted</Text>
                      <Text fontWeight="semibold">
                        No{" "}
                        {generateListNotFound(
                          noResultsList.map(item => {
                            return { value: item, label: "" };
                          }),
                          ", ",
                        )}{" "}
                        found
                      </Text>
                    </Layout>
                  </Padding>
                </Container>
              </Padding>
            </Container>
          </Margin>,
        );
      } else {
        setNoResultsDisclaimer(null);
      }
    }
    // Show SiteJabber prompt only for select brands
    if (!loading) {
      runSiteJabber();
    }
  }, [navigateProps, pulledReport, loading]);

  const sendMemberEvent = () => {
    dispatch(
      sendMemberEventAsync({
        EventCategory: "Social Report",
        EventAction: "Download Express",
        MetaData: JSON.stringify({
          key: "pdfDownload",
          value: `Report Name: ${
            pulledReport && pulledReport.socialReportSummary
              ? fullCapsText(
                  generateList([
                    {
                      value: pulledReport.socialReportSummary.email,
                      label: "",
                    },
                    {
                      value: pulledReport.socialReportSummary.phone,
                      label: "",
                    },
                    {
                      value: pulledReport.socialReportSummary.twitter,
                      label: "",
                    },
                  ]),
                )
              : ""
          }`,
        }),
      }),
    );
  };

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current as HTMLElement,
    onBeforeGetContent: () => {
      return new Promise<void>(resolve => {
        const originalTitle = document.title;
        document.title =
          pulledReport && pulledReport.socialReportSummary
            ? `${originalTitle} - ${fullCapsText(
                generateList([
                  {
                    value: pulledReport.socialReportSummary.email,
                    label: "",
                  },
                  {
                    value: pulledReport.socialReportSummary.phone,
                    label: "",
                  },
                  {
                    value: pulledReport.socialReportSummary.twitter,
                    label: "",
                  },
                ]),
              )}`
            : originalTitle;
        setPrintReady(true);

        // Function to check if elements with the specific class still exist
        const checkIfElementsClosed = () => {
          const elements = document.querySelectorAll(
            ".sc-container-line-item__content--closed",
          );
          const showMoreElements = document.querySelectorAll(
            ".sc-show-more__icon--closed",
          );
          if (elements.length === 0 && showMoreElements.length === 0) {
            // No elements found, resolve the promise to proceed with printing
            resolve();
          } else {
            // Elements still found, check again after a short delay
            setTimeout(checkIfElementsClosed, 50);
          }
        };

        // Start the checking process
        checkIfElementsClosed();

        // Ensure the title is reset after printing is triggered
        setTimeout(() => {
          document.title = originalTitle; // Restore the original document title
        }, 5000);
      });
    },
    onAfterPrint: () => {
      setPrintReady(false);
    },
    copyStyles: true,
  });

  return (
    <>
      {pulledReport || loading ? (
        <PrintableComponent
          ref={contentToPrint}
          printReady={printReady}
          reportType="social"
          header={
            pulledReport && pulledReport.socialReportSummary
              ? fullCapsText(
                  generateList([
                    {
                      value: pulledReport.socialReportSummary.email,
                      label: "",
                    },
                    {
                      value: pulledReport.socialReportSummary.phone,
                      label: "",
                    },
                    {
                      value: pulledReport.socialReportSummary.twitter,
                      label: "",
                    },
                  ]),
                )
              : undefined
          }
          subtitle={
            pulledReport
              ? generateSocialReportHighlights(
                  pulledReport,
                  renderSocialReportType(pulledReport.socialReportSummary),
                )
              : null
          }
          date={scoreGenerator()}
        >
          <ReportTitle
            reportTitle="Social Report"
            reportType="social"
            handleDownload={
              loading
                ? () => {
                    console.log("Wait for loading");
                  }
                : () => {
                    sendMemberEvent();
                    handlePrint(null, () => null);
                  }
            }
          />
          <Sheet>
            <Padding
              space="md"
              top="none"
              breakpointStyles={{
                mobile: {
                  space: "none",
                  bottom: "md",
                },
              }}
            >
              <Layout
                type="grid"
                gridProps={{ gap: "md", templateColumns: "1-column" }}
                breakpointStyles={{
                  desktop: {
                    type: "grid",
                    gridProps: { templateColumns: componentFormat, gap: "md" },
                  },
                }}
              >
                <>
                  <Layout
                    type="flex"
                    flexProps={{ gap: "md", direction: "column" }}
                    passThroughClassess="page-social-report__left-column"
                  >
                    <Sticky
                      space="sm"
                      turnOffAtBreakPoint={["mobile"]}
                      direction="header-offset"
                    >
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        <ReportSummary
                          reportDate={
                            loading ? (
                              <LoadingPlaceholder
                                height={"16px"}
                                width={"10rem"}
                              />
                            ) : (
                              `Report Date ${parseDate(
                                scoreGenerator(),
                                "MM/DD/YYYY",
                              )}`
                            )
                          }
                          reportTitleWordBreak="break-word"
                          reportTitle={
                            loading ? (
                              <LoadingPlaceholder
                                height={"25px"}
                                width={"15rem"}
                              />
                            ) : pulledReport &&
                              pulledReport.socialReportSummary ? (
                              fullCapsText(
                                generateList([
                                  {
                                    value:
                                      pulledReport.socialReportSummary.email,
                                    label: "",
                                  },
                                  {
                                    value:
                                      pulledReport.socialReportSummary.phone,
                                    label: "",
                                  },
                                  {
                                    value:
                                      pulledReport.socialReportSummary.twitter,
                                    label: "",
                                  },
                                ]),
                              )
                            ) : (
                              ""
                            )
                          }
                          reportHighlights={
                            loading ? (
                              <LoadingPlaceholder
                                height={"18px"}
                                width={"12rem"}
                              />
                            ) : pulledReport ? (
                              generateSocialReportHighlights(
                                pulledReport,
                                renderSocialReportType(
                                  pulledReport.socialReportSummary,
                                ),
                              )
                            ) : null
                          }
                          reportDetails={
                            loading ? (
                              <LoadingPlaceholder
                                height={"24px"}
                                width={"10rem"}
                              />
                            ) : pulledReport && pulledReport.location ? (
                              pulledReport.location
                            ) : null
                          }
                          newInfo={false}
                        />
                        <Padding
                          space="none"
                          breakpointStyles={{
                            mobile: {
                              left: "md",
                              right: "md",
                            },
                          }}
                        >
                          <NavigateToSection
                            items={navigateProps}
                            tooltipContent={`The ${brandInfo.name} Social Report provides you with many different types of data and information. The Navigate to Section menu provides access and counts of records contained in the report for each category of data.`}
                          />
                        </Padding>
                      </Layout>
                    </Sticky>
                  </Layout>
                  <Margin space="none">
                    <Padding
                      space="none"
                      top="md"
                      breakpointStyles={{
                        mobile: {
                          left: "md",
                          right: "md",
                        },
                      }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        {/* Possible Profile Pictures */}
                        <Container
                          containerTitle={`Possible Profile Pictures ${
                            loading
                              ? ""
                              : `(${
                                  pulledReport &&
                                  pulledReport.details &&
                                  pulledReport.details.photos
                                    ? pulledReport.details.photos.length
                                    : 0
                                })`
                          }`}
                          containerId={profilePicturesId}
                        >
                          {loading ? (
                            <Padding space="md">
                              <LoadingPlaceholder
                                height={"80px"}
                                width={"80px"}
                              />
                            </Padding>
                          ) : pulledReport &&
                            pulledReport.details &&
                            pulledReport.details.photos &&
                            pulledReport.details.photos[0] ? (
                            pulledReport.details.photos.map((photo, i) => (
                              <Padding space="md" key={i}>
                                <img
                                  src={photo.value}
                                  alt={photo.label}
                                  style={{ width: "auto", height: "80px" }}
                                />
                              </Padding>
                            ))
                          ) : (
                            <Padding space="md">
                              <Text tag="b">No Profile Pictures found</Text>
                            </Padding>
                          )}
                        </Container>
                        {/* Social Profiles */}
                        <Container
                          containerTitle={`Possible Social Profiles ${
                            loading
                              ? ""
                              : `(${
                                  pulledReport &&
                                  Object.keys(pulledReport.details.profiles)
                                    .length
                                })`
                          }`}
                          containerId={socialProfilesId}
                        >
                          {loading ? (
                            <LoadingContainerLineItem
                              lineItemHeader
                              lineItemSubHeader
                            />
                          ) : pulledReport &&
                            Object.keys(pulledReport.details.profiles).length >
                              0 ? (
                            <CollapsableItemList>
                              {Object.keys(pulledReport.details.profiles).map(
                                (profileKey, i) => {
                                  const profile =
                                    pulledReport.details.profiles[
                                      profileKey as keyof Profiles
                                    ];
                                  if (profile) {
                                    return (
                                      <ContainerLineItem
                                        lineItemHeader={
                                          <AnchorLink
                                            size="base"
                                            hoverUnderline={true}
                                            fontWeight="bold"
                                            href={profile.url}
                                            icon
                                            wordBreak="break-all"
                                          >
                                            {profile.url}
                                          </AnchorLink>
                                        }
                                        lineItemSubHeader={capFirstLetter(
                                          profileKey,
                                        )}
                                        key={`${profileKey}${i}`}
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                },
                              )}
                            </CollapsableItemList>
                          ) : (
                            <Padding space="md">
                              <Text tag="b">No Social Profiles found</Text>
                            </Padding>
                          )}
                        </Container>
                        {/* Locations */}
                        {loading ||
                        (pulledReport &&
                          pulledReport.details.locations &&
                          pulledReport.details.locations[0]) ? (
                          <Container
                            containerTitle={`Possible Locations ${
                              loading
                                ? ""
                                : `(${
                                    pulledReport &&
                                    pulledReport.details.locations
                                      ? pulledReport.details.locations.length
                                      : 0
                                  })`
                            }`}
                            containerId={locationsId}
                          >
                            {loading ? (
                              <LoadingContainerLineItem
                                lineItemHeader
                                lineItemSubHeader
                              />
                            ) : (
                              <CollapsableItemList>
                                {pulledReport &&
                                  pulledReport.details.locations.map(
                                    (location, i) => (
                                      <ContainerLineItem
                                        lineItemHeader={location.formatted}
                                        lineItemSubHeader={location.country}
                                        key={i}
                                      />
                                    ),
                                  )}
                              </CollapsableItemList>
                            )}
                          </Container>
                        ) : null}
                        {/* Employment */}
                        {loading ||
                        (pulledReport &&
                          pulledReport.details.employment &&
                          pulledReport.details.employment[0]) ? (
                          <Container
                            containerTitle={`Possible Employment ${
                              loading
                                ? ""
                                : `(${
                                    pulledReport &&
                                    pulledReport.details.employment
                                      ? pulledReport.details.employment.length
                                      : 0
                                  })`
                            }`}
                            containerId={employmentId}
                          >
                            {loading ? (
                              <LoadingContainerLineItem
                                lineItemHeader
                                lineItemSubHeader
                              />
                            ) : (
                              <CollapsableItemList>
                                {pulledReport &&
                                  pulledReport.details.employment.map(
                                    (job, i) => (
                                      <ContainerLineItem
                                        lineItemHeader={
                                          job.title ? job.title : job.name
                                        }
                                        lineItemSubHeader={
                                          job.title ? job.name : null
                                        }
                                        key={i}
                                      />
                                    ),
                                  )}
                              </CollapsableItemList>
                            )}
                          </Container>
                        ) : null}
                        {/* Education */}
                        {loading ||
                        (pulledReport &&
                          pulledReport.details.education &&
                          pulledReport.details.education[0]) ? (
                          <Container
                            containerTitle={`Possible Education ${
                              loading
                                ? ""
                                : `(${
                                    pulledReport &&
                                    pulledReport.details.education
                                      ? pulledReport.details.education.length
                                      : 0
                                  })`
                            }`}
                            containerId={educationId}
                          >
                            {loading ? (
                              <LoadingContainerLineItem
                                lineItemHeader
                                lineItemSubHeader
                              />
                            ) : (
                              <CollapsableItemList>
                                {pulledReport &&
                                  pulledReport.details.education.map(
                                    (school, i) => (
                                      <ContainerLineItem
                                        lineItemHeader={school.name}
                                        lineItemSubHeader={generateList(
                                          [
                                            {
                                              value: school.end
                                                ? school.end.year
                                                : null,
                                              label: "",
                                            },
                                            {
                                              value: school.degree
                                                ? school.degree
                                                : null,
                                              label: "",
                                            },
                                          ],
                                          " | ",
                                        )}
                                        key={i}
                                      />
                                    ),
                                  )}
                              </CollapsableItemList>
                            )}
                          </Container>
                        ) : null}
                        {/* Affiliated Websites */}
                        {loading ||
                        (pulledReport &&
                          pulledReport.details.urls &&
                          pulledReport.details.urls[0]) ? (
                          <Container
                            containerTitle={`Possible Affiliated Websites ${
                              loading
                                ? ""
                                : `(${
                                    pulledReport && pulledReport.details.urls
                                      ? pulledReport.details.urls.length
                                      : 0
                                  })`
                            }`}
                            containerId={affiliatedWebsitesId}
                          >
                            {loading ? (
                              <LoadingContainerLineItem lineItemHeader />
                            ) : (
                              <CollapsableItemList>
                                {pulledReport &&
                                  pulledReport.details.urls.map((url, i) => (
                                    <ContainerLineItem
                                      lineItemHeader={
                                        <AnchorLink
                                          size="base"
                                          href={url.value}
                                          fontWeight="bold"
                                          hoverUnderline
                                          icon
                                        >
                                          {url.value}
                                        </AnchorLink>
                                      }
                                      key={i}
                                    />
                                  ))}
                              </CollapsableItemList>
                            )}
                          </Container>
                        ) : null}
                      </Layout>
                    </Padding>
                    {/* no results section */}
                    {noResultsDisclaimer}
                  </Margin>
                </>
              </Layout>
            </Padding>
          </Sheet>
        </PrintableComponent>
      ) : (
        <>
          <ReportTitle reportTitle="Social Report" reportType="social" />
          <NoResults
            searchType="social"
            header={
              <Text textAlign="center" fontWeight="bold" tag="h2">
                No results for
              </Text>
            }
            subHeader={
              <Text textAlign="center">
                We’ve run searches and found no data for this input. In some
                cases this may be due to privacy preferences or regulations.
              </Text>
            }
            noResultText={
              <Text tag="h2" fontWeight="bold" textAlign="center">
                {socialSearch}
              </Text>
            }
          />
        </>
      )}
      <div className="stjr-instant-feedback"></div>
    </>
  );
};

export default SocialReport;
