import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";

// components
import { Layout, Text, Map, LoadingPlaceholder } from "@kidslivesafe/sc-react";

// redux getters
import {
  selectNeighborhoodReportHistory,
  selectReportHistoryStatus,
} from "../../../features/reports/reportsSlice";
import { selectMapboxKey } from "../../../features/brand/brandSlice";

// types
import { NeighborhoodReportHistory } from "../../../features/reports/reportTypes";
import { getCoordinates } from "../../../features/reports/reportsAPI";
import { fullCapsText } from "../../../utils";

interface PinData {
  latitude: number;
  longitude: number;
  popup: React.ReactNode;
  element?: React.ReactNode;
}

interface NeighborhoodReportHistoryWithCoordinates
  extends NeighborhoodReportHistory {
  latitude: number;
  longitude: number;
}

const NeighborhoodHistoryMap: React.FC<{}> = () => {
  const reportHistory = useAppSelector(selectNeighborhoodReportHistory);
  const reportHistoryStatus = useAppSelector(selectReportHistoryStatus);
  const [mapPins, setMapPins] = React.useState<PinData[]>([]);

  const dispatch = useAppDispatch();

  const loading = reportHistoryStatus === "loading";

  const mapboxToken = useAppSelector(selectMapboxKey);

  useEffect(() => {
    const enrichReportsWithCoordinates = async (
      reports: NeighborhoodReportHistory[],
    ) => {
      const addressMap = reports.slice(0, 5).map((report, index) => {
        if (report.city && report.addressLine1 && report.state && report.zip) {
          return {
            index,
            address: {
              fullAddress: report.addressLine1 || "",
              city: report.city || "",
              state: report.state,
              postalCode: report.zip,
              latitude: 0,
              longitude: 0,
            },
          };
        } else {
          return null; // Skip reports with no valid address
        }
      });

      const validAddressMap = addressMap.filter(entry => entry !== null) as {
        index: number;
        address: {
          fullAddress: string;
          city: string;
          state: string;
          postalCode: string;
          latitude: number;
          longitude: number;
        };
      }[];

      const addresses = validAddressMap.map(entry => entry.address);

      const coordinateData: any = await getCoordinates(addresses);

      const enrichedReports = [
        ...reports,
      ] as NeighborhoodReportHistoryWithCoordinates[];
      validAddressMap.forEach(({ index }, i) => {
        const coordinate = coordinateData[i];
        if (!coordinate?.error) {
          const { lat: latitude, lon: longitude } = coordinate;
          enrichedReports[index] = {
            ...enrichedReports[index],
            latitude,
            longitude,
          };
        } else {
          enrichedReports[index] = {
            ...enrichedReports[index],
            latitude: 0,
            longitude: 0,
          };
        }
      });

      return enrichedReports;
    };

    const prepareNeighborhoodPinData = (
      history: NeighborhoodReportHistoryWithCoordinates[],
    ) => {
      const pinData = history
        .map(entry => {
          const latitude = Number(entry.latitude);
          const longitude = Number(entry.longitude);

          if (
            !isNaN(latitude) &&
            !isNaN(longitude) &&
            latitude !== 0 &&
            longitude !== 0 &&
            latitude >= 24.396308 &&
            latitude <= 49.384358 &&
            longitude >= -125.0 &&
            longitude <= -66.93457
          ) {
            return {
              latitude,
              longitude,
              popup: (
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Text fontWeight="bold" size="sm">
                    {fullCapsText(
                      `${entry.addressLine1}, ${entry.city}, ${entry.state},${" "}
                    ${entry.zip}`,
                    )}
                  </Text>
                </Layout>
              ),
            };
          } else {
            // Skip this entry if the coordinates are invalid
            return null;
          }
        })
        // Remove null entries
        .filter((pin): pin is Exclude<typeof pin, null> => pin !== null);
      return pinData;
    };

    const fetchAndPreparePins = async () => {
      if (reportHistory && reportHistory.length > 0) {
        // Limit to the first 5 items
        const limitedReportHistory = reportHistory.slice(0, 5);
        const enrichedReports =
          await enrichReportsWithCoordinates(limitedReportHistory);
        const pins = prepareNeighborhoodPinData(enrichedReports);
        setMapPins(pins);
      }
    };

    fetchAndPreparePins();
  }, [reportHistory, dispatch]);

  return (
    <>
      <>
        {loading ? (
          <LoadingPlaceholder width="100%" height="500px" />
        ) : (
          reportHistory &&
          reportHistory.length > 0 && (
            <Map
              mapboxToken={mapboxToken}
              markerData={reportHistory ? mapPins : undefined}
              setBoundaryMarkerData={reportHistory ? mapPins : undefined}
            />
          )
        )}
      </>
    </>
  );
};

export default NeighborhoodHistoryMap;
