import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

// redux getters
import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import {
  formatPhoneNumber,
  generateList,
  parseDate,
  renderArrayOfObjectsPrep,
} from "../../../utils";

// types
import {
  PropertyProfessionalLicense,
  PullPhoneReportParams,
} from "../../../features/reports/reportTypes";

// components
import {
  Container,
  Padding,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  ReportLink,
  Text,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

// pull report hook
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

import { usePhoneReportDispatch } from "../../../hooks/phoneReportHook";

const ProfessionalLicenses: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );

  const loading = propertyReportStatus === "loading";
  const pullPeopleReport = usePeopleReportDispatch();
  const pullPhoneReport = usePhoneReportDispatch();

  /* generate liens Object */
  const licenseObjects = renderArrayOfObjectsPrep(
    pulledReport?.professionalLicenses || [],
    [],
    [
      {
        key: ["nameDetails"],
        keyFormat: () => {
          return "Name";
        },
        valueFormat: (value, currentObject: PropertyProfessionalLicense) => {
          const jsxElements =
            currentObject.nameDetails?.reportToken &&
            currentObject.nameDetails.fullName ? (
              <ReportLink<PullPeopleReportParams>
                inputValue={currentObject.nameDetails.reportToken}
                handleClick={pullPeopleReport}
                text={currentObject.nameDetails.fullName}
                smallText
              />
            ) : (
              <Text passThroughClassess="sc-table-row__detail">
                {currentObject.nameDetails?.fullName}
              </Text>
            );

          return <>{jsxElements}</>;
        },
      },
      {
        key: ["phone"],
        valueFormat: (value, currentObject: PropertyProfessionalLicense) => {
          const jsxElements = (
            <ReportLink<PullPhoneReportParams>
              inputValue={currentObject.phone!}
              handleClick={pullPhoneReport}
              text={formatPhoneNumber(currentObject.phone!)}
              smallText
            />
          );
          return <>{jsxElements}</>;
        },
      },
    ],
  );
  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Professional Licenses at Address ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.professionalLicenses
                  ? pulledReport.professionalLicenses.length
                  : 0
              })`
        }`}
        containerId={containerId}
        tooltipContent="A professional license at an address signifies that a licensed professional, such as a doctor, lawyer, or other regulated occupation, operates or is affiliated with a business at that location."
        newInfo={reportDifferences?.professionalLicenses}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
            children
          />
        ) : pulledReport?.professionalLicenses &&
          pulledReport.professionalLicenses[0] ? (
          <CollapsableItemList numberToShow={5} isOpen={printReady}>
            {pulledReport.professionalLicenses.map((license, index) => (
              <ContainerLineItem
                lineItemHeader={`${
                  license.licenseType ? license.licenseType : null
                }`}
                lineItemSubHeader={generateList(
                  [
                    {
                      value: license.issueDate
                        ? parseDate(license.issueDate, "year")
                        : null,
                      label: "",
                    },
                    {
                      value: license.licenseStatus
                        ? license.licenseStatus
                        : null,
                      label: "",
                    },
                  ],
                  " | ",
                )}
                isOpen={printReady ? true : false}
                key={index}
              >
                {licenseObjects[index] ? (
                  <Padding space="md">
                    <Table
                      orderOfProperties={[]}
                      tableDataObject={licenseObjects[index]}
                    />
                  </Padding>
                ) : null}
              </ContainerLineItem>
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No Leins found at this address
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default ProfessionalLicenses;
