import React from "react";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";

import {
  deleteNeighborhoodWatchMonitoringAreaAsync,
  selectMonitoring,
  selectMonitoringStatus,
  selectNeighborhoodMonitoringAreas,
  selectPropertyMonitoring,
  selectTotalAllowedMonitoredReports,
  setRenameReport,
  setToggleMonitoringModal,
  togglePropertyMonitoringAsync,
} from "../../../features/monitoring/monitoringSlice";
import {
  Text,
  Layout,
  Monitoring,
  ContainerLineItem,
  ReportLink,
  LoadingPlaceholder,
  Padding,
  MonitoringContainer,
  Icon,
  IconList,
  AnchorLink,
  Container,
} from "@kidslivesafe/sc-react";

import {
  NeighborhoodMonitoringInterface,
  PeopleMonitoringInterface,
  PropertyMonitoringInterface,
} from "../../../features/monitoring/monitoringAPI";

import {
  formatAddress,
  formatName,
  fullCapsText,
  generateList,
  generatePropertyReportHighlights,
  parseDate,
} from "../../../utils";
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";
import { useToggleMonitoringDispatch } from "../../../hooks/monitoringHook";
import { PullNeighborhoodReportParams } from "../../../features/reports/reportTypes";
import { useNeighborhoodReportDispatch } from "../../../hooks/neighborhoodReportHook";
import {
  PullPropertyReportParams,
  usePropertySearchDispatch,
} from "../../../hooks/propertyReportHook";

interface MonitoringContainerProps {
  monitoringType?: "People" | "Property" | "Neighborhood" | null;
  containerId?: string;
}

const MonitoringContainerDashboard: React.FC<MonitoringContainerProps> = ({
  monitoringType = null,
  containerId,
}) => {
  const dispatch = useAppDispatch();

  const monitoring = useAppSelector(selectMonitoring);

  const neighborhoodMonitoring = useAppSelector(
    selectNeighborhoodMonitoringAreas,
  );
  const propertyMonitoring = useAppSelector(selectPropertyMonitoring);
  const monitoringStatus = useAppSelector(selectMonitoringStatus);
  const totalAllowedMonitoring = useAppSelector(
    selectTotalAllowedMonitoredReports,
  );

  const pullPeopleReport = usePeopleReportDispatch();
  const pullNeighborhoodReport = useNeighborhoodReportDispatch();
  const pullPropertyReport = usePropertySearchDispatch();
  const { toggleMonitoring } = useToggleMonitoringDispatch();

  type MonitoringType =
    | PeopleMonitoringInterface
    | NeighborhoodMonitoringInterface
    | PropertyMonitoringInterface;

  const sortedData: (PeopleMonitoringInterface | undefined)[] = monitoring
    ? [...monitoring].sort(
        (a, b) => Number(b.createdDate) - Number(a.createdDate),
      )
    : [];

  if (sortedData && sortedData?.length < totalAllowedMonitoring) {
    sortedData.push(undefined);
  }

  function sortMonitoringArray(
    monitoringArray: (MonitoringType | null)[],
  ): (MonitoringType | null)[] {
    return monitoringArray.sort((a, b) => {
      if (!a) return 1;
      if (!b) return -1;
      if (!a && !b) return 0;

      const getTimestamp = (item: MonitoringType): number => {
        if (item.type === "neighborhood") {
          return item.createdAt ? new Date(item.createdAt).getTime() : 0;
        }

        if (item.createdDate) {
          return new Date(
            parseDate(item.createdDate.toString(), "down-to-the-second"),
          ).getTime();
        }

        return item.version
          ? new Date(parseDate(item.version, "down-to-the-second")).getTime()
          : 0;
      };

      const timeA = getTimestamp(a);
      const timeB = getTimestamp(b);

      return timeB - timeA;
    });
  }

  let monitoringArray: any = [];

  monitoringArray = monitoringArray.concat(neighborhoodMonitoring);
  monitoringArray = monitoringArray.concat(monitoring);
  monitoringArray = monitoringArray.concat(propertyMonitoring);
  if (monitoringArray.length < totalAllowedMonitoring) {
    monitoringArray.push(null);
  }
  monitoringArray = sortMonitoringArray(monitoringArray);

  const renderMonitoring = (
    report:
      | PeopleMonitoringInterface
      | NeighborhoodMonitoringInterface
      | PropertyMonitoringInterface
      | undefined,
    index: number,
  ) => {
    if (report && report.type === "people" && monitoringType === "People") {
      return (
        <MonitoringContainer
          key={report.firstName + report.version + index}
          active
          newInfo={report.hasChanges}
          fixedHeight={false}
        >
          <Padding space="md">
            <Layout type="flex" flexProps={{ gap: "md", direction: "column" }}>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{ gap: "xxs", alignItems: "center" }}
                  passThroughClassess="dashboard__monitoring-container__title"
                >
                  <Icon name={IconList.IconPeople} size={24} />
                  <Text size="sm" fontWeight="semibold">
                    {`Person ${sortedData && sortedData.length - sortedData.indexOf(report) - 1}`}
                  </Text>
                </Layout>
                <Monitoring
                  handleMonitoring={toggleMonitoring}
                  monitoringInput={{
                    reportToken: report.reportToken,
                    version: report.version,
                  }}
                  initialState={true}
                />
              </Layout>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  direction: "column",
                }}
              >
                <ReportLink<PullPeopleReportParams>
                  handleClick={pullPeopleReport}
                  inputValue={report.reportToken}
                  text={formatName(report)}
                  noWrap
                />
                <Text size="xs">
                  {generateList(
                    [
                      {
                        value: formatAddress(report)
                          ? formatAddress(report, "with-comma")
                          : null,
                        label: "",
                      },
                      {
                        value: report.age ? report.age : null,
                        label: "Age ",
                      },
                    ],
                    " | ",
                  )}
                </Text>
              </Layout>
              <Text size="xxs" tag="i" textAlign="center">
                {generateList(
                  [
                    {
                      value: report.createdDate
                        ? parseDate(report.createdDate, "MM/DD/YYYY")
                        : null,
                      label: "set on ",
                    },
                    // NW TODO: Add last viewed
                  ],
                  " | ",
                )}
              </Text>
            </Layout>
          </Padding>
        </MonitoringContainer>
      );
    } else if (
      report &&
      report.type === "neighborhood" &&
      monitoringType === "Neighborhood"
    ) {
      return (
        <MonitoringContainer key={report.id} active fixedHeight={false}>
          <Padding space="md">
            <Layout type="flex" flexProps={{ gap: "md", direction: "column" }}>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    alignItems: "center",
                  }}
                  passThroughClassess="dashboard__monitoring-container__title"
                >
                  <Icon name={IconList.IconNeighborhoodWatch} size={24} />
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      alignItems: "center",
                      wrap: "wrap",
                    }}
                  >
                    <Text size="sm" fontWeight="semibold" textWrap="wrap">
                      {report.nickname}
                    </Text>
                    <AnchorLink
                      size="xs"
                      color="primary"
                      role="button"
                      hoverUnderline
                      handleClick={() => {
                        dispatch(setRenameReport(report));
                        dispatch(setToggleMonitoringModal(true));
                      }}
                    >
                      (rename)
                    </AnchorLink>
                  </Layout>
                </Layout>
                <Monitoring
                  handleMonitoring={(dto: { id: number }) =>
                    dispatch(deleteNeighborhoodWatchMonitoringAreaAsync(dto))
                  }
                  monitoringInput={{
                    id: report.id,
                  }}
                  initialState={true}
                />
              </Layout>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  direction: "column",
                }}
              >
                <ContainerLineItem
                  lineItemHeader={
                    <Layout type="flex" flexProps={{ alignItems: "center" }}>
                      <ReportLink<PullNeighborhoodReportParams>
                        handleClick={pullNeighborhoodReport}
                        inputValue={{
                          addressSearch: {
                            address: report.address,
                            city: report.city,
                            state: report.state,
                            zipCode: report.zipCode,
                          },
                          searchMethod: "address",
                        }}
                        text={fullCapsText(
                          formatAddress(
                            {
                              addressLine1: report.address,
                              city: report.city,
                              state: report.state,
                              postalCode: report.zipCode,
                            },
                            "with-comma",
                          ),
                        )}
                        noWrap
                      />
                    </Layout>
                  }
                  lineItemHeaderNoWrap
                  noPadding
                  lineItemHeaderCenter
                />
                <Text size="xs">
                  {generateList(
                    [
                      {
                        value: report.latestOffendersCount
                          ? report.latestOffendersCount
                          : null,
                        label: " Offenders Nearby",
                        order: "reverse",
                      },
                      {
                        value: report.radius ? report.radius : null,
                        label: " Mile Radius",
                        order: "reverse",
                      },
                    ],
                    " | ",
                  )}
                </Text>
              </Layout>
              <Text size="xxs" tag="i" textAlign="center">
                {generateList(
                  [
                    {
                      value: report.createdAt
                        ? parseDate(report.createdAt, "MM/DD/YYYY")
                        : null,
                      label: "set on ",
                    },
                    // NW TODO: Add last viewed
                  ],
                  " | ",
                )}
              </Text>
            </Layout>
          </Padding>
        </MonitoringContainer>
      );
    } else if (
      report &&
      report.type === "property" &&
      monitoringType === "Property"
    ) {
      return (
        <MonitoringContainer
          key={`${report.type}-${report.addressLine1}`}
          active
          fixedHeight={false}
          newInfo={report.hasChanges}
        >
          <Padding space="md">
            <Layout type="flex" flexProps={{ gap: "md", direction: "column" }}>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  passThroughClassess="dashboard__monitoring-container__title"
                >
                  <Icon name={IconList.IconProperty} size={24} />
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      alignItems: "center",
                      wrap: "wrap",
                    }}
                  >
                    <Text size="sm" fontWeight="semibold" textWrap="wrap">
                      {report.nickname}
                    </Text>
                    <AnchorLink
                      size="xs"
                      color="primary"
                      role="button"
                      hoverUnderline
                      handleClick={() => {
                        dispatch(setRenameReport(report));
                        dispatch(setToggleMonitoringModal(true));
                      }}
                    >
                      (rename)
                    </AnchorLink>
                  </Layout>
                </Layout>
                <Monitoring
                  handleMonitoring={dto =>
                    dispatch(togglePropertyMonitoringAsync(dto))
                  }
                  monitoringInput={{
                    addressLine1: report.addressLine1,
                    addressLine2: report.addressLine2,
                    city: report.city,
                    state: report.state,
                    zip: report.zip,
                    nickname: report.nickname,
                    version: report.version,
                    bedrooms: report.bedrooms,
                    totalBaths: report.totalBaths,
                    sqft: report.sqft,
                    lotAcres: report.lotAcres,
                  }}
                  initialState={true}
                />
              </Layout>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  direction: "column",
                }}
              >
                <ContainerLineItem
                  lineItemHeader={
                    <Layout type="flex" flexProps={{ alignItems: "center" }}>
                      <ReportLink<PullPropertyReportParams>
                        handleClick={pullPropertyReport}
                        inputValue={{
                          addressLine1: report.addressLine1,
                          addressLine2: report.addressLine2,
                          city: report.city,
                          state: report.state,
                          zip: report.zip,
                        }}
                        text={fullCapsText(
                          formatAddress(
                            {
                              addressLine1: report.addressLine1,
                              addressLine2: report.addressLine2,
                              city: report.city,
                              state: report.state,
                              postalCode: report.zip,
                            },
                            "with-comma",
                          ),
                        )}
                        noWrap
                      />
                    </Layout>
                  }
                  lineItemHeaderNoWrap
                  noPadding
                  lineItemHeaderCenter
                />
                <Text size="xs">
                  {generatePropertyReportHighlights({
                    propertyDetails: {
                      bedrooms: report.bedrooms,
                      totalBaths: report.totalBaths,
                      buildingSquareFeet: report.sqft,
                      acres: report.lotAcres,
                    },
                  })}
                </Text>
              </Layout>
              <Text size="xxs" tag="i" textAlign="center">
                {generateList(
                  [
                    {
                      value: report.createdDate
                        ? parseDate(report.createdDate, "MM/DD/YYYY")
                        : null,
                      label: "set on ",
                    },
                  ],
                  " | ",
                )}
              </Text>
            </Layout>
          </Padding>
        </MonitoringContainer>
      );
    }
  };

  return (
    <>
      <Container
        containerTitle={`${monitoringType} Report Monitoring`}
        containerId={containerId}
      >
        <Padding space="md">
          <Padding bottom="md" breakpointStyles={{ mobile: { bottom: "xl" } }}>
            <Text size="xs" textAlign="left">
              This is an overview of your monitored {monitoringType} Reports, to
              manage all of your monitored reports{" "}
              <AnchorLink
                inline
                role="link"
                hoverUnderline
                href="/#dashboard__monitoring__container"
                target="_parent"
                size="xs"
              >
                click here.
              </AnchorLink>
            </Text>
          </Padding>
          <Layout
            type="flex"
            flexProps={{ direction: "column", gap: "md" }}
            breakpointStyles={{
              desktop: {
                type: "grid",
                gridProps: { templateColumns: "2-column", gap: "md" },
              },
            }}
          >
            {monitoringStatus === "loading" ? (
              Array(2)
                .fill(null)
                .map((_, index) => (
                  <MonitoringContainer key={index}>
                    <ContainerLineItem
                      key={index}
                      lineItemHeader={
                        <LoadingPlaceholder width={"10rem"} height={"20px"} />
                      }
                      lineItemDetails={
                        <Layout
                          type="flex"
                          flexProps={{
                            gap: "xxs",
                            direction: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <LoadingPlaceholder width={"55px"} height={"13px"} />
                          <LoadingPlaceholder width={"50px"} height={"13px"} />
                        </Layout>
                      }
                      lineItemSubHeader={<LoadingPlaceholder width={"12rem"} />}
                      lineItemHeaderCenter
                    />
                  </MonitoringContainer>
                ))
            ) : monitoringStatus === "failed" ? (
              <Text>Failed to load monitoring</Text>
            ) : monitoringArray ? (
              monitoringArray.map((_: any, index: number) => {
                return renderMonitoring(monitoringArray[index], index + 1);
              })
            ) : (
              <Text>You are not currently monitoring anyone.</Text>
            )}
          </Layout>
        </Padding>
      </Container>
    </>
  );
};

export default MonitoringContainerDashboard;
