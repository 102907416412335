import React, { useState } from "react";
import {
  CollapsableItemList,
  Container,
  Layout,
  MultiSearch,
  Padding,
  Sheet,
  Text,
  Modal,
  Input,
  Button,
} from "@kidslivesafe/sc-react";

import "./propertyHome.css";

import { useMultiSearchProps } from "../../../hooks/multiSearchPropsHook";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import {
  selectPropertyReportHistory,
  selectReportHistoryStatus,
} from "../../../features/reports/reportsSlice";
import ActivityHistoryLineItem from "../../../components/UIComponents/ActivityHistory/ActivityHistoryLineItem";
import PropertyHistoryMap from "./PropertyHistoryMap";
import PropertyReportMonitoring from "./PropertyReportMonitoring";
import SpacerMultiSearch from "../../../components/UIComponents/SpacerMultiSearch/SpacerMultiSearch";
import {
  selectRenameReport,
  selectToggleMonitoringModal,
  setToggleMonitoringModal,
  updatePropertyMonitoringAsync,
} from "../../../features/monitoring/monitoringSlice";

const PropertyHome: React.FC = () => {
  const dispatch = useAppDispatch();

  const reportHistory = useAppSelector(selectPropertyReportHistory);
  const reportHistoryStatus = useAppSelector(selectReportHistoryStatus);

  const loading = reportHistoryStatus === "loading";

  const [nickname, setNickname] = useState<string>("");
  const [nicknameError, setNickNameError] = useState<boolean>(false);

  const renameReport = useAppSelector(selectRenameReport);
  const monitoringModal = useAppSelector(selectToggleMonitoringModal);

  const multiSearchProps = useMultiSearchProps(
    {
      footer: true,
      showCancel: false,
      topPadding: "xs",
      resetInput: true,
      buttonText: "New Search",
      openTab: "property",
      tabs: ["property"],
    },
    "property-home",
  );

  const handleSubmitNickName = (nickname: string) => {
    if (nickname.length > 15) {
      setNickNameError(true);
    } else {
      setNickNameError(false);
    }
  };

  return (
    <>
      <Container>
        <Sheet>
          <Padding left="md" right="md">
            <Layout type="flex" flexProps={{ gap: "xs", direction: "column" }}>
              <Text tag="h3" textAlign="center">
                Property Search
              </Text>
              <Text size="sm" textAlign="center">
                Search over 160 million properties in the US to discover owners,
                deeds, values and transactions.{" "}
              </Text>
            </Layout>
          </Padding>
        </Sheet>
      </Container>
      <MultiSearch {...multiSearchProps} />
      <Sheet>
        <Padding space="md">
          <Layout type="flex" flexProps={{ gap: "xs", direction: "column" }}>
            <Container
              containerTitle="Property Report History"
              containerId="property-home__activity-history__container"
            >
              <PropertyHistoryMap />
              <CollapsableItemList>
                {loading ? (
                  Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <ActivityHistoryLineItem
                        report={undefined}
                        loading={loading}
                        loadingKey={index}
                        key={index}
                      />
                    ))
                ) : reportHistory && reportHistory.length > 0 ? (
                  reportHistory.map(report =>
                    report ? (
                      <ActivityHistoryLineItem
                        report={report}
                        loadingKey={report.score}
                        key={report.score}
                      />
                    ) : null,
                  )
                ) : (
                  <Padding space="md">
                    <Text size="xs">
                      Get started by searching an address above.
                    </Text>
                  </Padding>
                )}
              </CollapsableItemList>
            </Container>
          </Layout>
        </Padding>
      </Sheet>
      <Sheet>
        <PropertyReportMonitoring />
        <Padding top="md">
          <SpacerMultiSearch options={["people", "phone", "neighborhood"]} />
        </Padding>
      </Sheet>
      <Modal
        open={monitoringModal}
        setOpen={() => {
          dispatch(setToggleMonitoringModal(!monitoringModal));
          setNickname("");
        }}
        size="fit-content"
        xClose
        outSideClose
      >
        <Padding space="md">
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
              alignItems: "center",
            }}
          >
            <Text tag="h2">Set New Name</Text>
            <Text size="xs" tag="i">
              Please use 15 characters maximum
            </Text>
            <Layout
              type="flex"
              flexProps={{
                gap: "sm",
                direction: "column",
                alignItems: "center",
              }}
            >
              <Input
                id="neighborhood-report__monitoring-modal"
                value={nickname}
                handleChange={input => {
                  setNickname(input);
                  handleSubmitNickName(input);
                }}
                type="text"
                placeholder={
                  renameReport && renameReport.type === "property"
                    ? "Property"
                    : "Neighborhood"
                }
                fill="fill"
                error={nicknameError}
              />
              <Button
                buttonText="Set Name"
                fill="fill"
                disabled={nicknameError}
                handleClick={() => {
                  if (renameReport) {
                    if (renameReport.type === "property") {
                      dispatch(
                        updatePropertyMonitoringAsync({
                          addressLine1: renameReport.addressLine1,
                          addressLine2: renameReport.addressLine2,
                          city: renameReport.city,
                          state: renameReport.state,
                          zip: renameReport.zip,
                          nickname: nickname,
                        }),
                      );
                    }
                  }
                  dispatch(setToggleMonitoringModal(!monitoringModal));
                  setNickname("");
                }}
              />
            </Layout>
          </Layout>
        </Padding>
      </Modal>
    </>
  );
};

export default PropertyHome;
