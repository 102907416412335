import React from "react";

import {
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
  AnchorLink,
  Button,
} from "@kidslivesafe/sc-react";
import {
  useAppDispatch,
  useAppSelector,
  useHandlePageNavigate,
} from "../../../hooks/hooks";

import {
  downsellSubscriptionAsync,
  getSubscriptionDetailsAsync,
  selectDownsellDetails,
  selectPrimarySubscription,
  selectSubscriptionStatus,
} from "../../../features/subscriptions/subscriptionSlice";

import "./cancel-offer.css";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import { useCancelSubscription } from "../../../hooks/cancelHook";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

const CancelOffer: React.FC = () => {
  const pageNavigate = useHandlePageNavigate();
  const dispatch = useAppDispatch();
  const cancelSubscription = useCancelSubscription();
  const subscriptionInfo = useAppSelector(selectPrimarySubscription);
  const downsellData = useAppSelector(selectDownsellDetails);
  const brandInfo = useAppSelector(selectBrandInfo);

  const currentBreakpoint = useCurrentBreakpoint();
  const loading = useAppSelector(selectSubscriptionStatus) === "loading";

  const renderPlanDetails = () => {
    let content: string[] = [];

    // default content TODO: setup for kls neighborhood accounts
    switch (brandInfo.acronym) {
      case "pf":
        content = [
          "Search and Report History Preserved",
          "Continuous Report Monitoring",
          "Email alerts when monitoring detects changes",
        ];

        if (downsellData) {
          // spliced in reverse order to show in correct order
          if (downsellData.downSellAutoReportThreshold > 0) {
            content.splice(
              1,
              0,
              `Up to ${downsellData.downSellAutoReportThreshold} Auto Reports per month`,
            );
          }
          if (downsellData.downSellSocialReportThreshold > 0) {
            content.splice(
              1,
              0,
              `Up to ${downsellData.downSellSocialReportThreshold} Social Reports per month`,
            );
          }
          if (downsellData.downSellPhoneReportThreshold > 0) {
            content.splice(
              1,
              0,
              `Up to ${downsellData.downSellPhoneReportThreshold} Phone Reports per month`,
            );
          }
          if (downsellData.downSellPeopleReportThreshold > 0) {
            content.splice(
              1,
              0,
              `Up to ${downsellData.downSellPeopleReportThreshold} People Reports per month`,
            );
          }
          if (downsellData.downSellPropertyReportThreshold > 0) {
            content.splice(
              1,
              0,
              `Up to ${downsellData.downSellPropertyReportThreshold} Property Reports per month`,
            );
          }
        }

        break;
      default:
        content = [
          "Search and Report History Preserved",
          "Up to twenty (20) People, Phone or Property Reports per month",
          "Continuous Report Monitoring",
          "Email alerts when monitoring detects changes",
        ];
    }

    return (
      <ul className="page-cancel-offer_ul">
        {content.map((item, index) => (
          <li key={index}>
            <Text>{item}</Text>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <ReportTitle reportTitle="Switch and Save" />
      <Sheet>
        <Padding
          top={currentBreakpoint === "desktop" ? "xl" : "md"}
          left="md"
          right="md"
          bottom="md"
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "md",
            }}
          >
            <Text>
              Save money and get great information with our Limited Plan.
            </Text>
            <Text>
              Keep the most important people report features for only{" "}
              <Text tag="b">
                ${downsellData?.downSellPrice} every{" "}
                {subscriptionInfo?.billingInterval} days.
              </Text>
            </Text>
            <Text>
              <Text tag="b">
                You save over{" "}
                {subscriptionInfo && downsellData && brandInfo.acronym === "pf"
                  ? Math.floor(
                      ((subscriptionInfo?.price - downsellData?.downSellPrice) /
                        subscriptionInfo?.price) *
                        100,
                    )
                  : "80"}
                %
              </Text>{" "}
              and still get:
            </Text>
            {renderPlanDetails()}
            <Layout
              type="flex"
              flexProps={{
                gap: "xs",
                direction: "column",
                alignItems: "center",
              }}
            >
              <Button
                buttonText={`Yes, Switch to $${downsellData?.downSellPrice} Plan`}
                handleClick={() => {
                  if (loading) return;
                  dispatch(downsellSubscriptionAsync()).then(action => {
                    if (downsellSubscriptionAsync.fulfilled.match(action)) {
                      dispatch(getSubscriptionDetailsAsync());
                      pageNavigate("/offer-confirmation");
                    }
                  });
                }}
                loading={loading}
                size={brandInfo.acronym === "pf" ? "md" : "sm"} // setup for longer text length.
              />
              <AnchorLink
                role="button"
                hoverUnderline
                handleClick={() => {
                  cancelSubscription();
                }}
              >
                No Thanks, Cancel
              </AnchorLink>
            </Layout>
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default CancelOffer;
