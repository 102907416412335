import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import {
  loginAsync,
  selectAuthStatus,
  selectUsername,
  selectRememberUsername,
  saveUsername,
  getMemberExperienceDataAsync,
} from "../../../features/authentication/authenticationSlice";
import { selectBrandInfo } from "../../../features/brand/brandSlice";
import { getSubscriptionDetailsAsync } from "../../../features/subscriptions/subscriptionSlice";

import "./login.css";

import {
  getAllListsAsync,
  getReportHistoryAsync,
} from "../../../features/reports/reportsSlice";

import {
  Container,
  Padding,
  Layout,
  Text,
  Sheet,
  Input,
  Button,
  AnchorLink,
  CheckBox,
  Form,
  Header,
} from "@kidslivesafe/sc-react";
import { useNavigate, useLocation } from "react-router-dom";

import Logo from "@assets/images/brand-logo.svg";
import HeaderLogo from "@assets/images/brand-logo--header.svg";

import {
  getMonitoringAsync,
  getNeighborhoodWatchMonitoringAreasAsync,
  getPropertyMonitoringAsync,
} from "../../../features/monitoring/monitoringSlice";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import { formatPhoneNumber } from "../../../utils";

const Login: React.FC = () => {
  /* -- local state -- */
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [localRememberUsername, setLocalRememberUsername] =
    useState<boolean>(false);

  /* -- redux state -- */
  const authStatus = useAppSelector(selectAuthStatus);
  const brandInfo = useAppSelector(selectBrandInfo);
  const username = useAppSelector(selectUsername);
  const rememberUsername = useAppSelector(selectRememberUsername);

  /* -- setup hooks -- */
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentBreakPoint = useCurrentBreakpoint();

  useEffect(() => {
    const myCookie = getCookie("username");
    const emailToSet = username || myCookie;

    if (emailToSet) {
      setEmail(emailToSet);
    }
    setLocalRememberUsername(rememberUsername);
  }, [username, rememberUsername]);

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(";").shift();
    }
  };

  // Check for redirect query parameter
  const redirect = new URLSearchParams(location.search).get("redirect");

  /* -- handle submit -- */
  const handleLoginSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  ) => {
    if (e) {
      e.preventDefault();
    }

    if (!email && !password) {
      setErrorMessage("Please fill out the entire form.");
      setEmailError(true);
      setPasswordError(true);
      return;
    } else if (!password) {
      setErrorMessage("Please fill out the entire form.");
      setPasswordError(true);
      return;
    } else if (!email) {
      setErrorMessage("Please fill out the entire form.");
      setEmailError(true);
      return;
    }

    try {
      const resultAction = await dispatch(loginAsync({ email, password }));
      if (loginAsync.fulfilled.match(resultAction)) {
        console.log("Login successful");

        dispatch(saveUsername(localRememberUsername));

        // get subscription details
        const subscriptionAction = await dispatch(
          getSubscriptionDetailsAsync(),
        );
        if (getSubscriptionDetailsAsync.fulfilled.match(subscriptionAction)) {
          // console.log("Subscription details fetched successfully");
        }

        // get report history
        dispatch(getReportHistoryAsync());
        // trigger get monitoring
        dispatch(getMonitoringAsync());
        dispatch(getNeighborhoodWatchMonitoringAreasAsync());
        dispatch(getPropertyMonitoringAsync());

        // get lists data
        dispatch(getAllListsAsync());

        // get member experience data
        dispatch(getMemberExperienceDataAsync());

        setTimeout(() => {
          navigate(redirect || "/");
        }, 0);
      } else {
        if (resultAction.error.message === "HasCustomerLockout") {
          setErrorMessage(
            "Account locked. Please contact customer support at " +
              formatPhoneNumber(brandInfo.phoneNumber),
          );
        } else {
          setErrorMessage("Login failed. Please try again.");
        }
        setEmailError(true);
        setPasswordError(true);
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleRememberUsername = (value: boolean) => {
    setLocalRememberUsername(value);
  };

  const renderLogo = () => {
    switch (brandInfo.acronym) {
      case "sf":
        return (
          <img
            width={currentBreakPoint === "desktop" ? "147" : "88"}
            src={Logo}
            alt="Logo"
          />
        );
      case "pdc":
        return (
          <img
            width={currentBreakPoint === "desktop" ? "170" : "170"}
            src={Logo}
            alt="Logo"
          />
        );
      case "pis":
        return (
          <img
            width={currentBreakPoint === "desktop" ? "170" : "170"}
            src={HeaderLogo}
            alt="Logo"
          />
        );
      case "spr":
        return (
          <img
            width={currentBreakPoint === "desktop" ? "170" : "170"}
            src={HeaderLogo}
            alt="Logo"
          />
        );
      case "prr":
        return <img width="147" src={HeaderLogo} alt="Logo" />;
      case "kls":
        return (
          <img
            width={currentBreakPoint === "desktop" ? "170" : "170"}
            src={Logo}
            alt="Logo"
          />
        );
      case "qpr":
        return (
          <img
            width={currentBreakPoint === "desktop" ? "170" : "170"}
            src={Logo}
            alt="Logo"
          />
        );
      case "pf":
        return (
          <img
            width={currentBreakPoint === "desktop" ? "170" : "170"}
            src={HeaderLogo}
            alt="Logo"
          />
        );
      default:
        return (
          <img
            width={currentBreakPoint === "desktop" ? "147" : "88"}
            src={Logo}
            alt="Logo"
          />
        );
    }
  };

  return (
    <>
      {brandInfo.acronym === "qpr" ? (
        <Header
          homeUrl="/"
          displayButtons={false}
          menuButtonLinks={[]}
          logoUrl={HeaderLogo}
        ></Header>
      ) : (
        <Sheet>
          <Layout
            type="flex"
            flexProps={{ direction: "column", alignItems: "flex-start" }}
          >
            <Padding space="md">
              <div className="page-login-brand-logo-container">
                <Text color="white">{renderLogo()}</Text>
              </div>
            </Padding>
          </Layout>
        </Sheet>
      )}
      <Sheet>
        <Padding space="md">
          <div
            className={
              currentBreakPoint === "desktop"
                ? "page-login-sheet-form-container"
                : ""
            }
          >
            <Layout type="flex" flexProps={{ direction: "column", gap: "lg" }}>
              <Container
                bodyBorderRadius
                border={brandInfo.acronym === "qpr" ? true : false}
                customClassName="page-login__container"
              >
                <Padding
                  space={["pf"].includes(brandInfo.acronym) ? "xl" : "md"}
                >
                  <Form>
                    <Layout
                      type="flex"
                      flexProps={{
                        direction: "column",
                        gap: "md",
                      }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "column",
                          gap: "xl",
                        }}
                      >
                        <Text tag="h1">Member Login</Text>
                        <Text size="xs" fontWeight="semibold">
                          Don't have an account?{" "}
                          <AnchorLink
                            inline
                            color="primary"
                            href={brandInfo.homeUrl}
                            size="xs"
                            fontWeight={
                              ["qpr", "pf"].includes(brandInfo.acronym)
                                ? "normal"
                                : "semibold"
                            }
                          >
                            Join Today!
                          </AnchorLink>
                        </Text>
                      </Layout>
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "column",
                          gap: "xs",
                        }}
                      >
                        <Input
                          id="login-email"
                          type="email"
                          value={email}
                          handleChange={setEmail}
                          placeholder="Enter Email"
                          handleError={() => {
                            setEmailError(false);
                            setPasswordError(false);
                          }}
                          error={emailError}
                          enableLastPass
                        />
                        <Input
                          id="login-password"
                          type="password"
                          placeholder="Enter Password"
                          value={password}
                          handleChange={setPassword}
                          handleError={() => {
                            setEmailError(false);
                            setPasswordError(false);
                          }}
                          error={passwordError}
                          enableLastPass
                        />
                        {(emailError || passwordError) && errorMessage && (
                          <Layout
                            type="flex"
                            flexProps={{ alignSelf: "flex-start" }}
                          >
                            <Text size="xs" color="error">
                              {errorMessage}
                            </Text>
                          </Layout>
                        )}
                      </Layout>
                      <Text size="xs" tag="div">
                        <Layout
                          type="flex"
                          flexProps={{ direction: "row", gap: "xxs" }}
                        >
                          <CheckBox
                            labelText="Remember Username"
                            checkedValue={localRememberUsername}
                            handleChange={handleRememberUsername}
                          />
                        </Layout>
                      </Text>

                      <Button
                        buttonText="Log In"
                        color="primary"
                        handleClick={handleLoginSubmit}
                        disabled={authStatus === "loading"}
                        size="md"
                        fill="fill"
                        passThroughClassess="page-loign__button-border"
                      />
                      <Layout
                        type="flex"
                        flexProps={{
                          alignItems: "center",
                          direction: "column",
                          gap: "xl",
                        }}
                      >
                        <AnchorLink
                          href="/reset-password"
                          target="_self"
                          size="xs"
                        >
                          Forgot Password?
                        </AnchorLink>
                        <Layout
                          type="flex"
                          flexProps={{
                            alignItems: "center",
                            direction: "column",
                            gap: "xxs",
                          }}
                        >
                          <Text size="sm" fontWeight="semibold">
                            Need Help? Contact Us
                          </Text>
                          <AnchorLink
                            inline
                            phoneNumber
                            target="_self"
                            hoverUnderline
                            href={brandInfo.phoneNumber}
                            size="xs"
                          >
                            {formatPhoneNumber(brandInfo.phoneNumber)}
                          </AnchorLink>
                        </Layout>
                      </Layout>
                    </Layout>
                  </Form>
                </Padding>
              </Container>
            </Layout>
          </div>
        </Padding>
      </Sheet>
    </>
  );
};

export default Login;
