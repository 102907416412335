import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";

// redux getters
import {
  selectAiStatus,
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
  selectPropertyReportTrackingFlags,
  updateNeighborhoodAiUsed,
  updateNeighborhoodAiVoted,
} from "../../../features/reports/reportsSlice";
import {
  selectBrandInfo,
  selectMapboxKey,
} from "../../../features/brand/brandSlice";

// utils
import {
  generateList,
  parseDate,
  formatName,
  convertToTitleCase,
  convertStateAbbreviation,
} from "../../../utils";

// types
import {
  PropertyNeighbor,
  NeighborhoodSchool,
  PropertyDetails,
} from "../../../features/reports/reportTypes";

// components
import {
  Container,
  Layout,
  Padding,
  Tag,
  Text,
  CollapsableItemList,
  ContainerLineItem,
  ReportLink,
  LoadingPlaceholder,
  Map,
  Icon,
  IconList,
  AnchorLink,
  GradientBackground,
  ShowMore,
  MultiSearch,
  SectionTitle,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@kidslivesafe/sc-react";
import { classFormation } from "@kidslivesafe/sc-foundation";

import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

import {
  PullPropertyReportParams,
  usePropertySearchDispatch,
} from "../../../hooks/propertyReportHook";
import { useMultiSearchProps } from "../../../hooks/multiSearchPropsHook";

import SchoolRatingCircle from "../../../components/UIComponents/SchoolRatingCircle/SchoolRatingCircle";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { selectMemberExperienceData } from "../../../features/authentication/authenticationSlice";
import { sendMemberEventAsync } from "../../../features/admin/adminSlice";

interface PinData {
  latitude: number;
  longitude: number;
  popup: React.ReactNode;
  element?: React.ReactNode;
}

const NeighborhoodOverview: React.FC<{
  containerId: string;
  sectionTitleId: string;
  printReady?: boolean;
}> = ({ containerId, sectionTitleId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );
  const loading = propertyReportStatus === "loading";
  const brandInfo = useAppSelector(selectBrandInfo);
  const memberExperienceData = useAppSelector(selectMemberExperienceData);

  const currentDateMonthYear = new Date().toLocaleDateString();

  const schoolGrades = (gradeSchools: string) => {
    switch (gradeSchools) {
      case "KG,1,2,3,4,5,6":
        return "K-6";
      case "PK,KG,1,2,3,4,5,6":
        return "PK-6";
      case "KG,1,2,3,4,5,6,7,8,9,10,11,12":
        return "KG-12";
      case "PK,KG,1,2,3,4,5,6,7,8,9,10,11,12":
        return "PK-12";
      case "7,8,9,10,11,12,UG":
        return "7-12 & UG";
      case "PK,KG,1,2,3,4":
        return "PK-4";
      case "PK,9,10,11,12":
        return "PK & HS";
      case "8,9,10,11,12":
        return "8-12";
      case "KG,1,2,3,4,5,6,7,8":
        return "KG-8";
      case "6,7,8,9,10,11,12":
        return "6-12";
      case "6,7,8":
        return "6-8";
      case "5,6,7,8,12":
        return "5-8 & 12";
      case "1,2,3":
        return "1-3";
      case "KG,1,2,3,4":
        return "KG-4";
      case "PK,KG":
        return "PSED-KDG";
      case "2,3":
        return "2nd and 3rd grade";
      case "9,10,11,12":
        return "HS";
      case "7,8":
        return "MS";
      case "KG,1,2,3,4,5":
        return "K-5";
      case "PK,KG,1,2,3,4,5":
        return "PSED-5";
      case "KG":
        return "Kindergarten";
      case "UG":
        return "UG";
      case "PK,KG,1,2,3,4,5,6,7,8":
        return "PK-8";
      case "1,2,3,4,5,6,7,8,9,10,11,12":
        return "1-12";
      default:
        return gradeSchools;
    }
  };

  const getNumberSuffix = (number: number) => {
    if (number % 100 >= 11 && number % 100 <= 13) {
      return number + "th";
    }

    switch (number % 10) {
      case 1:
        return number + "st";
      case 2:
        return number + "nd";
      case 3:
        return number + "rd";
      default:
        return number + "th";
    }
  };

  const pullPeopleReport = usePeopleReportDispatch();
  const pullPropertyReport = usePropertySearchDispatch();

  return (
    <>
      {/* Neighborhood Ai */}
      {(["prr"].includes(brandInfo.acronym) &&
        memberExperienceData &&
        memberExperienceData.variant &&
        ["m31_prr_/property-report_v1", "m31_prr_/property-report_v2"].includes(
          memberExperienceData.variant,
        )) ||
      ["pf"].includes(brandInfo.acronym) ? (
        <NeighborhoodAI containerId={containerId} printReady={printReady} />
      ) : (
        <Padding
          left="md"
          right="md"
          breakpointStyles={{
            desktop: {
              space: "none",
            },
          }}
        >
          <SectionTitle
            sectionTitle="Neighborhood Insights"
            sectionId={sectionTitleId}
            headingLevel={2}
          />
        </Padding>
      )}

      {/* Neighborhood Map */}
      <Padding
        left="md"
        right="md"
        breakpointStyles={{
          desktop: {
            space: "none",
          },
        }}
      >
        <NeighborhoodMap
          containerId="neighborhood-map"
          printReady={printReady}
        />
      </Padding>

      {/* Neighbors */}
      <Padding
        left="md"
        right="md"
        breakpointStyles={{
          desktop: {
            space: "none",
          },
        }}
      >
        <Container
          containerTitle={`Neighbors ${
            loading
              ? ""
              : `(${
                  pulledReport?.propertyReportSummary?.neighborsCount
                    ? pulledReport.propertyReportSummary.neighborsCount
                    : 0
                })`
          }`}
          containerId="neighbors"
          newInfo={reportDifferences?.neighbors}
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={10}
            >
              <>
                <LoadingPlaceholder width={"10rem"} height={"20px"} />
                <LoadingPlaceholder width={"10rem"} height={"20px"} />
                <LoadingPlaceholder width={"10rem"} height={"20px"} />
                <LoadingPlaceholder width={"10rem"} height={"20px"} />
                <LoadingPlaceholder width={"10rem"} height={"20px"} />
              </>
            </LoadingContainerLineItem>
          ) : pulledReport?.neighbors && pulledReport.neighbors[0] ? (
            <CollapsableItemList numberToShow={10} isOpen={printReady}>
              {pulledReport.neighbors.map((neighbor, index) => (
                <React.Fragment key={`${neighbor["latitude"]}-${index}`}>
                  <ContainerLineItem
                    lineItemHeader={
                      <Text size="base">
                        {getNumberSuffix(index + 1)} Closest Neighboring
                        Property <Tag tagText="neighbor" type="primary" />
                      </Text>
                    }
                    isOpen={printReady ? true : false}
                    lineItemSubHeader={
                      <ReportLink<PullPropertyReportParams>
                        text={`${neighbor.fullStreetAddress ? `${neighbor.fullStreetAddress},` : null} ${neighbor.city ? neighbor.city : null}`}
                        inputValue={{
                          addressLine1: neighbor.fullStreetAddress,
                          addressLine2: undefined,
                          state: neighbor.state
                            ? convertStateAbbreviation(neighbor.state)
                            : "",
                          city: neighbor.city,
                          zip: neighbor.postalCode,
                          latitude: neighbor.latitude,
                          longitude: neighbor.longitude,
                          nearbySchoolRadius: 5,
                        }}
                        handleClick={pullPropertyReport}
                        altStyle
                      />
                    }
                  />
                  <Padding right="md" left="md">
                    <Text size="sm">
                      {`${neighbor.residents?.length} ${neighbor.residents?.length === 1 ? "Resident" : "Residents"} found at address:`}
                    </Text>

                    <Padding space="sm">
                      <CollapsableItemList
                        numberToShow={5}
                        noBorder
                        isOpen={printReady}
                      >
                        {neighbor.residents?.map((resident, index) => (
                          <div key={`${resident["dateFirstSeen"]}-${index}`}>
                            <Padding bottom="xxs">
                              <Layout
                                type="flex"
                                flexProps={{
                                  gap: "xs",
                                  direction: "row",
                                  alignItems: "center",
                                }}
                              >
                                {resident.name?.reportToken ? (
                                  <>
                                    <ReportLink<PullPeopleReportParams>
                                      text={generateList(
                                        [
                                          {
                                            value: resident.name.firstName
                                              ? resident.name.firstName
                                              : null,
                                            label: "",
                                          },
                                          {
                                            value: resident.name.lastName
                                              ? resident.name.lastName
                                              : null,
                                            label: "",
                                          },
                                        ],
                                        " ",
                                      )}
                                      reportTag={
                                        resident.dateOfDeath ? (
                                          <Tag
                                            type="deceased"
                                            tagText="deceased"
                                            size="small"
                                          />
                                        ) : null
                                      }
                                      inputValue={resident.name.reportToken}
                                      handleClick={pullPeopleReport}
                                      altStyle
                                    />
                                    {}
                                  </>
                                ) : resident.name ? (
                                  <Text size="sm">
                                    {formatName(resident.name, "abbreviated")}
                                    {resident.dateOfDeath && (
                                      <Tag
                                        tagText="deceased"
                                        type="deceased"
                                        size="small"
                                      />
                                    )}
                                  </Text>
                                ) : null}
                                <Text tag="span" size="xs">
                                  (
                                  {generateList(
                                    [
                                      {
                                        value: resident.dateFirstSeen
                                          ? parseDate(
                                              resident.dateFirstSeen,
                                              "month-year",
                                            )
                                          : null,
                                        label: "",
                                      },
                                      {
                                        value: resident.dateOfDeath
                                          ? parseDate(
                                              resident.dateOfDeath,
                                              "month-year",
                                            )
                                          : resident.dateLastSeen &&
                                              parseDate(
                                                resident.dateLastSeen,
                                                "month-year",
                                              ) ===
                                                parseDate(
                                                  currentDateMonthYear,
                                                  "month-year",
                                                )
                                            ? "Current"
                                            : resident.dateLastSeen
                                              ? parseDate(
                                                  resident.dateLastSeen,
                                                  "month-year",
                                                )
                                              : null,
                                        label: "",
                                      },
                                    ],
                                    " - ",
                                  )}
                                  )
                                </Text>
                              </Layout>
                            </Padding>
                          </div>
                        ))}
                      </CollapsableItemList>
                    </Padding>
                  </Padding>
                </React.Fragment>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text size="sm" tag="b">
                No Neighbors found near this address
              </Text>
            </Padding>
          )}
        </Container>
      </Padding>

      {/* Nearby Schools */}
      <Padding
        left="md"
        right="md"
        breakpointStyles={{
          desktop: {
            space: "none",
          },
        }}
      >
        <Container
          containerTitle={`Nearby Schools ${
            loading
              ? ""
              : `(${
                  pulledReport?.neighborhoodSchools
                    ? pulledReport.neighborhoodSchools.length
                    : 0
                })`
          }`}
          containerId="nearby-schools"
          tooltipContent="This section shows schools in the surrounding area. GreatSchools Ratings provide a snapshot of school quality based on test scores, academic progress, and equity for public schools. Because few private schools publish results from standardized tests, they are not rated."
          newInfo={reportDifferences?.neighborhoodSchools}
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport?.neighborhoodSchools &&
            pulledReport?.neighborhoodSchools.length > 0 ? (
            <CollapsableItemList>
              {pulledReport.neighborhoodSchools?.map((school, index) => (
                <ContainerLineItem
                  key={`${school["enrollment"]}-${index}`}
                  lineItemHeader={
                    <Text>
                      {school.name}{" "}
                      <Tag tagText="nearby school" type="secondary" />
                    </Text>
                  }
                  lineItemSubHeader={
                    <ReportLink<PullPropertyReportParams>
                      text={generateList(
                        [
                          {
                            value: school.street
                              ? school.street.toUpperCase()
                              : null,
                            label: "",
                          },
                          {
                            value: school.city
                              ? school.city.toUpperCase()
                              : null,
                            label: "",
                          },
                        ],
                        ", ",
                      )}
                      inputValue={{
                        addressLine1: school.street,
                        addressLine2: "",
                        state: school.state,
                        city: school.city,
                        zip: school.zip,
                        latitude: school.lat,
                        longitude: school.lon,
                        nearbySchoolRadius: 5,
                      }}
                      handleClick={pullPropertyReport}
                      altStyle
                    />
                  }
                  subContent={
                    <Padding top="md">
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Padding>
                          <Padding bottom="xs">
                            <Text size="xs">
                              {school.districtName
                                ? school.districtName
                                : "School District not available at this time"}
                            </Text>
                          </Padding>
                          <Layout
                            type="flex"
                            flexProps={{ gap: "md", direction: "row" }}
                            breakpointStyles={{
                              mobile: {
                                type: "grid",
                                gridProps: {
                                  gap: "xxxs",
                                  templateColumns: "2-column",
                                },
                              },
                            }}
                          >
                            {school.level ? (
                              <Text size="xs">
                                Grades: {schoolGrades(school.level!)}
                              </Text>
                            ) : null}
                            {school.enrollment ? (
                              <Text size="xs">
                                Students: {school.enrollment}
                              </Text>
                            ) : (
                              <Text size="xs">Students: Unspecified</Text>
                            )}
                            {school.type ? (
                              <Text size="xs">
                                Type: {convertToTitleCase(school.type)}
                              </Text>
                            ) : null}
                            {school.distance ? (
                              <Text size="xs">
                                Distance:{" "}
                                {Math.round(
                                  (school.distance ? school.distance : 0) * 100,
                                ) / 100}{" "}
                                mi
                              </Text>
                            ) : null}
                          </Layout>
                        </Padding>
                        {school.rating && school.overviewUrl ? (
                          <SchoolRatingCircle
                            rating={school.rating}
                            url={school.overviewUrl}
                          ></SchoolRatingCircle>
                        ) : (
                          <SchoolRatingCircle rating={"?"}></SchoolRatingCircle>
                        )}
                      </Layout>
                    </Padding>
                  }
                ></ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text size="sm" tag="b">
                No Schools found nearby this address
              </Text>
            </Padding>
          )}
        </Container>
      </Padding>
    </>
  );
};

export default NeighborhoodOverview;

const NeighborhoodAI: React.FC<{ containerId: string; printReady?: boolean }> =
  React.memo(({ containerId, printReady }) => {
    const [aiExpanded, setAiExpanded] = useState(false);
    const [iconVote, setIconVote] = useState<"up" | "down" | "none">("none");

    const dispatch = useAppDispatch();

    const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
    const loading = propertyReportStatus === "loading";

    const pulledReport = useAppSelector(selectPropertyReport);
    const aiStatus = useAppSelector(selectAiStatus);
    const { neighborhoodAiUsed, neighborhoodAiVoted } = useAppSelector(
      selectPropertyReportTrackingFlags,
    );

    // reset vote when neighborhoodAiVoted changes
    useEffect(() => {
      setIconVote("none");
      setAiExpanded(false);
    }, [pulledReport]);

    const handleAiVote = (vote: "up" | "down") => {
      if (!neighborhoodAiVoted) {
        dispatch(
          sendMemberEventAsync({
            EventCategory: "Neighborhood AI",
            EventAction: `${vote === "up" ? "Thumbs Up" : "Thumbs Down"} Clicked`,
            MetaData: JSON.stringify({
              key: "address+zip",
              value: `Report Name: ${
                pulledReport &&
                pulledReport.propertyDetails &&
                pulledReport.propertyDetails
                  ? generateList(
                      [
                        {
                          value: pulledReport.propertyDetails.fullAddress,
                          label: "",
                        },
                        {
                          value: pulledReport.propertyDetails.postalCode,
                          label: "",
                        },
                      ],
                      ", ",
                    )
                  : ""
              }`,
            }),
          }),
        );
        dispatch(updateNeighborhoodAiVoted());
        setIconVote(vote);
      }
    };

    const aiLoading = aiStatus === "loading";
    const multiSearchProps = useMultiSearchProps(
      {
        footer: false,
        resetInput: true,
        openTab: "property",
        tabs: ["property"],
      },
      "ai-no-results",
    );

    const aiClasses = ["sc-ai__content-container"];
    if (aiExpanded) {
      aiClasses.push("sc-ai__content-container--expanded");
    }
    return (
      <GradientBackground id={containerId} seed={11} amplitude={220}>
        <Padding space="xl">
          <Container customClassName="ai__container">
            <Padding space="md">
              {pulledReport?.neighborhoodAi && !aiLoading && !loading ? (
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    gap: "md",
                  }}
                >
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      alignItems: "flex-start",
                      gap: "lg",
                    }}
                    passThroughClassess={classFormation(aiClasses)}
                  >
                    <Layout type="flex" flexProps={{ gap: "xs" }}>
                      <Text tag="h2">Neighborhood Vibes </Text>
                      <Tag
                        tagText={
                          <Layout
                            type="flex"
                            flexProps={{ alignItems: "center", gap: "xxxs" }}
                          >
                            <Icon
                              block="inline"
                              name={IconList.IconAi}
                              size="16"
                            />
                            AI
                          </Layout>
                        }
                        type="ai"
                      />
                    </Layout>

                    <Layout
                      type="flex"
                      flexProps={{
                        direction: "column",
                        gap: "xs",
                      }}
                    >
                      <Text tag="i" size="lg" headerFont fontWeight="semibold">
                        {pulledReport.propertyDetails?.postalCode}:{" "}
                        {pulledReport.neighborhoodAi.neighborhoodName}
                      </Text>
                      <Text size="sm">
                        {pulledReport.neighborhoodAi.neighborhoodSummary}
                      </Text>
                    </Layout>

                    <Layout
                      type="flex"
                      flexProps={{
                        direction: "column",
                        gap: "xs",
                      }}
                    >
                      <Text headerFont size="lg" fontWeight="semibold">
                        Points of Interest
                      </Text>
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "column",
                          gap: "md",
                        }}
                      >
                        {pulledReport.neighborhoodAi.pointsOfInterest.map(
                          (poi, index) => (
                            <Layout
                              key={`${poi.name}-${index}`}
                              type="flex"
                              flexProps={{
                                direction: "column",
                                gap: "xs",
                              }}
                            >
                              <Text size="sm">
                                {index + 1}.{" "}
                                <Text
                                  tag="span"
                                  size="sm"
                                  fontWeight="semibold"
                                >
                                  {poi.name}
                                </Text>
                                : {poi.description}
                              </Text>
                            </Layout>
                          ),
                        )}
                      </Layout>
                    </Layout>
                    <Text size="xs" tag="i">
                      AI-generated content. Please verify independently.
                    </Text>
                  </Layout>

                  <ShowMore
                    handleClick={() => {
                      if (!neighborhoodAiUsed) {
                        dispatch(
                          sendMemberEventAsync({
                            EventCategory: "Neighborhood AI",
                            EventAction: "Show More Clicked",
                            MetaData: JSON.stringify({
                              key: "address+zip",
                              value: `Report Name: ${
                                pulledReport &&
                                pulledReport.propertyDetails &&
                                pulledReport.propertyDetails
                                  ? generateList(
                                      [
                                        {
                                          value:
                                            pulledReport.propertyDetails
                                              .fullAddress,
                                          label: "",
                                        },
                                        {
                                          value:
                                            pulledReport.propertyDetails
                                              .postalCode,
                                          label: "",
                                        },
                                      ],
                                      ", ",
                                    )
                                  : ""
                              }`,
                            }),
                          }),
                        );
                        dispatch(updateNeighborhoodAiUsed());
                      }
                      setAiExpanded(!aiExpanded);
                    }}
                    open={aiExpanded || !!printReady}
                  />

                  <Layout
                    type="flex"
                    flexProps={{ justifyContent: "space-between" }}
                  >
                    <Layout type="flex" flexProps={{ gap: "lg" }}>
                      <AnchorLink
                        role="button"
                        size="sm"
                        color={
                          iconVote === "none"
                            ? "base"
                            : iconVote === "down"
                              ? "accent"
                              : "border-gray"
                        }
                        handleClick={() => handleAiVote("down")}
                      >
                        <Icon name={IconList.IconThumbsDown} size={20} />
                      </AnchorLink>
                      <AnchorLink
                        role="button"
                        size="sm"
                        color={
                          iconVote === "none"
                            ? "base"
                            : iconVote === "up"
                              ? "accent"
                              : "border-gray"
                        }
                        handleClick={() => handleAiVote("up")}
                      >
                        <Icon name={IconList.IconThumbsUp} size={20} />
                      </AnchorLink>
                    </Layout>
                    <Text tag="div" color="primary">
                      <Tooltip padding={8}>
                        <TooltipTrigger className="sc-tooltip__trigger">
                          <Icon name={IconList.IconHelp} size={16} />
                        </TooltipTrigger>
                        <TooltipContent className="sc-tooltip__content">
                          Explore AI-generated neighborhood insights that
                          enhance your property report. This section includes
                          the neighborhood name, a concise summary capturing
                          local lifestyle, and highlights of top points of
                          interest—all based on the specific location. Verify
                          this information for reliability.
                        </TooltipContent>
                      </Tooltip>
                    </Text>
                  </Layout>
                </Layout>
              ) : aiLoading || loading ? (
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    justifyContent: "center",
                    gap: "lg",
                  }}
                >
                  <Text tag="h1">
                    Neighborhood Vibes{" "}
                    <Tag
                      tagText={
                        <Layout
                          type="flex"
                          flexProps={{ alignItems: "center", gap: "xxxs" }}
                        >
                          <Icon
                            block="inline"
                            name={IconList.IconAi}
                            size="16"
                          />
                          AI
                        </Layout>
                      }
                      type="ai"
                    />
                  </Text>

                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      gap: "xs",
                    }}
                  >
                    <LoadingPlaceholder height={25} width={"100%"} />
                    <LoadingPlaceholder height={150} width={"100%"} />
                  </Layout>
                </Layout>
              ) : (
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    // gap: "",
                  }}
                >
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      gap: "sm",
                    }}
                  >
                    <Text tag="h2" textAlign="center">
                      Neighborhood Vibes Currently Unavailable for This Report
                    </Text>
                    <Text size="sm" textAlign="center">
                      Please try another search below.
                    </Text>
                  </Layout>
                  <MultiSearch {...multiSearchProps} />
                </Layout>
              )}
            </Padding>
          </Container>
        </Padding>
      </GradientBackground>
    );
  });

const NeighborhoodMap: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = React.memo(({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const mapboxToken = useAppSelector(selectMapboxKey);
  const brandInfo = useAppSelector(selectBrandInfo);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const loading = propertyReportStatus === "loading";

  const [propMapPins, setPropMapPins] = useState<PinData[]>([]);
  const [boundaryPins, setBoundaryPins] = useState<PinData[]>([]);

  /* Map Prep */
  const prepareSearchedAddressPinData = useCallback(
    (address: PropertyDetails) => {
      return [
        {
          latitude: address.latitude,
          longitude: address.longitude,
          popup: (
            <>
              <Text fontWeight="bold" size="sm">
                {address.fullAddress}, {address.city}, {address.state}{" "}
                {address.postalCode}
              </Text>
            </>
          ),
          element: (
            <Text
              tag="div"
              color={["pf"].includes(brandInfo.acronym) ? "accent" : "primary"}
            >
              <Icon name={IconList.IconPropertyPin} size={21} />
            </Text>
          ),
        },
      ];
    },
    [brandInfo.acronym],
  );

  const prepareNeighborPinData = useCallback(
    (neighbors: PropertyNeighbor[]) => {
      return neighbors.map((neighbor, index) => ({
        latitude: neighbor.latitude,
        longitude: neighbor.longitude,
        popup: (
          <>
            <Text
              fontWeight="bold"
              size="sm"
              key={`${neighbor["residents"]}-${index}`}
            >
              {neighbor.fullStreetAddress}, {neighbor.city}, {neighbor.state}{" "}
              {neighbor.postalCode}
            </Text>
          </>
        ),
        element: (
          <Text tag="div" color="neighborPin">
            <Icon name={IconList.IconPin} size={21} />
          </Text>
        ),
      }));
    },
    [],
  );

  const prepareSchoolPinData = useCallback((schools: NeighborhoodSchool[]) => {
    return schools.map((school, index) => ({
      latitude: school.lat,
      longitude: school.lon,
      popup: (
        <>
          <Text size="sm" fontWeight="bold">
            {school.name?.toUpperCase()}
          </Text>
          <Text size="xxs" key={`${school["overviewUrl"]}-${index}`}>
            {school.street?.toUpperCase()}, {school.city?.toUpperCase()},{" "}
            {school.state?.toUpperCase()} {school.zip}
          </Text>
        </>
      ),
      element: (
        <Text tag="div">
          <Icon name={IconList.IconPin} size={21} />
        </Text>
      ),
    }));
  }, []);

  const updatePins = useCallback(() => {
    if (!pulledReport) return;

    const searchedAddressPinArray = pulledReport.propertyDetails
      ? prepareSearchedAddressPinData(pulledReport.propertyDetails)
      : [];
    const schoolPinArray = pulledReport.neighborhoodSchools
      ? prepareSchoolPinData(pulledReport.neighborhoodSchools)
      : [];
    const neighborPinArray = pulledReport.neighbors
      ? prepareNeighborPinData(pulledReport.neighbors)
      : [];

    setPropMapPins([
      ...neighborPinArray,
      ...schoolPinArray,
      ...searchedAddressPinArray,
    ]);
    setBoundaryPins([...neighborPinArray, ...searchedAddressPinArray]);
  }, [
    pulledReport,
    prepareSearchedAddressPinData,
    prepareNeighborPinData,
    prepareSchoolPinData,
  ]);

  useEffect(() => {
    updatePins();
  }, [updatePins]);

  const changeBoundary = useCallback(
    (schools: boolean) => {
      if (!pulledReport) return;

      const searchedAddressPinArray = pulledReport.propertyDetails
        ? prepareSearchedAddressPinData(pulledReport.propertyDetails)
        : [];
      const schoolPinArray = pulledReport.neighborhoodSchools
        ? prepareSchoolPinData(pulledReport.neighborhoodSchools)
        : [];
      const neighborPinArray = pulledReport.neighbors
        ? prepareNeighborPinData(pulledReport.neighbors)
        : [];

      if (schools) {
        setBoundaryPins([
          ...searchedAddressPinArray,
          ...schoolPinArray,
          ...neighborPinArray,
        ]);
      } else {
        setBoundaryPins([...searchedAddressPinArray, ...neighborPinArray]);
      }
    },
    [
      pulledReport,
      prepareSearchedAddressPinData,
      prepareNeighborPinData,
      prepareSchoolPinData,
    ],
  );

  const neighborhoodCount = useMemo(
    () =>
      (pulledReport?.propertyReportSummary?.neighborsCount ?? 0) +
      (pulledReport?.neighborhoodSchools?.length ?? 0),
    [
      pulledReport?.propertyReportSummary?.neighborsCount,
      pulledReport?.neighborhoodSchools?.length,
    ],
  );

  return (
    <Container
      containerTitle={`Neighborhood Map ${loading ? "" : `(${neighborhoodCount})`}`}
      containerId={containerId}
      newInfo={reportDifferences?.neighbors}
    >
      {pulledReport &&
      pulledReport.propertyDetails &&
      pulledReport.propertyDetails.latitude &&
      pulledReport.propertyDetails.longitude &&
      pulledReport.neighborhoodSchools &&
      !printReady ? (
        loading ? (
          <LoadingPlaceholder width="100%" height="500px" />
        ) : (
          <>
            <Container>
              <Padding top="md" bottom="md" left="sm" right="sm">
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", gap: "xxs" }}
                >
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xs", alignItems: "center" }}
                  >
                    <Text
                      color={
                        ["pf"].includes(brandInfo.acronym)
                          ? "accent"
                          : "primary"
                      }
                    >
                      <Icon name={IconList.IconPropertyPin} size={21} />
                    </Text>
                    <Text size="base" fontWeight="bold">
                      {pulledReport?.propertyReportSummary?.addressLine1?.toUpperCase()}
                    </Text>
                  </Layout>
                  {pulledReport.propertyReportSummary?.neighborsCount &&
                  pulledReport.propertyReportSummary?.neighborsCount > 0 ? (
                    <Layout
                      type="flex"
                      flexProps={{ justifyContent: "space-between" }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{ gap: "xs", alignItems: "center" }}
                      >
                        <Text color="neighborPin">
                          <Icon name={IconList.IconPin} size={21} />
                        </Text>
                        <Text size="sm">
                          {pulledReport.propertyReportSummary.neighborsOverview}
                        </Text>
                      </Layout>
                      <AnchorLink
                        role="button"
                        size="xs"
                        handleClick={() => changeBoundary(false)}
                      >
                        view on map
                      </AnchorLink>
                    </Layout>
                  ) : null}
                  {pulledReport.neighborhoodSchools.length > 0 && (
                    <Layout
                      type="flex"
                      flexProps={{ justifyContent: "space-between" }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{ gap: "xs", alignItems: "center" }}
                      >
                        <Text>
                          <Icon name={IconList.IconPin} size={21} />
                        </Text>
                        <Text size="sm">
                          {pulledReport.neighborhoodSchools.length} Schools
                          found nearby
                        </Text>
                      </Layout>
                      <AnchorLink
                        role="button"
                        size="xs"
                        handleClick={() => changeBoundary(true)}
                      >
                        view on map
                      </AnchorLink>
                    </Layout>
                  )}
                </Layout>
              </Padding>
            </Container>
            <Map
              mapboxToken={mapboxToken}
              markerData={propMapPins}
              setBoundaryMarkerData={boundaryPins}
              center={
                pulledReport.propertyDetails
                  ? [
                      pulledReport.propertyDetails.longitude,
                      pulledReport.propertyDetails.latitude,
                    ]
                  : [-98.789062, 38.548165]
              }
            />
          </>
        )
      ) : null}
    </Container>
  );
});
