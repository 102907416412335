import {
  CollapsableItemList,
  Container,
  Layout,
  MultiSearch,
  Padding,
  Sheet,
  Text,
} from "@kidslivesafe/sc-react";
import React from "react";
import { useMultiSearchProps } from "../../../hooks/multiSearchPropsHook";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectPhoneReportHistory,
  selectReportHistoryStatus,
} from "../../../features/reports/reportsSlice";
import ActivityHistoryLineItem from "../../../components/UIComponents/ActivityHistory/ActivityHistoryLineItem";
import PhoneHistoryMap from "./PhoneHistoryMap";
import SpacerMultiSearch from "../../../components/UIComponents/SpacerMultiSearch/SpacerMultiSearch";

const PhoneHome: React.FC = () => {
  const reportHistory = useAppSelector(selectPhoneReportHistory);
  const reportHistoryStatus = useAppSelector(selectReportHistoryStatus);

  const loading = reportHistoryStatus === "loading";

  const multiSearchProps = useMultiSearchProps(
    {
      footer: true,
      showCancel: false,
      topPadding: "xs",
      resetInput: true,
      buttonText: "New Search",
      openTab: "phone",
      tabs: ["phone"],
    },
    "phone-home",
  );

  return (
    <>
      <Container>
        <Sheet>
          <Padding left="md" right="md">
            <Layout type="flex" flexProps={{ gap: "xs", direction: "column" }}>
              <Text tag="h3" textAlign="center">
                Phone Search
              </Text>
              <Text size="sm" textAlign="center">
                Identify the source of unknown or unwanted calls or texts. Learn
                details including name, age, and address.{" "}
              </Text>
            </Layout>
          </Padding>
        </Sheet>
      </Container>
      <MultiSearch {...multiSearchProps} />
      <Sheet>
        <Padding space="md">
          <Layout type="flex" flexProps={{ gap: "xs", direction: "column" }}>
            <Container
              containerTitle="Phone Report History"
              containerId="phone-home__activity-history__container"
            >
              <PhoneHistoryMap />
              <CollapsableItemList>
                {loading ? (
                  Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <ActivityHistoryLineItem
                        report={undefined}
                        loading={loading}
                        loadingKey={index}
                        key={index}
                      />
                    ))
                ) : reportHistory && reportHistory.length > 0 ? (
                  reportHistory.map(report =>
                    report ? (
                      <ActivityHistoryLineItem
                        report={report}
                        loadingKey={report.score}
                        key={report.score}
                      />
                    ) : null,
                  )
                ) : (
                  <Padding space="md">
                    <Text size="xs">
                      Get started by searching a phone number above.
                    </Text>
                  </Padding>
                )}
              </CollapsableItemList>
            </Container>
          </Layout>
        </Padding>
        <SpacerMultiSearch options={["people", "property", "neighborhood"]} />
      </Sheet>
    </>
  );
};

export default PhoneHome;
