import React, { useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { useAppDispatch } from "../../../hooks/hooks";

import {
  Container,
  Layout,
  Padding,
  Text,
  CollapsableItemList,
  ContainerLineItem,
  Button,
  LoadingPlaceholder,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Icon,
  IconList,
  AnchorLink,
  Tag,
  Modal,
  Margin,
  Spotlight,
} from "@kidslivesafe/sc-react";

import { formatPhoneNumber, pluralOrSingular, parseDate } from "../../../utils";

import {
  selectPhoneReport,
  selectPhoneReportStatus,
  submitComplaintAsync,
} from "../../../features/reports/reportsSlice";

import "./assets/Complaints.css";

import { ComplaintResults as ComplaintResultsType } from "../../../features/reports/reportTypes";

import pulledReport from "./assets/PhoneReport.json";
pulledReport.date = new Date().toLocaleDateString();

const Complaints: React.FC<{ sectionId: string; printReady?: boolean }> = ({
  sectionId,
  printReady,
}) => {
  const dispatch = useAppDispatch();

  const [modalOne, setModalOne] = useState(false);
  const [incomingPhoneCallColor, setIncomingPhoneCallColor] = useState<
    "green" | undefined
  >(undefined);
  const [textMessageColor, setTextMessageColor] = useState<"green" | undefined>(
    undefined,
  );

  const [modalTwo, setModalTwo] = useState(false);
  const [notPrerecordedColor, setNotPrerecordedColor] = useState<
    "green" | undefined
  >(undefined);
  const [prerecordedColor, setPrerecordedColor] = useState<"green" | undefined>(
    undefined,
  );

  const [modalThree, setModalThree] = useState(false);
  const [multipleOccassions, setMultipleOccassions] = useState<
    "green" | undefined
  >(undefined);
  const [oneOccasion, setOneOccassion] = useState<"green" | undefined>(
    undefined,
  );

  const [modalFour, setModalFour] = useState(false);

  //store state
  const pulledReport = useAppSelector(selectPhoneReport);
  const phoneReportStatus = useAppSelector(selectPhoneReportStatus);

  const [submitComplaintObject, setSubmitComplaintObject] = useState<{
    phoneNumber: string;
    isCall: boolean;
    MultipleFrequency: boolean;
    RecordedMessage: boolean;
  }>({
    phoneNumber: pulledReport ? pulledReport.phoneReportSummary.phone : "",
    isCall: false,
    MultipleFrequency: false,
    RecordedMessage: false,
  });

  const loading = phoneReportStatus === "loading";

  const complaintSource = (complaint: ComplaintResultsType): string => {
    switch (complaint.source) {
      case "FCC":
        return "FCC (Federal Communication Commission)";
      case "FTC":
        return "FTC (Federal Trade Commission)";
      case "USER":
        return "User Submitted";
      default:
        return "Unknown";
    }
  };

  const handleModalClose = (setModal: (bool: boolean) => void) => {
    setModal(false);
    resetAllStates();
  };

  const handleModalOpen = (setModal: (bool: boolean) => void) => {
    setModal(true);
  };

  const nextStep = async (
    otherOption: "green" | undefined,
    setColor: (color: "green") => void,
    setModal: (bool: boolean) => void,
    setModalNext: React.Dispatch<React.SetStateAction<boolean>>,
    final?: boolean,
    mutlipleFrequency?: boolean,
  ) => {
    if (!otherOption && !final) {
      setColor("green");
      setTimeout(() => {
        setModal(false);
        handleModalOpen(setModalNext);
      }, 500);
    }
    if (final) {
      setColor("green");
      setSubmitComplaintObject(prevState => ({
        ...prevState,
        phoneNumber: pulledReport ? pulledReport.phoneReportSummary.phone : "",
      }));
      let submitObject = {
        ...submitComplaintObject,
        MultipleFrequency: mutlipleFrequency ? mutlipleFrequency : false,
      };
      const resultAction = await dispatch(submitComplaintAsync(submitObject));
      if (submitComplaintAsync.fulfilled.match(resultAction)) {
        setModal(false);
        handleModalOpen(setModalNext);
      }
    }
  };

  const resetAllStates = () => {
    setModalOne(false);
    setIncomingPhoneCallColor(undefined);
    setTextMessageColor(undefined);
    setModalTwo(false);
    setNotPrerecordedColor(undefined);
    setPrerecordedColor(undefined);
    setModalThree(false);
    setMultipleOccassions(undefined);
    setOneOccassion(undefined);
    setModalFour(false);
    setSubmitComplaintObject(prevState => ({
      ...prevState,
      isCall: false,
      MultipleFrequency: false,
      RecordedMessage: false,
    }));
  };

  return (
    <>
      <div className="complaint__container-background" id={sectionId}>
        <Padding
          top="md"
          left="md"
          right="md"
          bottom={
            pulledReport && pulledReport.phoneComplaints.result.length > 0
              ? "none"
              : "md"
          }
        >
          {loading ? (
            <LoadingPlaceholder width="100%" height="200px" />
          ) : (
            <Container customClassName="complaints__container" bodyBorderRadius>
              <Padding top="lg" bottom="lg" right="xs" left="xs">
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    gap: "sm",
                  }}
                >
                  {pulledReport &&
                  pulledReport.phoneComplaints.result.length > 0 ? (
                    <Layout
                      type="flex"
                      flexProps={{
                        gap: "md",
                        direction: "column",
                        alignItems: "center",
                      }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{
                          gap: "xxs",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text size="xxl" tag="div" fontWeight="bold" headerFont>
                          {pulledReport.phoneComplaints.result.length}{" "}
                          Complaints Filed
                        </Text>
                        <Tooltip padding={8}>
                          <TooltipTrigger className="sc-tooltip__trigger">
                            <div className="page-complaints__sc-tooltipContainer">
                              <Icon name={IconList.IconHelp} size={16} />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent className="sc-tooltip__content">
                            {
                              <>
                                Complaints about this number come from users of
                                our services, government records such as FTC and
                                FCC Databases and other sources. In addition to
                                “Unwanted Call” and “Unwanted Text”, there are
                                also "DNC Complaints," referring to calls
                                reported to the FTC for violating the{" "}
                                <AnchorLink
                                  size="sm"
                                  color="white"
                                  fontWeight="normal"
                                  inline={true}
                                  href="https://www.donotcall.gov"
                                >
                                  Do Not Call registry
                                </AnchorLink>
                                . You may add a complaint by clicking “Submit
                                Complaint” or by going to ftc.gov or fcc.gov.
                              </>
                            }
                          </TooltipContent>
                        </Tooltip>
                      </Layout>
                      {/* <Text
                        size="sm"
                        textAlign="center"
                      >
                        {generateList([
                          {
                            value: `${pulledReport.phoneComplaints.summary.rplComplaintCounts}-User Complaint${pluralOrSingular(pulledReport.phoneComplaints.summary.rplComplaintCounts)}`,
                            label: "",
                          },
                          {
                            value: `${pulledReport.phoneComplaints.summary.fccComplaintCounts}-FCC Complaint${pluralOrSingular(pulledReport.phoneComplaints.summary.fccComplaintCounts)}`,
                            label: "",
                          },

                          {
                            value: `${pulledReport.phoneComplaints.summary.ftcComplaintCounts}-FTC Complaint${pluralOrSingular(pulledReport.phoneComplaints.summary.ftcComplaintCounts)}`,
                            label: ""
                          }
                        ])
                        }
                      </Text> */}
                      <Layout
                        type="flex"
                        flexProps={{
                          gap: "xxs",
                          justifyContent: "center",
                          alignItems: "center",
                          wrap: "wrap",
                        }}
                      >
                        <Text size="sm" textAlign="center">
                          {
                            pulledReport.phoneComplaints.summary
                              .rplComplaintCounts
                          }
                          -User Complaint
                          {pluralOrSingular(
                            pulledReport.phoneComplaints.summary
                              .rplComplaintCounts,
                          )}{" "}
                          |{" "}
                        </Text>
                        <Text size="sm" textAlign="center">
                          {
                            pulledReport.phoneComplaints.summary
                              .fccComplaintCounts
                          }
                          -FCC Complaint
                          {pluralOrSingular(
                            pulledReport.phoneComplaints.summary
                              .fccComplaintCounts,
                          )}
                        </Text>
                        <Text size="sm" textAlign="center">
                          |{" "}
                          {
                            pulledReport.phoneComplaints.summary
                              .ftcComplaintCounts
                          }
                          -FTC Complaint
                          {pluralOrSingular(
                            pulledReport.phoneComplaints.summary
                              .ftcComplaintCounts,
                          )}
                        </Text>
                      </Layout>
                    </Layout>
                  ) : (
                    <Text
                      size="xxl"
                      tag="div"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      <>
                        <div>No Complaints Filed for</div>
                        <Layout
                          type="flex"
                          flexProps={{
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "xs",
                          }}
                        >
                          <div>
                            {pulledReport
                              ? formatPhoneNumber(
                                  pulledReport.phoneReportSummary.phone,
                                )
                              : null}
                          </div>
                          <Tooltip padding={8}>
                            <TooltipTrigger className="sc-tooltip__trigger">
                              <div className="page-complaints__sc-tooltipContainer">
                                <Icon name={IconList.IconHelp} size={16} />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent className="sc-tooltip__content">
                              {
                                <>
                                  Complaints about this number come from users
                                  of our services, government records such as
                                  FTC and FCC Databases and other sources. In
                                  addition to “Unwanted Call” and “Unwanted
                                  Text”, there are also "DNC Complaints,"
                                  referring to calls reported to the FTC for
                                  violating the{" "}
                                  <AnchorLink
                                    size="sm"
                                    color="white"
                                    fontWeight="normal"
                                    inline={true}
                                    href="https://www.donotcall.gov"
                                  >
                                    Do Not Call registry
                                  </AnchorLink>
                                  . You may add a complaint by clicking “Submit
                                  Complaint” or by going to ftc.gov or fcc.gov.
                                </>
                              }
                            </TooltipContent>
                          </Tooltip>
                        </Layout>
                      </>
                    </Text>
                  )}
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "md",
                      direction: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      fill="not-fill"
                      size="md"
                      handleClick={() => {
                        handleModalOpen(setModalOne);
                      }}
                      buttonText={"File Complaint"}
                    />
                  </Layout>
                </Layout>
              </Padding>
            </Container>
          )}
        </Padding>
        {pulledReport &&
        pulledReport.phoneComplaints.result.length > 0 &&
        !loading ? (
          <div className="complaint__container-background">
            <Container>
              <CollapsableItemList isOpen={printReady}>
                {pulledReport.phoneComplaints.result.map((complaint, index) => (
                  <ContainerLineItem
                    lineItemHeader={
                      <div className="page-phoneReport__complaints__complaint-item">
                        {complaint.issue}{" "}
                        <Tag type="complaint" tagText="complaint" />
                      </div>
                    }
                    lineItemDetails={parseDate(
                      complaint.createdDate,
                      "month-year",
                    )}
                    lineItemSubHeader={complaintSource(complaint)}
                    subContent={
                      <Text tag="div" size="xs">
                        {complaint.isRecordedMessage ? (
                          <div>Prerecorded Voice</div>
                        ) : null}
                        {complaint.isMultipleFrequency ? (
                          <div>Contacted Multiple Times</div>
                        ) : null}
                        {complaint.subject && complaint.subject !== "Other" ? (
                          <div>{complaint.subject}</div>
                        ) : null}
                      </Text>
                    }
                    key={index}
                    isOpen={printReady ? true : false}
                  />
                ))}
              </CollapsableItemList>
            </Container>
          </div>
        ) : null}
        <Modal
          open={modalOne}
          setOpen={() => {
            handleModalClose(setModalOne);
          }}
          xClose
          size="base"
          outSideClose
        >
          <Margin top="xxl">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "xl",
              }}
            >
              <Text textAlign="center" tag="h3">
                Submit a complaint on an:
              </Text>
              <Layout
                type="flex"
                flexProps={{
                  gap: "lg",
                  direction: "column",
                  alignItems: "center",
                }}
              >
                <div className="page-complaints__modal-spotlight-width">
                  <Spotlight
                    pillshape
                    altColor={incomingPhoneCallColor}
                    onClick={() => {
                      setSubmitComplaintObject(prevState => ({
                        ...prevState,
                        isCall: true,
                      }));
                      nextStep(
                        textMessageColor,
                        setIncomingPhoneCallColor,
                        setModalOne,
                        setModalTwo,
                      );
                    }}
                  >
                    <Padding left="sm" right="sm" top="md" bottom="md">
                      <Layout
                        type="flex"
                        flexProps={{ justifyContent: "flex-start", gap: "sm" }}
                      >
                        <Icon
                          size={18}
                          name={IconList.IconPhoneComplaintIncoming}
                        ></Icon>
                        <Text size="xs" fontWeight="semibold" unresponsive>
                          Incoming Phone Call
                        </Text>
                      </Layout>
                    </Padding>
                  </Spotlight>
                </div>
                <div className="page-complaints__modal-spotlight-width">
                  <Spotlight
                    pillshape
                    altColor={textMessageColor}
                    onClick={() => {
                      setSubmitComplaintObject(prevState => ({
                        ...prevState,
                        isCall: false,
                      }));
                      nextStep(
                        incomingPhoneCallColor,
                        setTextMessageColor,
                        setModalOne,
                        setModalThree,
                      );
                    }}
                  >
                    <Padding left="sm" right="sm" top="md" bottom="md">
                      <Layout
                        type="flex"
                        flexProps={{ justifyContent: "flex-start", gap: "sm" }}
                      >
                        <Icon size={18} name={IconList.IconMessage}></Icon>
                        <Text size="xs" fontWeight="semibold" unresponsive>
                          Unwanted Text Message
                        </Text>
                      </Layout>
                    </Padding>
                  </Spotlight>
                </div>
              </Layout>
            </Layout>
          </Margin>
        </Modal>
        <Modal
          open={modalTwo}
          setOpen={() => {
            handleModalClose(setModalTwo);
          }}
          xClose
          size="base"
          outSideClose
        >
          <Margin top="xxl">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "xl",
              }}
            >
              <Text textAlign="center" tag="h3">
                The unwanted call was:
              </Text>
              <Layout
                type="flex"
                flexProps={{
                  gap: "lg",
                  direction: "column",
                  alignItems: "center",
                }}
              >
                <div className="page-complaints__modal-spotlight-width">
                  <Spotlight
                    pillshape
                    altColor={prerecordedColor}
                    onClick={() => {
                      setSubmitComplaintObject(prevState => ({
                        ...prevState,
                        RecordedMessage: true,
                      }));
                      nextStep(
                        notPrerecordedColor,
                        setPrerecordedColor,
                        setModalTwo,
                        setModalThree,
                      );
                    }}
                  >
                    <Padding left="sm" right="sm" top="md" bottom="md">
                      <Layout
                        type="flex"
                        flexProps={{ justifyContent: "flex-start", gap: "sm" }}
                      >
                        <Icon size={18} name={IconList.IconYes}></Icon>
                        <Text size="xs" fontWeight="semibold" unresponsive>
                          Prerecorded/Robocall
                        </Text>
                      </Layout>
                    </Padding>
                  </Spotlight>
                </div>
                <div className="page-complaints__modal-spotlight-width">
                  <Spotlight
                    pillshape
                    altColor={notPrerecordedColor}
                    onClick={() => {
                      setSubmitComplaintObject(prevState => ({
                        ...prevState,
                        RecordedMessage: false,
                      }));
                      nextStep(
                        prerecordedColor,
                        setNotPrerecordedColor,
                        setModalTwo,
                        setModalThree,
                      );
                    }}
                  >
                    <Padding left="sm" right="sm" top="md" bottom="md">
                      <Layout
                        type="flex"
                        flexProps={{ justifyContent: "flex-start", gap: "sm" }}
                      >
                        <Icon size={18} name={IconList.IconNo}></Icon>
                        <Text size="xs" fontWeight="semibold" unresponsive>
                          Not Prerecorded/Live
                        </Text>
                      </Layout>
                    </Padding>
                  </Spotlight>
                </div>
              </Layout>
            </Layout>
          </Margin>
        </Modal>
        <Modal
          open={modalThree}
          setOpen={() => {
            handleModalClose(setModalThree);
          }}
          xClose
          size="base"
          outSideClose
        >
          <Margin top="xxl">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "xl",
              }}
            >
              <Text size="lg" textAlign="center" tag="h3">
                You were contacted:
              </Text>
              <Layout
                type="flex"
                flexProps={{
                  gap: "lg",
                  direction: "column",
                  alignItems: "center",
                }}
              >
                <div className="page-complaints__modal-spotlight-width">
                  <Spotlight
                    pillshape
                    altColor={oneOccasion}
                    onClick={() => {
                      setSubmitComplaintObject(prevState => ({
                        ...prevState,
                        MultipleFrequency: false,
                      }));
                      //had to add this solution because setting of multipleFrequency is async. Default value is passed to api not updated
                      let multipleFrequency = false;
                      nextStep(
                        multipleOccassions,
                        setOneOccassion,
                        setModalThree,
                        setModalFour,
                        true,
                        multipleFrequency,
                      );
                    }}
                  >
                    <Padding left="sm" right="sm" top="md" bottom="md">
                      <Layout
                        type="flex"
                        flexProps={{ justifyContent: "flex-start", gap: "sm" }}
                      >
                        <Icon size={18} name={IconList.IconOneStack}></Icon>
                        <Text size="xs" fontWeight="semibold" unresponsive>
                          Just the One Time
                        </Text>
                      </Layout>
                    </Padding>
                  </Spotlight>
                </div>

                <div className="page-complaints__modal-spotlight-width">
                  <Spotlight
                    pillshape
                    altColor={multipleOccassions}
                    onClick={() => {
                      setSubmitComplaintObject(prevState => ({
                        ...prevState,
                        MultipleFrequency: true,
                      }));
                      //had to add this solution because setting of multipleFrequency is async. Default value is passed to api not updated
                      let multipleFrequency = true;
                      nextStep(
                        oneOccasion,
                        setMultipleOccassions,
                        setModalThree,
                        setModalFour,
                        true,
                        multipleFrequency,
                      );
                    }}
                  >
                    <Padding left="sm" right="sm" top="md" bottom="md">
                      <Layout
                        type="flex"
                        flexProps={{ justifyContent: "flex-start", gap: "sm" }}
                      >
                        <Icon
                          size={18}
                          name={IconList.IconMultipleStack}
                        ></Icon>
                        <Text size="xs" fontWeight="semibold" unresponsive>
                          On Multiple Occassions
                        </Text>
                      </Layout>
                    </Padding>
                  </Spotlight>
                </div>
              </Layout>
            </Layout>
          </Margin>
        </Modal>
        <Modal
          open={modalFour}
          setOpen={() => {
            handleModalClose(setModalFour);
          }}
          xClose
          size="base"
          outSideClose
        >
          <Margin top="xxl">
            <Layout
              type="flex"
              flexProps={{
                alignItems: "center",
                gap: "xl",
                direction: "column",
              }}
            >
              <Text textAlign="center" tag="h3">
                <>
                  <div>Complaint filed on </div>
                  <div>
                    {pulledReport
                      ? formatPhoneNumber(pulledReport.phoneReportSummary.phone)
                      : null}
                  </div>
                </>
              </Text>
              <Text color="success" tag="div">
                <Icon size={57} name={IconList.IconConfirmation} />
              </Text>
              <Text
                size="xs"
                textAlign="center"
                fontWeight="semibold"
                unresponsive
              >
                Your complaint has now been filed and will be tied to this phone
                number’s report moving forward. You will be returned to the
                report page to view your complaint.
              </Text>
            </Layout>
          </Margin>
        </Modal>
      </div>
    </>
  );
};

export default Complaints;
